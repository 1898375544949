import { LoaderCircular } from '../common'

function ChartCard({ chart, isLoading, openCard, min, chartValue }) {
  if (!chartValue)
    chartValue = (chart.elements[1].value * 100) / chart.elements[0].value
  if (isNaN(chartValue)) chartValue = 0
  return (
    <div className={`chart-card ${min ? 'min' : ''}`} onClick={openCard}>
      <div className="chart-card__title">{chart.title}</div>
      <div className="chart-card-content">
        <div className="diagram">
          {isLoading ? (
            <LoaderCircular show={true} />
          ) : (
            <svg viewBox="0 0 36 36" className="circular-chart">
              <path
                className="circle-bg"
                d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path
                className="circle"
                stroke="#E5264C"
                strokeDasharray={`${chartValue.toFixed(0)}, 100`}
                d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <text x="19" y="21" className="percentage">
                {chartValue.toFixed(2)}%
              </text>
            </svg>
          )}
        </div>

        <div className="chart-card__info">
          {chart.elements.map((item, i) => (
            <div className="chart-card__row" key={i}>
              <div>{item.key}</div>
              <div className="ml-auto">
                <b>{item.value}</b>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ChartCard
