export default function Table({
  headers,
  users,
  action,
  isAllowEdit,
  isAllowDelete,
}) {
  return (
    <div className="table">
      <div className="t-header">
        {headers.map((header, i) => (
          <div className="t-header__item" key={i}>
            {header}
          </div>
        ))}
      </div>
      <div className="t-body">
        {users.map((user) => (
          <div className="t-row" key={user.id}>
            <div className="t-row__item">
              {user.confirmed ? 'Подтвержден' : 'Приглашен'}
            </div>
            <div className="t-row__item">{user.firstName}</div>
            <div className="t-row__item">{user.patronymic}</div>
            <div className="t-row__item">{user.lastName}</div>
            <div className="t-row__item">{user.email}</div>
            <div className="t-row__item">{user.role.description}</div>
            <div className="t-row__item">
              {isAllowEdit(user) && (
                <button
                  className="btn btn-secondary"
                  onClick={() => action('edit', user)}
                >
                  Редактировать
                </button>
              )}
              {isAllowDelete(user) && (
                <button
                  className="btn btn-secondary"
                  onClick={() => action('delete', user)}
                >
                  Удалить
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
