import { observable, action, makeAutoObservable, computed } from 'mobx'
import { Users as API } from '../API'
import AuthStore from './AuthStore'
class UsersStore {
  @observable users = []
  @observable activeUser = null
  @observable search = ''
  @observable isNew = false
  @observable isEdit = false
  @observable isFetch = false
  @observable formError = {}

  constructor() {
    makeAutoObservable(this)
  }
  @action('update form')
  updateForm = (key, val) => {
    this.activeUser[key] = val
    this.formError = { ...this.formError, [key]: '' }
  }

  @action('sign in')
  loadUsers = async () => {
    try {
      this.isFetch = true
      let users = await API.getUsers({ search: this.search })
      this.isFetch = false
      this.users = users
    } catch (error) {
      console.log(error)
    }
  }
  @action('update search')
  updateSearch = (v) => {
    this.search = v
    this.loadUsers()
  }
  @action('add new user')
  addNewUser = () => {
    this.isNew = true
    this.isEdit = true
    this.activeUser = {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      patronymic: '',
    }
  }
  @action('close dialog')
  closeDialog = () => {
    this.activeUser = null
    this.isNew = false
    this.isEdit = false
  }
  @action('dialog submit')
  handleDialog = async (data) => {
    this.isFetch = true
    let errors = {}
    if (!this.activeUser.firstName) errors.firstName = 'Введите Имя'
    if (!this.activeUser.lastName) errors.lastName = 'Введите Фамилию'
    if (!this.activeUser.email) errors.email = 'Введите почту'
    if (!this.activeUser.role) errors.role = 'Выберите роль'
    if (Object.keys(errors).length > 0) {
      this.formError = errors
      this.isFetch = false
      return
    }
    try {
      if (this.isNew) {
        await API.createUser({
          email: this.activeUser.email,
          role: this.activeUser.role.value,
          username: this.activeUser.email,
          firstName: this.activeUser.firstName,
          surname: this.activeUser.lastName,
          patronymic: this.activeUser.patronymic,
        })
        await API.inviteUser({
          email: this.activeUser.email,
        })
      } else {
        await API.updateUser(this.activeUser.id, {
          role: this.activeUser.role.value,
          firstName: this.activeUser.firstName,
          surname: this.activeUser.lastName,
          patronymic: this.activeUser.patronymic,
        })
      }
      this.closeDialog()
      this.search = ''
      this.loadUsers()
      this.isFetch = false
    } catch (error) {
      this.isFetch = false
      this.formError.global = 'Error'
    }
  }

  @action('Edit user')
  editUser = (user) => {
    this.isNew = false
    this.isEdit = true
    this.activeUser = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.surname,
      email: user.email,
      role: { name: user.role.description, value: user.role.id },
      patronymic: user.patronymic,
    }
  }

  @action('Delete user')
  deleteUser = async (userId) => {
    await API.removeUser(userId)
    this.users = this.users.filter((user) => user.id !== userId)
  }

  @computed getRegionsCoordinators() {
    return this.users.filter(
      (user) => user.role.id === '611ed870a38e4936301f3785'
    )
  }
  @computed getCoordinators() {
    return this.users.filter(
      (user) => user.role.id === '611ed822a38e4936301f3711'
    )
  }
  @computed getRoles() {
    if (AuthStore.getUserRole() === 'admin') {
      return [
        { name: 'Администратор', value: '6115225821310505046e67d8' },
        { name: 'Координатор', value: '611ed822a38e4936301f3711' },
        { name: 'Руководитель региона', value: '611ed870a38e4936301f3785' },
      ]
    }
    if (AuthStore.getUserRole() === 'regionCoordinator') {
      return [
        { name: 'Координатор', value: '611ed822a38e4936301f3711' },
        // { name: 'Руководитель региона', value: '611ed870a38e4936301f3785' },
      ]
    }
    return []
  }
}

const usersStore = new UsersStore()

export default usersStore
