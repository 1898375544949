import { useState, useEffect } from 'react'
import { CheckBox, Input, LoaderCircular } from '../common'
import { observer } from 'mobx-react-lite'
import AnalyticsStore from '../../store/Modules/AnalyticsStore'
import UsersStore from '../../store/Modules/UsersStore'

const UsersDialog = observer(() => {
  useEffect(() => {
    if (UsersStore.users.length === 0) UsersStore.loadUsers()
  }, [])
  const close = () => {
    AnalyticsStore.showResponsiblesDialog = false
    AnalyticsStore.responsibleDialogArgs = null
  }
  const [search, setSearch] = useState('')
  const show = AnalyticsStore.showResponsiblesDialog
  const coordinators = AnalyticsStore.responsibleDialogArgs
    ? AnalyticsStore.responsibleDialogArgs?.slice(-1)[0].coordinators
    : []
  const isRegionCoordinator =
    AnalyticsStore?.responsibleDialogArgs?.slice(-1)[0].isRegion
  let users = isRegionCoordinator
    ? UsersStore.getRegionsCoordinators()
    : UsersStore.getCoordinators()

  let responsibleUsersIds = coordinators ? coordinators.map((u) => u.id) : []
  const getFilteredUsers = () => {
    return users.filter(
      (u) =>
        !responsibleUsersIds.includes(u.id) &&
        (!search ||
          (search && `${u.firstName} ${u.surname}`.search(search) !== -1))
    )
  }
  const removeUserResponsibility = (user, index) => {
    AnalyticsStore.removeResponsibility(user.permissionId, index)
  }
  const addUserResponsibility = (user) => {
    AnalyticsStore.assignResponsibility(user)
  }

  if (!show) return null
  return (
    <div className="dialog-wrapper responsibles-dialog">
      <div className="dialog card">
        <LoaderCircular show={AnalyticsStore.isFetch} />
        <div className="card__title">Ответственые</div>
        {coordinators && coordinators.length > 0 ? (
          coordinators.map((coordinator, i) => (
            <CheckBox
              key={coordinator.id}
              value={true}
              readOnly={coordinator.isInherit}
              placeholder={
                <span className="md-text">{`${coordinator.firstName} ${coordinator.surname}`}</span>
              }
              handle={() => removeUserResponsibility(coordinator, i)}
            />
          ))
        ) : (
          <span className="sm-text" style={{ width: '100%' }}>
            Отсутствуют
          </span>
        )}
        <div className="card-line"></div>
        <Input value={search} onChange={setSearch} placeholder="Поиск" />
        {getFilteredUsers().length > 0 ? (
          getFilteredUsers().map((coordinator) => (
            <CheckBox
              key={coordinator.id}
              value={false}
              placeholder={
                <span className="md-text">{`${coordinator.firstName} ${coordinator.surname}`}</span>
              }
              handle={() => addUserResponsibility(coordinator)}
            />
          ))
        ) : (
          <span className="sm-text" style={{ width: '100%' }}>
            Не найдено
          </span>
        )}

        <div className="card-actions">
          <button className="btn btn-secondary" onClick={close}>
            Закрыть
          </button>
        </div>
      </div>
    </div>
  )
})

export default UsersDialog
