import React from 'react'
import InfoIcon from '../../../assets/icons/info-icon.svg'
import DeleteIcon from '../../../assets/icons/delete-icon.svg'
import AddToShopListBtn from '../../map/common/AddToShopListBtn'
import Market from '../../../assets/icons/market-icon.svg'

function ShopListItem({
  shop,
  handleInfo,
  handleDelete,
  isBottomControll,
  handleMore,
}) {
  return (
    <div className="filter-list__item">
      <div className="filter-list__item-top">
        <img src={Market} className="filter-list__avatar" alt={shop.client} />
        <div>
          <div className="filter-list__title">{shop.client}</div>
          <div className="filter-list__price">
            {shop.payRate} {shop.currency} {shop.bonus ? `(${shop.bonus})` : ''}
          </div>
        </div>

        {handleInfo && (
          <img
            title="Найти"
            onClick={handleInfo}
            className="filter-list__item__btn"
            alt="info"
            src={InfoIcon}
          />
        )}

        {handleDelete && (
          <img
            title="Удалить"
            onClick={handleDelete}
            className="filter-list__item__btn"
            alt="remove"
            src={DeleteIcon}
          />
        )}
      </div>

      <div className="caption-text">
        {/* {!isBottomControll && shop?.city + ', '} */}
        {shop?.city + ', ' + (shop?.address || '---  ')}
      </div>

      {isBottomControll && (
        <div className="filter-list__bottom">
          <button
            style={{ flexGrow: '1' }}
            className="btn btn-secondary"
            onClick={handleMore}
          >
            Подробнее
          </button>
          <AddToShopListBtn shop={shop} />
        </div>
      )}
    </div>
  )
}

export default ShopListItem
