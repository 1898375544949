import React from 'react'
import InfoIcon from '../../../assets/icons/info-icon.svg'
import CheckBox from '../CheckBox'

function CheckBoxItem({
  value,
  handle,
  result,
  label,
  isInfo,
  infoHandle,
  isTop,
  placeholder,
}) {
  const infoBtn = () => {
    if (isInfo) {
      return (
        <img
          className="filter-list__item__btn"
          onClick={(e) => {
            e.preventDefault()
            infoHandle()
          }}
          alt="info"
          src={InfoIcon}
        />
      )
    }
  }
  return (
    <div className={`filter-checkbox-row ${isTop ? 'top-checkbox' : ''}`}>
      <CheckBox
        placeholder={placeholder}
        handle={(v) => handle(v)}
        value={value}
      >
        {isTop ? (
          label ? (
            <div className="result">{result}</div>
          ) : null
        ) : (
          infoBtn()
        )}
      </CheckBox>

      {label ? (
        <div className="filter-checkbox-row__label">{label}</div>
      ) : (
        <div className="result">{result}</div>
      )}
    </div>
  )
}

export default CheckBoxItem
