import React, { useState } from 'react'
import PasswordIcon from '../../assets/icons/password-icon.svg'
import { Input, LoaderCircular } from '../common'
import { AuthStore } from '../../store'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'

const SignUp = observer(() => {
  const location = useLocation()
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')
  const handleForm = () => {
    if (password) {
      let code = new URLSearchParams(location.search).get('code')
      AuthStore.signUp(password, code).catch((err) => {
        setError('Ошибка')
      })
    } else {
      setError('Введите пароль')
    }
  }
  return (
    <div className="card">
      <LoaderCircular show={AuthStore.isFetch} />
      <h3 className="card__title">Регистрация в системе</h3>

      <Input
        icon={PasswordIcon}
        name="password"
        type="password"
        error={error}
        value={password}
        onChange={(v) => {
          setError('')
          setPassword(v)
        }}
        placeholder="Пароль"
      />

      <div className="error-message">{AuthStore.registerError}</div>

      <div className="card-actions">
        <button className="btn btn-primary ml-auto" onClick={handleForm}>
          Далее
        </button>
      </div>
    </div>
  )
})

export default SignUp
