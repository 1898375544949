import CloseBtn from '../../common/CloseBtn'
import ShopListItem from '../../../common/lists/ShopListItem'
import { useState } from 'react'
function ShopMultiMarker({ close, markers, open }) {
  const [count, setCount] = useState(5)
  return (
    <div className="marker-card full multimark shop-multimark">
      <CloseBtn click={close} />

      <div className="filter-list  block-custom-scroll">
        {markers.slice(0, count).map((shop) => (
          <ShopListItem
            key={shop.id}
            shop={shop}
            isBottomControll={true}
            handleMore={() => open(shop.id, shop.icon)}
          />
        ))}
        {count < markers.length && (
          <button
            className="btn btn-primary"
            style={{ margin: '0 auto 0.5rem', display: 'block' }}
            onClick={() => setCount(count + 5)}
          >
            Показать больше
          </button>
        )}
      </div>
    </div>
  )
}

export default ShopMultiMarker
