import { observable, action, makeAutoObservable } from 'mobx'
import { FlyToInterpolator } from 'react-map-gl'
import { ShoppersStore, VisitsStore } from '../'
class MapStore {
  @observable viewport = {
    zoom: 10,
    width: '100%',
    height: '100%',
    center: [50.422128, 30.509563],
    latitude: 50.422128,
    longitude: 30.509563,
  }
  @observable bounds = null

  @observable requestTimeout = null
  @observable activeMarker = null
  @observable openMarker = null
  @observable openMarkerType = null
  @observable showAttachedVisits = false
  constructor() {
    makeAutoObservable(this)
  }
  onViewportUpdate = () => {
    // let bounds = this.bounds.map((el) => el.toFixed(4))
    let bounds = this.bounds
    if (!this.openMarker) {
      ShoppersStore.loadUsers(bounds)
      VisitsStore.loadVisits(bounds)
    }
  }
  loadMapData = () => {
    // ShoppersStore.loadShoppersLazy()
    VisitsStore.loadVisitsLazy()
  }
  checkBounds = (bounds) => {
    if (bounds) {
      if (this.bounds && this.bounds.length > 0) {
        if (
          this.bounds[0] !== bounds[0] ||
          this.bounds[1] !== bounds[1] ||
          this.bounds[2] !== bounds[2] ||
          this.bounds[3] !== bounds[3]
        ) {
          this.bounds = bounds
          return true
        } else {
          return false
        }
      }
      this.bounds = bounds
      return true
    }
    return false
  }
  @action('set map viewport')
  setViewport = (viewport, bounds) => {
    this.viewport = { ...viewport }

    if (this.checkBounds(bounds)) {
      if (this.requestTimeout) {
        clearTimeout(this.requestTimeout)
      }
      if (!this.skipRequest) {
        this.requestTimeout = setTimeout(
          async () => this.onViewportUpdate(),
          300
        )
      } else {
        setTimeout(() => (this.skipRequest = false), 500)
      }
    }
  }
  @action('go to place')
  goToPlace = (lat, lng, zoom = false) => {
    let distance = Math.sqrt(
      Math.abs(
        Math.pow(lat - this.viewport.longitude, 2) -
          Math.pow(lng - this.viewport.latitude, 2)
      )
    )
    if (distance < 0.04) return
    let animationDuration = (distance * 100).toFixed(0)
    this.skipRequest = false
    this.setViewport({
      ...this.viewport,
      longitude: lng,
      latitude: lat,
      zoom: zoom ? 16 : this.viewport.zoom,
      transitionDuration: animationDuration < 100 ? 100 : animationDuration,
      transitionInterpolator: new FlyToInterpolator(),
    })
  }
  @action('go to center')
  goToCenter = () => {
    this.goToPlace(50.422128, 30.509563)
  }

  @action('set active marker')
  setActiveMarker = async (marker, isShow) => {
    this.clearOpenMarker()
    this.clearActiveMarker()
    try {
      let m
      if (marker.type === 'shop') {
        m = await VisitsStore.getVisitById(marker.id)
      } else if (marker.type === 'user') {
        m = await ShoppersStore.getShopperById(marker.id)
      }
      if (isShow && m.lng && m.lat) this.goToPlace(m.lat, m.lng, true)
      if (m) m = { ...m, type: marker.type, icon: marker.icon }
      this.activeMarker = m
    } catch (error) {
      console.log(error)
    }
  }
  @action('clear active marker')
  clearActiveMarker = () => {
    this.activeMarker = null
    this.showAttachedVisits = false
  }
  @action('set open marker')
  setOpenMarker = (markerId, { latitude, longitude }, isZoom = false) => {
    this.openMarker = markerId
    this.goToPlace(latitude, longitude, isZoom)
  }
  @action('clear open marker')
  clearOpenMarker = () => {
    this.openMarker = null
    this.openMarkerType = null
  }
  @action('close marker on map click')
  handleMapClick = (e) => {
    if (e.target.closest('.marker-wrapper')) {
      // If click inside marker, don`t close
      return
    } else {
      this.clearOpenMarker()
      // this.clearActiveMarker()
    }
  }
}

const mapStore = new MapStore()

export default mapStore
