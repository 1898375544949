import React, { useState } from 'react'
import ListTab from './VisitsFilterTabs/ListTab'
import FiltersTab from './VisitsFilterTabs/FiltersTab'

const VisitsFilter = () => {
  const [tab, setTab] = useState(1)
  return (
    <div className="filter-card__inner">
      {tab === 1 ? (
        <FiltersTab next={() => setTab(2)} />
      ) : (
        <ListTab back={() => setTab(1)} />
      )}
    </div>
  )
}

export default VisitsFilter
