import React from 'react'
import { formatDateDot } from '../../../../util'
import CloseBtn from '../../common/CloseBtn'
import { Icon } from '../../../common'

function UserMarker({ user, open, close }) {
  return (
    <div className="marker-card">
      <CloseBtn click={close} />

      <div className="marker-card__avatar">
        <img src={user.icon} alt="avatar" />
      </div>

      <div className="marker-card__name">
        {user.firstName} {user.lastName}
      </div>

      <hr className="line" />

      <div className="card-row rating">
        <div className="text-label">Посещений</div>
        <div className="ml-auto">
          <Icon icon="rate" size="1.5rem" />
          <b>{user.visitsCount}</b>
        </div>
      </div>
      <div className="card-row">
        <div className="text-label">Регистрация </div>
        <div className="ml-auto">
          <b>
            {user.registrationDate
              ? formatDateDot(user.registrationDate)
              : 'Неизвестно'}
          </b>
        </div>
      </div>
      <button className="btn btn-secondary more" onClick={() => open()}>
        Подробнее
      </button>
    </div>
  )
}

export default UserMarker
