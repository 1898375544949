import { observable, action, makeAutoObservable, computed } from 'mobx'
import Market from '../../assets/icons/market-icon.svg'
import FilterStore from './Filters/FilterStore'
import MapStore from './MapStore'
import { Visits as API } from '../API'

class VisitsStore {
  @observable visits = []
  @observable isFetch = false
  @observable isFilterFetch = false
  @observable filter = {
    projects: null,
    project: [],
    description: '',
    visits: null,
    selected: [],
    status: ['Заявка', 'Не назначено', 'Отклонено'],
    statuses: ['В процессе', 'Заявка', 'Не назначено', 'Отклонено'],
  }
  @observable isUseFilter = false
  @action('change filter')
  setFilter = (key, value) => {
    if (this.filter.hasOwnProperty(key)) {
      if (key === 'selected') {
        if (Array.isArray(value)) {
          // if array -> select all action
          if (this.filter.selected.length < this.filter.visits.length) {
            // add all elements
            this.filter.selected = this.filter.visits.map((v) => v.id)
          } else {
            // remove all elements
            this.filter.selected = []
          }
        } else {
          if (!this.filter.selected.includes(value)) {
            // add element
            this.filter.selected.push(value)
          } else {
            // remove element
            this.filter.selected.splice(this.filter.selected.indexOf(value), 1)
          }
        }
      } else {
        this.filter[key] = value
        this.filter.visits = null
        this.filter.selected = []
        this.isUseFilter = false
      }
      if (key === 'project') this.filter.description = null
      if (key === 'status') this.loadFilterProjects()
    }
  }
  @action('get visits with selected filters')
  loadFilterVisits = async () => {
    this.filter.visits = null
    this.filter.selected = []
    this.isUseFilter = false
    this.isFilterFetch = true
    this.filter.visits = await API.getFilteredVisits({
      clients: this.filter.project.join(', '),
      description: this.filter.description,
      statuses: this.filter.status.join(', '),
    })
    this.isFilterFetch = false
  }
  @action('clear filter')
  clearFilter = () => {
    this.filter = {
      ...this.filter,
      project: [],
      description: null,
    }
  }
  loadFilterProjects = async () => {
    try {
      this.isFilterFetch = true
      let projects = await API.getProjects(this.filter.status)
      this.filter.projects = projects
      this.isFilterFetch = false
    } catch (error) {
      console.log(error)
    }
  }
  constructor() {
    makeAutoObservable(this)
  }
  @action('load projects')
  loadVisits = async (bounds) => {
    try {
      if (this.isUseFilter) return
      this.isFetch = true
      const visits = await API.getVisits({
        area: bounds
          ? {
              topLeft: {
                lat: bounds[1],
                lng: bounds[0],
              },
              bottomRight: {
                lat: bounds[3],
                lng: bounds[2],
              },
            }
          : null,
        search: FilterStore.search,
        statuses: FilterStore.requestType,
        project:
          this.filter.project.length > 0 ? this.filter.project : undefined,
        description:
          this.filter.project && this.filter.description
            ? this.filter.description
            : undefined,
        my: FilterStore.location === 'Мои',
      })
      this.isFetch = false
      this.visits = visits
      return
    } catch (error) {
      console.log(error)
    }
  }
  @action('load all visits lazy')
  loadVisitsLazy = async () => {
    try {
      this.isFetch = true
      let params = {
        search: FilterStore.search,
        statuses: FilterStore.requestType,
        project:
          this.filter.project.length > 0 ? this.filter.project : undefined,
        description:
          this.filter.project && this.filter.description
            ? this.filter.description
            : undefined,
        my: FilterStore.location === 'Мои',
      }
      let visitsCount = await API.getVisitsCount(params)
      let pageSize = 1000
      let pages = Math.ceil(visitsCount / pageSize)
      let allVisits = []
      // allVisits = visits
      for (let i = 0; i < pages; i++) {
        //   localStorage.setItem('cach-visits_' + i, JSON.stringify(visits))
        let visits = await API.getVisits({
          ...params,
          limit: pageSize,
          _start: i * pageSize,
        })
        allVisits = [...allVisits, ...visits]
      }
      this.visits = allVisits
      this.isFetch = false
    } catch (error) {
      console.log(error)
    }
  }
  @action('load user by id')
  async getVisitById(id) {
    try {
      return await API.getVisit(id)
    } catch (error) {
      console.log(error)
    }
  }

  @computed get points() {
    let visits = []
    if (MapStore.showAttachedVisits) {
      // let ids = MapStore.activeMarker.attachedVisits.map((v) => v.id)
      // visits = this.visits.filter((v) => ids.includes(v.id))
      visits = MapStore.activeMarker.attachedVisits
    } else if (this.isUseFilter) {
      visits = this.filter.visits.filter((s) =>
        this.filter.selected.includes(s.id)
      )
    } else {
      visits = this.visits
    }
    return visits.length > 0
      ? visits.map((location) => {
          return {
            type: 'Feature',
            properties: {
              cluster: false,
              id: location.id,
              type: 'shop',
              data: {
                ...location,
                icon: Market,
                position: { lat: location.lat, lng: location.lng },
              },
              icon: Market,
            },
            geometry: {
              type: 'Point',
              coordinates: [location.lng, location.lat],
            },
          }
        })
      : []
  }
}

const visitsStore = new VisitsStore()

export default visitsStore
