import { observable, action, makeAutoObservable } from 'mobx'
import Icon from '../../assets/avatar.png'
import { Notifications as API } from '../API'
class NotificationsStore {
  @observable showNotifications = false
  @observable notifications = []

  constructor() {
    makeAutoObservable(this)
  }

  @action('toggle notifications')
  toggleNotifications = () => {
    this.showNotifications = !this.showNotifications
  }
  @action('Load notifications')
  loadNotifications = async () => {
    let notifications = await API.getNotifications()
    this.notifications = notifications.map((n) => ({
      icon: Icon,
      from: n.visit?.client,
      message: n.visit?.status,
      published_at: n.published_at,
      visit: n.visit,
    }))
  }
}

const notificationsStore = new NotificationsStore()

export default notificationsStore
