import { observable, action, makeAutoObservable, computed } from 'mobx'
import { Shoppers as API } from '../../API'
import ShoppersStore from '../ShoppersStore'

class ShopersFilterStore {
  @observable filteredShopers = []
  @observable filteredShopersCount = null

  @observable filters = {
    ageRange: {
      value: [0, 100],
      range: [0, 100],
    },
    sex: {
      value: null,
      variants: {
        Мужской: 'M',
        Женский: 'F',
      },
    },
    experience: {
      value: null,
      variants: {
        '1 месяц': [0, 30],
        '1-3 месяца': [30, 90],
        '3-6 месяцев': [90, 180],
        '6 месяцев - 1 год': [180, 360],
        'больше 1 года': [360, 10000],
      },
    },
    visits: {
      value: 0,
      variants: {
        'до 10': [0, 10],
        '10-50': [10, 50],
        '50-100': [50, 100],
        '100-500': [100, 500],
        'больше 500': [501, 10000],
      },
    },
    radius: {
      value: null,
      variants: {
        '5км': 5,
        '10км': 10,
        '15км': 15,
      },
    },
    address: {
      address: '',
      lat: null,
      lng: null,
    },
    isAuto: false,
    isOut: false,
    auto: '',
    search: '',
    limit: false,
    withRotation: false,
    withWalk: false,

    selected: [],
  }
  @observable isUseFilter = false
  @observable isFetch = false
  @observable bounds = []
  @observable requestTimeout = null

  constructor() {
    makeAutoObservable(this)
  }
  @action('change filter')
  setFilter = (key, value) => {
    let type = 0
    if (['sex', 'experience', 'visits', 'radius'].includes(key)) type = 1
    if (['ageRange'].includes(key)) type = 2
    if (['address', 'auto', 'search'].includes(key)) type = 3
    if (['isAuto', 'isOut', 'limit', 'withRotation', 'withWalk'].includes(key))
      type = 4
    if (['selected'].includes(key)) type = 5
    switch (type) {
      case 1:
        this.filters[key].value =
          this.filters[key].variants[value] !== undefined
            ? this.filters[key].variants[value]
            : null
        break
      case 2:
        this.filters[key].value = value
        break
      case 3:
        this.filters[key] = value
        break
      case 4:
        this.filters[key] = !this.filters[key]
        break
      case 5:
        if (this.filters.selected.includes(value)) {
          this.filters.selected.splice(this.filters.selected.indexOf(value), 1)
        } else {
          this.filters.selected.push(value)
        }
        break
      default:
        break
    }
  }
  @action('use Filter')
  setUseFilter = (val) => {
    this.isUseFilter = val
  }
  @action('Load Filtered Shoppers')
  loadShoppersSelection = async (visit) => {
    try {
      this.isFetch = true
      // ShoppersStore
      let filter = {
        minAge: ShoppersStore.filter.ageRange.value[0],
        maxAge: this.filters.ageRange.value[1],
        gender: this.filters.sex.value,
        isAuto: this.filters.isAuto,
        auto: this.filters.auto || null,
        radius: this.filters.radius.value,
        isLimit: this.filters.limit,
        isRotation: this.filters.withRotation,
        isStep: this.filters.withWalk,
        isAutoOut: this.filters.isOut,
      }
      const shoppers = await API.getShoppersRecommendation(visit.id, filter)
      this.isFetch = false
      this.filteredShopersCount = shoppers.length
      this.filteredShopers = shoppers
    } catch (err) {
      console.log(err)
    }
  }
  @action('Reset shoppers filter')
  resetFilter = () => {
    this.filters = {
      ageRange: {
        value: [0, 100],
        range: [0, 100],
      },
      sex: {
        value: null,
        variants: {
          Мужской: true,
          Женский: false,
        },
      },
      experience: {
        value: 0,
        variants: {
          '1 год': 365,
          '2 года': 365 * 2,
          '3 года': 365 * 3,
          '4 года': 365 * 4,
        },
      },
      visits: {
        value: 0,
        variants: {
          50: 50,
          100: 100,
          200: 200,
        },
      },
      radius: {
        value: null,
        variants: {
          5: 5,
          10: 10,
          15: 15,
        },
      },
      address: '',
      isAuto: false,
      isOut: false,
      auto: '',
      search: '',
      limit: false,
      withRotation: false,
      withWalk: false,

      selected: [],
    }
  }
  @action('toggle all users selected')
  setFilterAllUser = (val) => {
    this.filters.selected = val
      ? []
      : this.filteredShopers.map((s) => {
          return s.id
        })
  }
  @computed get searchFilterUsers() {
    let result = []
    result =
      this.filters.search.length > 0
        ? this.filteredShopers.filter(
            (u) =>
              (u.firstName + ' ' + u.lastName)
                .toLocaleLowerCase()
                .search(this.filters.search.toLocaleLowerCase()) !== -1
          )
        : this.filteredShopers
    return result
  }
}

const shopersStore = new ShopersFilterStore()

export default shopersStore
