import React, { Fragment } from 'react'

import CheckBoxItem from './CheckBoxItem'
import LoadingIndicator from '../LoadingIndicator'

import { useObserver } from 'mobx-react'

function CheckBoxList({
  isAllSelected,
  handleTop,
  topResult,
  topPlaceholder,
  topLabel,
  list,
  filteredList,
  handleItem,
  handleItemInfo,
  getName,
  countKey,
  isLoading,
}) {
  return useObserver(() => (
    <Fragment>
      <CheckBoxItem
        value={isAllSelected}
        placeholder={topPlaceholder}
        handle={handleTop}
        result={!isLoading ? topResult : '--'}
        label={topLabel}
        isTop="true"
      />

      <div className="filter__line"></div>

      <div className="block-custom-scroll">
        <LoadingIndicator show={isLoading} />
        {!isLoading &&
          list.map((item) => (
            <CheckBoxItem
              key={item.id}
              value={filteredList.includes(item.id)}
              placeholder={getName(item)}
              handle={() => handleItem(item.id)}
              isInfo={!!handleItemInfo}
              infoHandle={() => handleItemInfo(item)}
              result={item[countKey]}
            />
          ))}
      </div>
    </Fragment>
  ))
}

export default CheckBoxList
