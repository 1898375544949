import React from 'react'
import CloseBtn from '../../common/CloseBtn'
import AddToShopListBtn from '../../common/AddToShopListBtn'
import { formatDateDot } from '../../../../util'
function ShopOpenMarker({ shop, open, close }) {
  const statusClass = {
    Выполнено: 'success',
    'В процессе': 'warning',
    Заявка: 'error',
    'Не назначено': 'error',
  }[shop ? shop.status : 'Не назначено']
  console.log(shop)
  return (
    <div className="marker-card shop">
      <CloseBtn click={close} />

      <div className="marker-card__avatar">
        <img src={shop.icon} alt="avatar" />
      </div>

      <div className="marker-card__fullname">{shop.client}</div>
      <div className="marker-card__address">
        {shop?.city + ', ' + (shop?.address || '---  ')}
      </div>

      <hr className="line" />

      <div className="card-row status">
        <div className="text-label">Статус</div>
        <div className="ml-auto">
          <b className={statusClass}>{shop.status}</b>
        </div>
      </div>
      <div className="card-row">
        <div className="text-label">Стоимость</div>
        <div className="ml-auto">
          <b>
            {shop.payRate} {shop.currency}
          </b>
        </div>
      </div>
      <div className="card-row">
        <div className="text-label">Создание</div>
        <div className="ml-auto">
          <b>{formatDateDot(shop.published_at)}</b>
        </div>
      </div>
      <div
        className="card__bottom"
        style={{ flexGrow: '1', margin: '-1.25rem 0 -3rem' }}
      >
        <button
          style={{ flexGrow: '1' }}
          className="btn btn-secondary more"
          onClick={() => open()}
        >
          Подробнее
        </button>
        <AddToShopListBtn shop={shop} />
      </div>
    </div>
  )
}

export default ShopOpenMarker
