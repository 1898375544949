import { useEffect, useRef } from 'react'
import { Marker } from 'react-map-gl'
const CustomMarker = ({ isOpen, latitude, longitude, children, isMulti }) => {
  const markerRef = useRef()
  useOpenMarker({ isOpen, ref: markerRef })
  return (
    <Marker latitude={latitude} longitude={longitude} type>
      <div
        className={`marker-wrapper ${isMulti ? 'multi-mark' : ''}`}
        ref={markerRef}
      >
        {children}
      </div>
    </Marker>
  )
}

export default CustomMarker

function useOpenMarker({ isOpen, ref }) {
  useEffect(() => {
    let el = ref.current
    if (isOpen && ref) {
      el.closest('.mapboxgl-marker').style.zIndex = '100'
      return () => {
        el.closest('.mapboxgl-marker').style.zIndex = '1'
      }
    }
    return
  })
}
