import { observable, action, makeAutoObservable, computed } from 'mobx'
import ManAvatar from '../../assets/profileMan.svg'
import WomanAvatar from '../../assets/profileWoman.svg'
import FilterStore from './Filters/FilterStore'
import { Shoppers as API } from '../API'
import MapStore from './MapStore'
class ShoppersStore {
  @observable shoppers = []
  @observable isFetch = false
  constructor() {
    makeAutoObservable(this)
  }
  @action('load users')
  loadUsers = async (bounds) => {
    try {
      this.isFetch = true
      let shoppers = await API.getShoppers({
        area: bounds
          ? {
              topLeft: {
                lat: bounds[1],
                lng: bounds[0],
              },
              bottomRight: {
                lat: bounds[3],
                lng: bounds[2],
              },
            }
          : null,
        search: FilterStore.search,
      })
      this.isFetch = false
      this.shoppers = shoppers.map((s) => ({
        position: { lat: s.lat, lng: s.lng },
        icon: s.gender === 'M' ? ManAvatar : WomanAvatar,
        type: 'user',
        ...s,
      }))
    } catch (error) {
      console.log(error)
    }
  }
  @action('load all visits lazy')
  loadShoppersLazy = async () => {
    try {
      this.isFetch = true
      let params = {
        search: FilterStore.search,
      }
      let shoppersCount = await API.getShoppersCount(params)
      let pageSize = 1000
      let pages = Math.ceil(shoppersCount / pageSize)
      for (let i = 0; i < pages; i++) {
        let shoppers = await API.getShoppers({
          ...params,
          _limit: pageSize,
          _start: i * pageSize,
        })
        this.shoppers = [
          ...this.shoppers,
          ...shoppers.map((s) => ({
            position: { lat: s.lat, lng: s.lng },
            icon: s.gender === 'M' ? ManAvatar : WomanAvatar,
            type: 'user',
            ...s,
          })),
        ]
      }
      this.isFetch = false
    } catch (error) {
      console.log(error)
    }
  }
  @action('load user by id')
  async getShopperById(id) {
    try {
      return await API.getShopper(id)
    } catch (error) {
      console.log(error)
    }
  }
  @action('update user by id')
  updateShopper = async (key, value) => {
    try {
      if (['isBadShopper', 'isAuto', 'comment'].includes(key)) {
        await API.updateShopper(MapStore.activeMarker.id, {
          [key]: value,
        })
        MapStore.activeMarker = { ...MapStore.activeMarker, [key]: value }
        return
      }
    } catch (error) {
      console.log(error)
    }
  }

  @computed get users() {
    if (this.isUseFilter) {
      return this.shoppers.filter((s) => this.filterSelected.includes(s.id))
    } else {
      return this.shoppers
    }
  }
  @computed get points() {
    return this.users.length
      ? this.users.map((user) => {
          return {
            type: 'Feature',
            properties: {
              cluster: false,
              id: user.id,
              type: user.type,
              data: { ...user },
              icon: user.icon,
            },
            geometry: {
              type: 'Point',
              coordinates: [user.position.lng, user.position.lat],
            },
          }
        })
      : []
  }

  // FILTER
  @observable isUseFilter = false
  @observable filter = {
    ageRange: [18, 50],
    gender: null,
    experience: null,
    visits: null,
    radius: { label: '1км', value: 1 },
    region: null,
    address: '',
    isAuto: false,
    isAutoOut: false,
    auto: null,
    isLimit: true,
    isRotation: true,
    isStep: true,
    search: '',
  }
  @observable filterOptions = {
    gender: [
      { label: 'Не выбрано', value: null },
      { label: 'Мужской', value: 'M' },
      { label: 'Женский', value: 'F' },
    ],
    experience: [
      { label: '1 месяц', value: [0, 30] },
      { label: '1-3 месяца', value: [30, 90] },
      { label: '3-6 месяцев', value: [90, 180] },
      { label: '6 месяцев - 1 год', value: [180, 360] },
      { label: 'больше 1 года', value: [360, 10000] },
    ],
    visits: [
      { label: 'до 10', value: [0, 10] },
      { label: '10-50', value: [10, 50] },
      { label: '50-100', value: [50, 100] },
      { label: '100-500', value: [100, 500] },
      { label: 'больше 500', value: [501, 10000] },
    ],
    radius: [
      { label: '1км', value: 1 },
      { label: '2км', value: 2 },
      { label: '10км', value: 10 },
      { label: '30км', value: 30 },
      { label: '100км', value: 100 },
    ],
    regions: null,
  }
  @observable filteredShoppers = null
  @observable filterSelected = []
  @action('change filter')
  changeFilter = (key, val) => {
    if (key === 'select') {
      this.filterSelected = [val]
    } else {
      this.filter[key] = val
    }
  }
  @action('Show user on map and open user marker')
  showInfo = (shopper) => {
    if (shopper.lat && shopper.lng)
      MapStore.goToPlace(shopper.lat, shopper.lng, true)
  }

  @action('Select all shoppers in filter')
  selectAllShoppers = () => {
    this.filterSelected =
      this.filterSelected.length === this.filteredShoppers.length
        ? []
        : this.filteredShoppers.map((s) => s.id)
  }
  @action('Clear shoppers Filter')
  clearFilter = () => {
    this.filter = {
      ageRange: [18, 50],
      gender: null,
      experience: null,
      visits: null,
      radius: { label: '1км', value: 1 },
      region: null,
      address: null,
      isAuto: false,
      isAutoOut: false,
      auto: null,
      isLimit: true,
      isRotation: true,
      isStep: true,
      search: '',
    }
    this.filteredShoppers = null
    this.filterSelected = []
    this.isUseFilter = false
  }
  @action('Search for results with filters')
  submitFilter = async () => {
    try {
      this.isFetch = true
      let shoppers = await API.getFilteredShoppers({
        age: this.filter.ageRange.join(' '),
        gender: this.filter.gender?.value,
        experience: this.filter.experience?.value.join(' '),
        visits: this.filter.visits?.value.join(' '),
        region: this.filter.region?.full_name || null,
        isAuto: this.filter.isAuto,
        auto: this.filter.auto,
        isAutoOut: this.filter.isAutoOut,
      })
      this.isFetch = false
      this.filteredShoppers = shoppers.map((s) => ({
        position: { lat: s.lat, lng: s.lng },
        icon: s.gender === 'M' ? ManAvatar : WomanAvatar,
        type: 'user',
        ...s,
      }))
      // this.isUseFilter = true
    } catch (error) {
      console.log(error)
    }
  }
  @action('use filtered shoppers on map')
  filterUse = () => {
    FilterStore.toggleFilter()
    this.isUseFilter = true
  }
  @computed get searchFilteredShoppers() {
    let result = []
    result =
      this.filter.search.length > 0
        ? this.filteredShoppers.filter(
            (u) =>
              (u.firstName + ' ' + u.lastName)
                .toLocaleLowerCase()
                .search(this.filter.search.toLocaleLowerCase()) !== -1
          )
        : this.filteredShoppers
    return result
  }
  @computed get regions() {
    if (!this.filter.regions) {
      ;(async () => {
        this.filter.regions = await API.getRegions()
        return []
      })()
    }
    return this.filter.regions
  }
}

const shoppersStore = new ShoppersStore()

export default shoppersStore
