import React, { useEffect } from 'react'
import { UsersStore, AuthStore } from '../store'
import { observer } from 'mobx-react-lite'
import Panel from '../components/users/Panel'
import Table from '../components/users/Table'
import UserDialog from '../components/users/UserDialog'
import Loader from '../components/common/LoaderCircular'

const Users = observer(() => {
  useEffect(() => {
    UsersStore.loadUsers()
  }, [])
  const headers = [
    'Cтатус',
    'Имя ',
    'Отчество',
    'Фамилия ',
    'E-mail',
    'Роль',
    '',
  ]
  const users = UsersStore.users.map((user) => ({
    firstName: user.firstName,
    lastName: user.surname,
    patronymic: user.patronymic,
    confirmed: user.isConfirmed,
    ...user,
  }))
  const activeUser = UsersStore.activeUser
  const handleAction = (type, user) => {
    switch (type) {
      case 'edit':
        UsersStore.editUser(user)
        break
      case 'delete':
        UsersStore.deleteUser(user.id)
        break
      default:
        break
    }
  }
  const onSearch = (v) => UsersStore.updateSearch(v)
  const onNewUser = () => UsersStore.addNewUser()
  const search = UsersStore.search
  const isFetch = UsersStore.isFetch
  const isAllowEdit = (el) => AuthStore.getPermission('user-edit', el)
  const isAllowDelete = (el) => AuthStore.getPermission('user-delete', el)

  return (
    <div className="page-content users-page" style={{ overflow: 'auto' }}>
      <div className="card">
        <Panel search={search} onSearch={onSearch} onNewUser={onNewUser} />
        <div className="card-line"></div>
        <Table
          users={users}
          headers={headers}
          action={handleAction}
          isAllowEdit={isAllowEdit}
          isAllowDelete={isAllowDelete}
        />
        <Loader show={isFetch} />
        <UserDialog show={activeUser} />
      </div>
    </div>
  )
})

export default Users
