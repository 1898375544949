function Marker(props) {
  return (
    <div
      className={`marker--min ${props.type} ${props.isInList ? 'in-list' : ''}`}
    >
      <div onClick={props.open} className="marker--min__icon">
        <img src={props.icon} alt="avatar" />
      </div>
      <div className="marker--min__label">
        {props.type === 'user' && (
          <svg
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 6.5V11H3V6.5H1Z" fill="#A6ACBE" />
            <path d="M9 4V11H11V4H9Z" fill="#A6ACBE" />
            <path d="M5 2V11H7V2H5Z" fill="#A6ACBE" />
          </svg>
        )}
        {props.label || 0}
      </div>
    </div>
  )
}

export default Marker
