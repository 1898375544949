import ShopMultiMarkerOpen from './ShopMultiMarkerOpen'

function ShopMultiMarker({
  markers,
  isOpen,
  openMarker,
  close,
  openCard,
  payRate,
  point_count,
  currency,
}) {
  if (isOpen)
    return (
      <div className="marker-wrapper multi-mark">
        <ShopMultiMarkerOpen markers={markers} open={openCard} close={close} />
      </div>
    )

  return (
    <div className="marker-wrapper multi-mark" onClick={openMarker}>
      <MarkerAvatarShop
        count={point_count}
        payRate={payRate}
        currency={currency}
      />
    </div>
  )
}

export default ShopMultiMarker

function MarkerAvatarShop(props) {
  return (
    <div className="marker--min">
      <svg
        className="multimark"
        viewBox="0 0 68 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_f)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.0666 66.0073C57.7586 63.9976 66 59.1549 66 53.5C66 46.0442 51.6731 40 34 40C16.3269 40 2 46.0442 2 53.5C2 59.237 10.4826 64.1382 22.4453 66.0931C22.2125 66.1688 22.3974 66.2438 23.1235 66.3136C29.6661 66.9429 33.9999 72 33.9999 72C33.9999 72 35.6328 67.9848 44.8076 66.3136C45.4362 66.1991 45.8461 66.0973 46.0666 66.0073Z"
            fill="url(#paint0_linear)"
            fillOpacity="0.3"
          />
        </g>
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="2"
          y="0"
          width="64"
          height="72"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.9407 63.0071C55.7726 59.4754 66 46.9321 66 32C66 14.3269 51.6731 0 34 0C16.3269 0 2 14.3269 2 32C2 46.9321 12.2274 59.4754 26.0593 63.0071C26.0203 63.0025 26 63 26 63C26 63 28.7886 65.2898 30.2941 67.1176C31.9855 69.1711 34 72 34 72C34 72 36.0057 69.1869 37.6882 67.1398C39.1989 65.3015 42 63 42 63C42 63 41.9797 63.0025 41.9407 63.0071Z"
            fill="#202430"
          />
        </mask>
        <g mask="url(#mask0)">
          <rect x="-2" width="72" height="72" fill="white" />
        </g>
        <path
          d="M1.5 15.5C1.5 7.76801 7.76801 1.5 15.5 1.5C23.232 1.5 29.5 7.76801 29.5 15.5C29.5 23.232 23.232 29.5 15.5 29.5C7.76801 29.5 1.5 23.232 1.5 15.5Z"
          fill="#F8F8F8"
        />
        <text x="15" y="20" className="market__count">
          {props.count}
        </text>
        <path
          x="15"
          y="20"
          d="M15.5 28.5C8.3203 28.5 2.5 22.6797 2.5 15.5H0.5C0.5 23.7843 7.21573 30.5 15.5 30.5V28.5ZM28.5 15.5C28.5 22.6797 22.6797 28.5 15.5 28.5V30.5C23.7843 30.5 30.5 23.7843 30.5 15.5H28.5ZM15.5 2.5C22.6797 2.5 28.5 8.3203 28.5 15.5H30.5C30.5 7.21573 23.7843 0.5 15.5 0.5V2.5ZM15.5 0.5C7.21573 0.5 0.5 7.21573 0.5 15.5H2.5C2.5 8.3203 8.3203 2.5 15.5 2.5V0.5Z"
          fill="#DCDCDC"
        />
      </svg>
      <svg
        className="multimark center shop"
        viewBox="0 0 68 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.0668 41.937C26.0476 41.9202 25.0765 41.5006 24.3657 40.7699C23.6549 40.0393 23.2623 39.0569 23.2737 38.0376C23.274 37.8952 23.3029 37.7543 23.3587 37.6232L27.1943 28.762C27.2765 28.5736 27.4116 28.4131 27.5832 28.2999C27.7549 28.1868 27.9556 28.1258 28.1612 28.1245H32.1031C32.2653 28.1249 32.4253 28.1624 32.5708 28.2343C32.7163 28.3061 32.8434 28.4103 32.9424 28.5389C33.04 28.6675 33.1069 28.8166 33.1382 28.9749C33.1695 29.1332 33.1643 29.2966 33.1231 29.4526L30.8599 38.1864C30.8353 39.1807 30.4264 40.1268 29.7192 40.8261C29.012 41.5254 28.0613 41.9236 27.0668 41.937ZM25.4093 38.2395C25.4455 38.659 25.6341 39.0506 25.9395 39.3404C26.245 39.6302 26.646 39.798 27.0668 39.812C27.5243 39.7981 27.9577 39.6037 28.2723 39.2712C28.5868 38.9387 28.757 38.4952 28.7456 38.0376C28.7352 37.9494 28.7352 37.8602 28.7456 37.772L30.7218 30.2495H28.8624L25.4093 38.2395Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M32.5393 41.9376C32.0342 41.9306 31.5354 41.8243 31.0714 41.6246C30.6074 41.4248 30.1873 41.1357 29.835 40.7736C29.4828 40.4115 29.2054 39.9835 29.0185 39.5142C28.8317 39.0448 28.7392 38.5433 28.7462 38.0382C28.7358 37.9499 28.7358 37.8608 28.7462 37.7725L31.073 28.9219C31.1333 28.6881 31.2713 28.4818 31.4644 28.337C31.6575 28.1921 31.8942 28.1174 32.1355 28.125H36.0667C36.2142 28.124 36.3602 28.1537 36.4956 28.2123C36.631 28.2708 36.7527 28.3569 36.8529 28.465C36.9518 28.5753 37.0265 28.7051 37.0722 28.846C37.118 28.9869 37.1338 29.1358 37.1186 29.2831L36.3536 38.1338C36.3536 38.6342 36.2548 39.1297 36.063 39.5919C35.8712 40.0541 35.5901 40.4739 35.2357 40.8272C34.8814 41.1806 34.4608 41.4605 33.9981 41.6511C33.5354 41.8416 33.0397 41.939 32.5393 41.9376ZM30.8712 38.1657C30.8795 38.3847 30.931 38.6 31.0225 38.7992C31.1141 38.9984 31.244 39.1776 31.4048 39.3265C31.5656 39.4755 31.7542 39.5914 31.9598 39.6675C32.1654 39.7436 32.3839 39.7784 32.603 39.7701C32.8221 39.7617 33.0373 39.7102 33.2365 39.6187C33.4357 39.5271 33.6149 39.3972 33.7639 39.2364C33.9128 39.0756 34.0287 38.887 34.1048 38.6814C34.1809 38.4758 34.2157 38.2572 34.2074 38.0382L34.8767 30.25H32.9217L30.8712 38.1657Z"
          fill="url(#paint2_linear)"
        />
        <path
          d="M38 41.9376C37.4949 41.9307 36.9962 41.8243 36.5322 41.6246C36.0682 41.4249 35.6481 41.1357 35.2959 40.7736C34.9436 40.4115 34.6662 39.9835 34.4794 39.5142C34.2926 39.0448 34.2 38.5433 34.207 38.0382L34.972 29.0918C34.9959 28.8269 35.1184 28.5806 35.3151 28.4016C35.5118 28.2226 35.7685 28.1239 36.0345 28.125H39.9656C40.2316 28.1239 40.4883 28.2226 40.685 28.4016C40.8817 28.5806 41.0041 28.8269 41.0281 29.0918L41.793 37.9532C41.8086 38.4646 41.7225 38.9741 41.5398 39.452C41.357 39.9299 41.0813 40.3668 40.7285 40.7374C40.3757 41.108 39.9529 41.4049 39.4845 41.611C39.0162 41.817 38.5116 41.928 38 41.9376ZM37.0119 30.25L36.3213 38.1338C36.3108 38.3645 36.3479 38.5949 36.4305 38.8106C36.5131 39.0263 36.6392 39.2227 36.8011 39.3874C36.963 39.5521 37.1572 39.6816 37.3714 39.7679C37.5856 39.8541 37.8154 39.8952 38.0462 39.8886C38.2771 39.882 38.5041 39.8279 38.713 39.7295C38.922 39.6312 39.1084 39.4908 39.2607 39.3171C39.4129 39.1434 39.5277 38.9402 39.5978 38.7201C39.6679 38.5001 39.6918 38.2679 39.6681 38.0382L38.9881 30.25H37.0119Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M43.4609 41.9376C42.9558 41.9306 42.457 41.8243 41.9931 41.6246C41.5291 41.4248 41.109 41.1357 40.7568 40.7736C40.4045 40.4115 40.1271 39.9835 39.9403 39.5142C39.7534 39.0448 39.6609 38.5433 39.6679 38.0382L38.9136 29.2831C38.8984 29.1358 38.9141 28.9869 38.9599 28.846C39.0056 28.7051 39.0803 28.5753 39.1792 28.465C39.2795 28.3569 39.4012 28.2708 39.5365 28.2123C39.6719 28.1537 39.8179 28.124 39.9654 28.125H43.8965C44.1378 28.1174 44.3745 28.1921 44.5676 28.337C44.7607 28.4818 44.8988 28.6881 44.959 28.9219L47.2539 37.7725C47.2643 37.8608 47.2643 37.9499 47.2539 38.0382C47.261 38.5433 47.1684 39.0448 46.9816 39.5142C46.7948 39.9835 46.5173 40.4115 46.1651 40.7736C45.8129 41.1357 45.3928 41.4248 44.9288 41.6246C44.4648 41.8243 43.966 41.9306 43.4609 41.9376ZM41.1235 30.25L41.7929 37.9532C41.7785 38.1852 41.8105 38.4177 41.8868 38.6373C41.9632 38.8568 42.0825 39.059 42.2377 39.232C42.3929 39.4051 42.581 39.5455 42.791 39.6452C43.001 39.7448 43.2287 39.8017 43.4609 39.8126C43.8956 39.7995 44.3092 39.6225 44.6186 39.317C44.9281 39.0114 45.1104 38.6001 45.129 38.1657L43.0784 30.25H41.1235Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M48.9331 41.9375C47.9386 41.9241 46.988 41.5259 46.2807 40.8266C45.5735 40.1273 45.1646 39.1812 45.14 38.1869L42.8769 29.4531C42.8356 29.2971 42.8304 29.1337 42.8617 28.9754C42.893 28.8171 42.96 28.668 43.0575 28.5394C43.1565 28.4108 43.2836 28.3066 43.4291 28.2348C43.5746 28.1629 43.7346 28.1254 43.8969 28.125H47.8388C48.0443 28.1263 48.2451 28.1873 48.4167 28.3004C48.5883 28.4136 48.7234 28.5741 48.8056 28.7625L52.6413 37.6237C52.6971 37.7548 52.726 37.8957 52.7263 38.0381C52.7376 39.0574 52.345 40.0398 51.6342 40.7704C50.9235 41.5011 49.9523 41.9207 48.9331 41.9375ZM45.2781 30.25L47.2225 37.7725C47.2329 37.8607 47.2329 37.9499 47.2225 38.0381C47.2092 38.2572 47.2393 38.4767 47.3109 38.6842C47.3824 38.8916 47.4942 39.083 47.6397 39.2472C47.7852 39.4115 47.9617 39.5455 48.159 39.6416C48.3563 39.7377 48.5706 39.7939 48.7897 39.8072C49.0088 39.8204 49.2283 39.7904 49.4357 39.7188C49.6432 39.6473 49.8345 39.5355 49.9988 39.39C50.1631 39.2445 50.2971 39.068 50.3931 38.8707C50.4892 38.6734 50.5455 38.4591 50.5588 38.24L47.1375 30.25H45.2781Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M49.6875 57.8751H26.3125C26.0307 57.8751 25.7605 57.7631 25.5612 57.5639C25.3619 57.3646 25.25 57.0944 25.25 56.8126V40.8751C25.25 40.5933 25.3619 40.323 25.5612 40.1238C25.7605 39.9245 26.0307 39.8126 26.3125 39.8126C26.5943 39.8126 26.8645 39.9245 27.0638 40.1238C27.2631 40.323 27.375 40.5933 27.375 40.8751V55.7501H48.625V40.8751C48.625 40.5933 48.7369 40.323 48.9362 40.1238C49.1355 39.9245 49.4057 39.8126 49.6875 39.8126C49.9693 39.8126 50.2395 39.9245 50.4388 40.1238C50.6381 40.323 50.75 40.5933 50.75 40.8751V56.8126C50.75 57.0944 50.6381 57.3646 50.4388 57.5639C50.2395 57.7631 49.9693 57.8751 49.6875 57.8751Z"
          fill="url(#paint6_linear)"
        />
        <path
          d="M42.25 57.8748H33.75C33.4682 57.8748 33.198 57.7629 32.9987 57.5636C32.7994 57.3644 32.6875 57.0941 32.6875 56.8123V51.4998C32.6875 51.218 32.7994 50.9478 32.9987 50.7485C33.198 50.5493 33.4682 50.4373 33.75 50.4373C34.0318 50.4373 34.302 50.5493 34.5013 50.7485C34.7006 50.9478 34.8125 51.218 34.8125 51.4998V55.7498H41.1875V49.3748H36.9375C36.6557 49.3748 36.3855 49.2629 36.1862 49.0636C35.9869 48.8644 35.875 48.5941 35.875 48.3123C35.875 48.0305 35.9869 47.7603 36.1862 47.561C36.3855 47.3618 36.6557 47.2498 36.9375 47.2498H42.25C42.5318 47.2498 42.802 47.3618 43.0013 47.561C43.2006 47.7603 43.3125 48.0305 43.3125 48.3123V56.8123C43.3125 57.0941 43.2006 57.3644 43.0013 57.5636C42.802 57.7629 42.5318 57.8748 42.25 57.8748Z"
          fill="url(#paint7_linear)"
        />
        <path
          d="M33.75 49.3748C34.3368 49.3748 34.8125 48.8991 34.8125 48.3123C34.8125 47.7255 34.3368 47.2498 33.75 47.2498C33.1632 47.2498 32.6875 47.7255 32.6875 48.3123C32.6875 48.8991 33.1632 49.3748 33.75 49.3748Z"
          fill="url(#paint8_linear)"
        />
        <defs>
          <filter
            id="filter0_f"
            x="4"
            y="45"
            width="68"
            height="36"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="-79.6341"
            y1="44.8043"
            x2="-79.6341"
            y2="151.41"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.01" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="23.2734"
            y1="37.7242"
            x2="33.6847"
            y2="35.1909"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF2D38" />
            <stop offset="1" stopColor="#E5264C" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="28.7384"
            y1="37.7246"
            x2="37.7113"
            y2="35.8725"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF2D38" />
            <stop offset="1" stopColor="#E5264C" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="34.2067"
            y1="37.7247"
            x2="42.3866"
            y2="36.1969"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF2D38" />
            <stop offset="1" stopColor="#E5264C" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="38.908"
            y1="37.7246"
            x2="47.8494"
            y2="35.886"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF2D38" />
            <stop offset="1" stopColor="#E5264C" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="42.8416"
            y1="37.7247"
            x2="53.2528"
            y2="35.1914"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF2D38" />
            <stop offset="1" stopColor="#E5264C" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="25.25"
            y1="52.366"
            x2="48.3708"
            y2="41.268"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF2D38" />
            <stop offset="1" stopColor="#E5264C" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="32.6875"
            y1="54.6342"
            x2="43.3125"
            y2="51.0217"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF2D38" />
            <stop offset="1" stopColor="#E5264C" />
          </linearGradient>
          <linearGradient
            id="paint8_linear"
            x1="32.6875"
            y1="48.7267"
            x2="34.8125"
            y2="48.0042"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF2D38" />
            <stop offset="1" stopColor="#E5264C" />
          </linearGradient>
        </defs>
      </svg>
      <div className="marker--min__label" style={{ marginTop: 0 }}>
        {props.payRate} {props.currency}
      </div>
    </div>
  )
}
