import { useState } from 'react'

import Charts from './tabs/Charts'
import Table from './tabs/Table'
import Exeptions from './tabs/Exeptions'

function Tabs() {
  const [activeTab, setActiveTab] = useState('График выполнения')
  let tabList = ['График выполнения', 'Таблица', 'Управление исключениями']

  const showTab = () => {
    switch (activeTab) {
      case 'График выполнения':
        return <Charts />
      case 'Таблица':
        return <Table />
      case 'Управление исключениями':
        return <Exeptions />
      default:
        return <Charts />
    }
  }

  return (
    <div className="analytics-tabs">
      <div className="analytics-tabs-header">
        {tabList.map((t) => (
          <div
            key={t}
            onClick={() => setActiveTab(t)}
            className={`analytics-tabs-header__item ${
              t === activeTab ? 'active' : ''
            }`}
          >
            {t}
          </div>
        ))}
      </div>
      <div className="analytics-tabs-body">{showTab()}</div>
    </div>
  )
}

export default Tabs
