function CheckBox(props) {
  return (
    <label
      className={`checkbox-wrapper ${props.error ? 'error' : ''} ${
        props.value ? 'active' : ''
      }`}
    >
      {props.placeholder}
      {props.children}
      <input
        type="checkbox"
        checked={props.value}
        readOnly={props.readOnly}
        onChange={(e) =>
          props.handle && !props.readOnly
            ? props.handle(e.target.checked)
            : null
        }
      />
      <span className="checkmark"></span>
    </label>
  )
}

export default CheckBox
