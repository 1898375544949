import SignInCard from '../components/auth/SignIn'
import { Fragment } from 'react'
import Footer from '../components/Footer'

function Login() {
  return (
    <Fragment>
      <div className="page-content auth-page">
        <SignInCard />
      </div>
      <Footer></Footer>
    </Fragment>
  )
}

export default Login
