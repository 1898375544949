import React from 'react'

import CheckBoxList from '../../../common/lists/CheckBoxList'
import SearchInput from '../../../common/SearchInput'

import { observer } from 'mobx-react-lite'

import { ShoppersStore, MapStore } from '../../../../store/'
const ListTab = observer(({ back }) => {
  const shoppers = ShoppersStore.searchFilteredShoppers || []
  const selectedShoppers = ShoppersStore.filterSelected
  const isFetch = ShoppersStore.isFetch
  const isAllSelected = shoppers.length === selectedShoppers.length
  const search = ShoppersStore.filter.search

  const selectShopper = (id) => ShoppersStore.changeFilter('select', id)
  const selectAll = () => ShoppersStore.selectAllShoppers()
  const handleSearch = (v) => ShoppersStore.changeFilter('search', v)
  const handleFilter = () => ShoppersStore.filterUse()
  return (
    <>
      <SearchInput
        id="shopper-list"
        placeholder="Поиск..."
        value={search}
        handle={handleSearch}
      />

      <CheckBoxList
        isAllSelected={isAllSelected}
        topPlaceholder="Всего шоперов:"
        handleTop={selectAll}
        topResult={shoppers.length}
        list={shoppers}
        filteredList={selectedShoppers}
        handleItem={(id) => {
          selectShopper(id)
        }}
        handleItemInfo={(shopper) => {
          MapStore.setActiveMarker({ ...shopper, type: 'user' }, true)
        }}
        getName={(shopper) => shopper.firstName + ' ' + shopper.lastName}
        countKey="visitsCount"
        isLoading={isFetch}
        topLabel={
          <svg
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 6.5V11H3V6.5H1Z" fill="#A6ACBE" />
            <path d="M9 4V11H11V4H9Z" fill="#A6ACBE" />
            <path d="M5 2V11H7V2H5Z" fill="#A6ACBE" />
          </svg>
        }
      />

      <div className="filter-list__bottom">
        <button className="btn btn-secondary" onClick={back}>
          Назад
        </button>
        <button className="btn btn-primary" onClick={handleFilter}>
          Применить
        </button>
      </div>
    </>
  )
})

export default ListTab
