import { useState } from 'react'
import { Icon } from '../../../common'
import CloseBtn from '../../common/CloseBtn'

function UserMultiMarker({ markers, open, close }) {
  const [count, setCount] = useState(5)
  return (
    <div className="marker-card full multimark multimarker--user">
      <CloseBtn click={close} />

      <div className="filter-list block-custom-scroll">
        {markers.slice(0, count).map((user, i) => (
          <div className="filter-list__item" key={i}>
            <div className="filter-list__item-top">
              <img
                src={user.icon}
                className="filter-list__avatar"
                alt={user.firstName + ' ' + user.lastName}
              />
              <div style={{ flexGrow: 1 }}>
                <div className="filter-list__title">
                  {user.firstName} {user.lastName}
                </div>
                <div className="filter-list__price">
                  <Icon icon="rate" size="1.5rem" />
                  {user.visitsCount}
                </div>
              </div>
            </div>

            <div className="filter-list__bottom">
              <button
                style={{
                  margin: '1rem 0 0 auto',
                  width: '100%',
                  display: 'block',
                }}
                className="btn btn-secondary"
                onClick={() => open(user.id, user.icon)}
              >
                Подробнее
              </button>
            </div>
          </div>
        ))}

        {count < markers.length && (
          <button
            className="btn btn-primary"
            style={{ margin: '0 auto 0.5rem', display: 'block' }}
            onClick={() => setCount(count + 5)}
          >
            Показать больше
          </button>
        )}
      </div>
    </div>
  )
}

export default UserMultiMarker
