import Shopers from './filterMenuTabs/ShopersFilter'
import Projects from './filterMenuTabs/ProjectsFilter'
import 'rc-slider/assets/index.css'

import React, { useState } from 'react'
import { useObserver } from 'mobx-react'

function FilterMenu({ show }) {
  const [tab, setTab] = useState('Проекты')

  return useObserver(() => (
    <div className={`filter-card filter-menu ${show ? 'active' : ''}`}>
      <div className="filter-menu-header">
        <div
          onClick={() => setTab('Проекты')}
          className={`filter-menu-header__item ${
            tab === 'Проекты' ? 'active' : ''
          }`}
        >
          Проекты
        </div>
        <div
          onClick={() => setTab('Шоперы')}
          className={`filter-menu-header__item ${
            tab === 'Шоперы' ? 'active' : ''
          }`}
        >
          Шоперы
        </div>
      </div>

      {tab === 'Проекты' ? (
        <Projects></Projects>
      ) : (
        <Shopers changeTab={() => setTab('Проекты')} />
      )}
    </div>
  ))
}

export default FilterMenu
