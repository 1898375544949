import React, { useState } from 'react'
import HistoryTable from './HistoryTable'
import InfoTable from './InfoTable'

function CardBottom({ infoListData, infoActions, historyActions, history }) {
  const [tabPrimary, setTabPrimary] = useState('info')

  return (
    <div className="history-table">
      <div className="history-table-header">
        <div
          className={`history-table-header__item ${
            tabPrimary === 'info' ? 'active' : ''
          }`}
          onClick={() => setTabPrimary('info')}
        >
          Инфо
        </div>
        <div
          className={`history-table-header__item ${
            tabPrimary === 'history' ? 'active' : ''
          }`}
          onClick={() => setTabPrimary('history')}
        >
          История
        </div>
      </div>
      {tabPrimary === 'info' && (
        <React.Fragment>
          <InfoTable list={infoListData} />
          {infoActions}
        </React.Fragment>
      )}
      {tabPrimary === 'history' && (
        <React.Fragment>
          <HistoryTable history={history} />
          {historyActions}
        </React.Fragment>
      )}
    </div>
  )
}

export default CardBottom
