function Icon({ icon, height, width, className, size, onClick }) {
  return (
    <div
      className={`icon-wrapper ${className ? className : ''}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        height: height || size,
        width: width || size,
        minWidth: width || size,
        cursor: onClick ? 'pointer' : 'auto',
      }}
      onClick={(e) => {
        if (onClick) onClick(e)
      }}
    >
      {ICONS[icon]}
    </div>
  )
}

export default Icon

const ICONS = {
  settings: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.7237 21.1845C20.951 21.1845 21.1689 21.0943 21.3296 20.9335C21.4904 20.7728 21.5806 20.5549 21.5806 20.3276V17.6625C22.2747 17.4764 22.8881 17.0665 23.3255 16.4963C23.7629 15.9261 24 15.2276 24 14.5089C24 13.7903 23.7629 13.0918 23.3255 12.5216C22.8881 11.9514 22.2747 11.5415 21.5806 11.3554V2.8802C21.5806 2.65292 21.4904 2.43496 21.3296 2.27425C21.1689 2.11354 20.951 2.02325 20.7237 2.02325C20.4964 2.02325 20.2784 2.11354 20.1177 2.27425C19.957 2.43496 19.8667 2.65292 19.8667 2.8802V11.364C19.1726 11.5501 18.5593 11.96 18.1219 12.5302C17.6845 13.1003 17.4474 13.7989 17.4474 14.5175C17.4474 15.2361 17.6845 15.9347 18.1219 16.5049C18.5593 17.075 19.1726 17.4849 19.8667 17.6711V20.3362C19.869 20.562 19.9603 20.7777 20.1208 20.9366C20.2812 21.0954 20.4979 21.1846 20.7237 21.1845ZM19.1555 14.5089C19.1555 14.1988 19.2475 13.8956 19.4198 13.6377C19.5921 13.3798 19.837 13.1788 20.1236 13.0601C20.4101 12.9414 20.7254 12.9104 21.0296 12.9709C21.3338 13.0314 21.6133 13.1807 21.8326 13.4C22.0519 13.6194 22.2013 13.8988 22.2618 14.203C22.3223 14.5072 22.2912 14.8225 22.1725 15.1091C22.0538 15.3956 21.8528 15.6405 21.5949 15.8129C21.3371 15.9852 21.0339 16.0771 20.7237 16.0771C20.3078 16.0771 19.9089 15.9119 19.6148 15.6178C19.3207 15.3237 19.1555 14.9249 19.1555 14.5089Z" />
      <path d="M12 21.1845C12.2273 21.1845 12.4452 21.0943 12.606 20.9336C12.7667 20.7728 12.8569 20.5549 12.8569 20.3276V8.45035C13.6249 8.24262 14.2913 7.763 14.7322 7.1007C15.173 6.43841 15.3583 5.63853 15.2535 4.84986C15.1487 4.06119 14.7611 3.33743 14.1626 2.81321C13.5641 2.28899 12.7956 2 12 2C11.2044 2 10.4359 2.28899 9.83742 2.81321C9.23895 3.33743 8.85127 4.06119 8.74649 4.84986C8.64171 5.63853 8.82698 6.43841 9.26782 7.1007C9.70865 7.763 10.3751 8.24262 11.1431 8.45035V20.3276C11.1431 20.5549 11.2333 20.7728 11.3941 20.9336C11.5548 21.0943 11.7727 21.1845 12 21.1845ZM10.4318 5.30536C10.4301 4.99484 10.5206 4.69081 10.6919 4.43179C10.8632 4.17277 11.1075 3.97044 11.3939 3.85043C11.6803 3.73042 11.9959 3.69814 12.3007 3.75768C12.6054 3.81722 12.8857 3.9659 13.1058 4.18487C13.326 4.40384 13.4762 4.68325 13.5374 4.98769C13.5986 5.29212 13.5681 5.60787 13.4496 5.89492C13.3312 6.18198 13.1302 6.42741 12.8721 6.6001C12.6141 6.7728 12.3105 6.865 12 6.865C11.5856 6.86501 11.188 6.70096 10.8941 6.40872C10.6003 6.11647 10.4341 5.71979 10.4318 5.30536Z" />
      <path d="M2.41936 14.7575V20.3276C2.41936 20.5549 2.50964 20.7728 2.67035 20.9335C2.83106 21.0943 3.04903 21.1845 3.2763 21.1845C3.50358 21.1845 3.72155 21.0943 3.88225 20.9335C4.04296 20.7728 4.13325 20.5549 4.13325 20.3276V14.7575C4.82736 14.5713 5.44067 14.1614 5.87809 13.5912C6.31551 13.0211 6.55261 12.3225 6.55261 11.6039C6.55261 10.8853 6.31551 10.1867 5.87809 9.61655C5.44067 9.04638 4.82736 8.63646 4.13325 8.45034V2.8802C4.13325 2.65292 4.04296 2.43496 3.88225 2.27425C3.72155 2.11354 3.50358 2.02325 3.2763 2.02325C3.04903 2.02325 2.83106 2.11354 2.67035 2.27425C2.50964 2.43496 2.41936 2.65292 2.41936 2.8802V8.45034C1.72525 8.63646 1.11194 9.04638 0.674514 9.61655C0.237092 10.1867 0 10.8853 0 11.6039C0 12.3225 0.237092 13.0211 0.674514 13.5912C1.11194 14.1614 1.72525 14.5713 2.41936 14.7575ZM3.2763 10.0357C3.58683 10.034 3.89086 10.1245 4.14988 10.2958C4.40889 10.4671 4.61123 10.7114 4.73124 10.9978C4.85124 11.2842 4.88353 11.5998 4.82399 11.9046C4.76445 12.2093 4.61577 12.4895 4.3968 12.7097C4.17782 12.9299 3.89842 13.0801 3.59398 13.1413C3.28955 13.2025 2.97379 13.172 2.68674 13.0535C2.39969 12.9351 2.15426 12.7341 1.98156 12.476C1.80886 12.218 1.71667 11.9144 1.71666 11.6039C1.71666 11.1895 1.8807 10.7919 2.17295 10.498C2.46519 10.2042 2.86188 10.038 3.2763 10.0357Z" />
    </svg>
  ),
  menu: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 3H9C8.60218 3 8.22064 3.15804 7.93934 3.43934C7.65804 3.72064 7.5 4.10218 7.5 4.5C7.5 4.89782 7.65804 5.27936 7.93934 5.56066C8.22064 5.84196 8.60218 6 9 6H21C21.3978 6 21.7794 5.84196 22.0607 5.56066C22.342 5.27936 22.5 4.89782 22.5 4.5C22.5 4.10218 22.342 3.72064 22.0607 3.43934C21.7794 3.15804 21.3978 3 21 3Z" />
      <path d="M3 6C3.82843 6 4.5 5.32843 4.5 4.5C4.5 3.67157 3.82843 3 3 3C2.17157 3 1.5 3.67157 1.5 4.5C1.5 5.32843 2.17157 6 3 6Z" />
      <path d="M21 10.5H9C8.60218 10.5 8.22064 10.658 7.93934 10.9393C7.65804 11.2206 7.5 11.6022 7.5 12C7.5 12.3978 7.65804 12.7794 7.93934 13.0607C8.22064 13.342 8.60218 13.5 9 13.5H21C21.3978 13.5 21.7794 13.342 22.0607 13.0607C22.342 12.7794 22.5 12.3978 22.5 12C22.5 11.6022 22.342 11.2206 22.0607 10.9393C21.7794 10.658 21.3978 10.5 21 10.5Z" />
      <path d="M3 13.5C3.82843 13.5 4.5 12.8284 4.5 12C4.5 11.1716 3.82843 10.5 3 10.5C2.17157 10.5 1.5 11.1716 1.5 12C1.5 12.8284 2.17157 13.5 3 13.5Z" />
      <path d="M21 18H9C8.60218 18 8.22064 18.158 7.93934 18.4393C7.65804 18.7206 7.5 19.1022 7.5 19.5C7.5 19.8978 7.65804 20.2794 7.93934 20.5607C8.22064 20.842 8.60218 21 9 21H21C21.3978 21 21.7794 20.842 22.0607 20.5607C22.342 20.2794 22.5 19.8978 22.5 19.5C22.5 19.1022 22.342 18.7206 22.0607 18.4393C21.7794 18.158 21.3978 18 21 18Z" />
      <path d="M3 21C3.82843 21 4.5 20.3284 4.5 19.5C4.5 18.6716 3.82843 18 3 18C2.17157 18 1.5 18.6716 1.5 19.5C1.5 20.3284 2.17157 21 3 21Z" />
    </svg>
  ),
  rate: (
    <svg viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 6.5V11H3V6.5H1Z" fill="#A6ACBE" />
      <path d="M9 4V11H11V4H9Z" fill="#A6ACBE" />
      <path d="M5 2V11H7V2H5Z" fill="#A6ACBE" />
    </svg>
  ),
  map: (
    <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.6429 0.5C23.5098 0.49995 23.3784 0.530887 23.2594 0.5904L16.764 3.83894L9.41931 0.574128C9.4073 0.569006 9.39359 0.568102 9.38073 0.562979C9.34769 0.550624 9.31389 0.540329 9.27959 0.532142C9.24549 0.522952 9.21083 0.51577 9.17588 0.510697C9.10627 0.504972 9.03631 0.504972 8.96675 0.510697C8.9318 0.51577 8.89714 0.522952 8.86304 0.532142C8.82874 0.540329 8.79494 0.550624 8.76189 0.562979C8.74904 0.568102 8.73533 0.568955 8.72332 0.574128L1.00916 4.00266C0.699484 4.14017 0.49995 4.44723 0.5 4.78603V23.6429C0.500151 23.9329 0.64685 24.2031 0.889977 24.3612C1.13315 24.5195 1.43986 24.544 1.70509 24.4263L9.07126 21.1521L16.4374 24.4263C16.4503 24.4314 16.464 24.4263 16.4769 24.4341C16.6844 24.5269 16.9226 24.5215 17.1257 24.4195C17.1394 24.4135 17.1548 24.4195 17.1685 24.4101L24.0255 20.9815C24.3161 20.8363 24.4997 20.5393 24.4995 20.2144V1.35755C24.4998 0.884152 24.1163 0.500201 23.6429 0.5ZM8.21417 19.6573L2.21424 22.3238V5.3432L8.21417 2.67664V19.6573ZM15.9283 22.3238L9.92841 19.6573V2.67664L15.9283 5.34315V22.3238ZM22.7854 19.6847L17.6426 22.2561V5.31573L22.7854 2.74434V19.6847Z" />
    </svg>
  ),
  analytics: (
    <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 12.5V21.5H6V12.5H2Z" />
      <path d="M18 7.5V21.5H22V7.5H18Z" />
      <path d="M10 3.5V21.5H14V3.5H10Z" />
    </svg>
  ),
  logout: (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 17L21 12L16 7"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 12H9"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  notifications: (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.73 21C13.5542 21.3031 13.3018 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  info: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5 7.99985C15.5 12.1415 12.1417 15.4999 8 15.4999C3.85784 15.4999 0.500001 12.1418 0.500002 7.99985C0.500002 3.85794 3.85784 0.499854 8 0.499854C12.1417 0.499855 15.5 3.8582 15.5 7.99985ZM1.625 7.99985C1.625 11.5205 4.47918 14.3749 8 14.3749C11.5203 14.3749 14.375 11.5202 14.375 7.99985C14.375 4.47952 11.5203 1.62486 8 1.62486C4.47918 1.62486 1.625 4.47925 1.625 7.99985Z"
        fill="#A6ACBE"
      />
      <path
        d="M8.00391 11.4093C7.71913 11.4093 7.48379 11.1977 7.44654 10.9231L7.44141 10.8468L7.44141 7.53253C7.44141 7.22187 7.69325 6.97003 8.00391 6.97003C8.28868 6.97003 8.52402 7.18165 8.56127 7.4562L8.56641 7.53253L8.56641 10.8468C8.56641 11.1574 8.31457 11.4093 8.00391 11.4093Z"
        fill="#A6ACBE"
      />
      <path
        d="M7.99641 5.71543C7.68575 5.71543 7.43391 5.46359 7.43391 5.15293C7.43391 4.86816 7.64552 4.63281 7.92008 4.59557L8.00391 4.59043C8.31457 4.59043 8.56641 4.84227 8.56641 5.15293C8.56641 5.4377 8.35479 5.67305 8.08023 5.7103L7.99641 5.71543Z"
        fill="#A6ACBE"
      />
    </svg>
  ),
  telegram: (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M4.70859 7.59049L4.51009 10.3825C4.79409 10.3825 4.91709 10.2605 5.06459 10.114L6.39609 8.84149L9.15509 10.862C9.66109 11.144 10.0176 10.9955 10.1541 10.3965L11.9651 1.91049L11.9656 1.90999C12.1261 1.16199 11.6951 0.869489 11.2021 1.05299L0.557089 5.12849C-0.169411 5.41049 -0.158411 5.81549 0.433589 5.99899L3.15509 6.84549L9.47659 2.88999C9.77409 2.69299 10.0446 2.80199 9.82209 2.99899L4.70859 7.59049Z"
          fill="#A6ACBE"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  viber: (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M11.5775 6.94651C11.9355 3.93301 11.4055 2.03051 10.4495 1.17001L10.45 1.16951C8.90704 -0.299988 3.69604 -0.517488 1.85004 1.23551C1.02104 2.09301 0.72904 3.35151 0.69704 4.90951C0.66504 6.46801 0.62704 9.38751 3.34754 10.1795H3.35004L3.34754 11.389C3.34754 11.389 3.32904 11.879 3.64204 11.9775C4.00004 12.0935 4.16204 11.866 5.27554 10.536C7.13754 10.6975 8.56754 10.3275 8.73004 10.2735C9.10604 10.1475 11.2335 9.86601 11.5775 6.94651ZM5.45904 9.68501C5.45904 9.68501 4.28054 11.1545 3.91404 11.536C3.79404 11.66 3.66254 11.6485 3.66454 11.4025C3.66454 11.241 3.67354 9.39451 3.67354 9.39451C1.36704 8.73351 1.50304 6.24751 1.52804 4.94701C1.55304 3.64601 1.79104 2.58051 2.49304 1.86301C4.11254 0.344512 8.68104 0.684012 9.84504 1.77801C11.268 3.03951 10.7615 6.60351 10.7645 6.72501C10.472 9.16201 8.74804 9.31651 8.43104 9.42201C8.29554 9.46701 7.03804 9.79051 5.45904 9.68501Z"
          fill="#A6ACBE"
        />
        <path
          d="M6.11068 2.14851C5.91818 2.14851 5.91818 2.44851 6.11068 2.45101C7.60418 2.46251 8.83418 3.50351 8.84768 5.41301C8.84768 5.61451 9.14268 5.61201 9.14018 5.41051H9.13968C9.12368 3.35301 7.78068 2.16001 6.11068 2.14851Z"
          fill="#A6ACBE"
        />
        <path
          d="M8.07548 5.09652C8.07098 5.29552 8.36548 5.30502 8.36798 5.10352C8.39248 3.96902 7.69298 3.03452 6.37847 2.93602C6.18597 2.92202 6.16597 3.22452 6.35797 3.23852C7.49798 3.32502 8.09848 4.10302 8.07548 5.09652Z"
          fill="#A6ACBE"
        />
        <path
          d="M7.7606 6.387C7.5136 6.244 7.2621 6.333 7.1581 6.4735L6.9406 6.755C6.8301 6.898 6.6236 6.879 6.6236 6.879C5.1166 6.4805 4.7136 4.9035 4.7136 4.9035C4.7136 4.9035 4.6951 4.69 4.8331 4.5755L5.1051 4.3505C5.2411 4.2425 5.3271 3.9825 5.1886 3.727C4.8186 3.0585 4.5701 2.828 4.4436 2.651C4.3106 2.4845 4.11061 2.447 3.90261 2.5595H3.89811C3.46561 2.8125 2.99211 3.286 3.14361 3.7735C3.40211 4.2875 3.87711 5.926 5.3911 7.164C6.1026 7.7495 7.2286 8.3495 7.7066 8.488L7.7111 8.495C8.1821 8.652 8.6401 8.16 8.8846 7.7145V7.711C8.9931 7.4955 8.9571 7.2915 8.7986 7.158C8.5176 6.884 8.0936 6.5815 7.7606 6.387Z"
          fill="#A6ACBE"
        />
        <path
          d="M6.58418 4.052C7.06469 4.08 7.29769 4.33101 7.32269 4.84651C7.33169 5.04801 7.62419 5.03401 7.61519 4.83251C7.58319 4.1595 7.23219 3.7845 6.60018 3.7495C6.40768 3.738 6.38968 4.0405 6.58418 4.052Z"
          fill="#A6ACBE"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  whatsapp: (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 496 496"
    >
      <path
        fill="#A6ACBE"
        d="M321.824,276.528c-0.88-1.424-3.168-2.304-6.608-4.016c-3.456-1.68-20.48-10-23.632-11.152    c-1.376-0.496-2.592-0.896-3.712-0.896c-1.472,0-2.784,0.672-4.08,2.608c-2.304,3.44-8.944,11.152-10.944,13.456    c-1.136,1.312-2.304,1.968-3.712,1.968c-1.056,0-2.288-0.368-3.76-1.12c-3.488-1.68-14.624-5.328-27.824-16.992    c-10.272-9.104-17.216-20.336-19.248-23.776c-2.016-3.408-0.208-5.28,1.536-6.976c1.552-1.584,3.44-4.032,5.2-6.032    c1.728-2,2.304-3.424,3.44-5.712c1.136-2.288,0.576-4.304-0.304-6.016c-0.848-1.712-7.76-18.592-10.672-25.472    c-2.144-5.12-4.304-5.776-6.096-5.776c-0.608,0-1.184,0.08-1.696,0.08c-1.984,0-4.272-0.304-6.608-0.304    c-2.304,0-6.032,0.864-9.216,4.32c-3.152,3.408-12.112,11.712-12.112,28.592c0,16.896,12.384,33.184,14.144,35.472    c1.712,2.304,23.92,38.064,59.056,51.808c20.368,7.968,28.944,9.776,33.776,9.776c3.52,0,5.056-0.96,7.728-1.2    c6.32-0.56,20.448-8.32,23.344-16.32C322.704,284.848,322.704,277.952,321.824,276.528z"
      />
      <path
        fill="#A6ACBE"
        d="M362.944,0H133.056C60.928,0,0,60.944,0,133.056v229.872C0,435.056,60.928,496,133.056,496h229.888     C435.056,496,496,435.056,496,362.912V133.056C496,60.944,435.072,0,362.944,0z M464,362.912     c0,54.784-46.272,101.072-101.056,101.072H133.056C78.288,463.984,32,417.712,32,362.912V133.056C32,78.288,78.288,32,133.056,32     h229.888C417.712,32,464,78.272,464,133.056V362.912z"
      />
      <path
        fill="#A6ACBE"
        d="M250.608,88.32c-86.592,0-157.04,69.968-157.04,155.984c0,25.936,6.448,51.36,18.736,73.968l-23.12,68.256     c-1.936,5.712-0.496,12.016,3.728,16.336c3.056,3.136,7.2,4.8,11.424,4.8c1.616,0,3.248-0.24,4.848-0.752l71.76-22.784     c21.52,10.56,45.456,16.128,69.664,16.128c86.608,0,157.072-69.952,157.072-155.952C407.68,158.288,337.216,88.32,250.608,88.32z      M250.576,368.272c-21.184,0-42.096-5.328-60.464-15.424c-2.368-1.296-5.024-1.968-7.696-1.968c-1.616,0-3.248,0.24-4.864,0.752     L129.6,366.848l15.296-45.12c1.52-4.48,0.976-9.424-1.488-13.472c-11.696-19.2-17.872-41.312-17.872-63.936     c0-68.352,56.096-123.984,125.04-123.984c68.96,0,125.072,55.632,125.072,123.984     C375.648,312.656,319.552,368.272,250.576,368.272z"
      />
    </svg>
  ),
}
