import { observable, action, makeAutoObservable, computed } from 'mobx'
import { setToken, eraseToken } from '../axios'
import { Auth as API, Users } from '../API'
import NotificationsStore from './NotificationsStore'

const A_ROLE = 'admin'
const RC_ROLE = 'regionCoordinator'
const C_ROLE = 'coordinator'

class AuthStore {
  @observable currentUser = null
  @observable signInForm = {
    login: '',
    password: '',

    errorLogin: '',
    errorPassword: '',
  }
  @observable isFetch = false

  constructor() {
    makeAutoObservable(this)
    this.relogUser()
  }

  @action('sign in')
  signIn = async (email, password) => {
    this.isFetch = true
    try {
      const res = await API.login(email, password)
      setToken('access_token', res.jwt)
      localStorage.setItem('assignment__user', JSON.stringify(res.user))
      this.currentUser = res.user
      NotificationsStore.loadNotifications()
      this.isFetch = false
    } catch (error) {
      this.isFetch = false
      return Promise.reject(error.message)
    }
  }
  @action('Forget password')
  forgetPassword = async (email) => {
    this.isFetch = true
    try {
      await API.forgetPassword(email)
      this.isFetch = false
      return Promise.resolve(true)
    } catch (error) {
      this.isFetch = false
      return Promise.reject(error.message)
    }
  }
  @action('Reset Password')
  resetPassword = async ({ password, code }) => {
    this.isFetch = true
    try {
      await API.updateUserPassword(password, code)
      this.isFetch = false
      return Promise.resolve(true)
    } catch (error) {
      this.isFetch = false
      return Promise.reject(false)
    }
  }
  @observable registerError = null
  @action('register user')
  signUp = async (password, code) => {
    this.registerError = null
    this.isFetch = true
    try {
      let res = await API.updateUserPassword(password, code)
      setToken('access_token', res.jwt)
      localStorage.setItem('assignment__user', JSON.stringify(res.user))
      this.currentUser = res.user
      await Users.updateUser(res.user.id, { confirmed: true })
      this.isFetch = false
    } catch (error) {
      this.isFetch = false
      this.registerError = error.data[0].message
    }
  }

  // Clear form errors
  clearErrors = (form) => {
    if (form === 'signIn') {
      this.signInForm.errorLogin = ''
      this.signInForm.errorPassword = ''
    }
  }
  @action('sign out')
  signOut = () => {
    eraseToken()
    this.currentUser = null
    localStorage.removeItem('assignment__user')
  }

  @action('Relog user')
  relogUser = async () => {
    if (['/signUp', '/signIn'].includes(window.location.pathname)) {
      this.signOut()
      return
    }
    let user = localStorage.getItem('assignment__user')
    if (user) {
      this.currentUser = JSON.parse(user)
      NotificationsStore.loadNotifications()
    }
  }
  @computed getPermission(type, data) {
    switch (type) {
      case 'users':
        return [A_ROLE, RC_ROLE].includes(this.getUserRole())
      case 'user-edit':
        return (
          (this.getUserRole() === A_ROLE &&
            [RC_ROLE, C_ROLE].includes(data.role.name)) ||
          (this.getUserRole() === RC_ROLE && [C_ROLE].includes(data.role.name))
        )
      case 'user-delete':
        return (
          (this.getUserRole() === A_ROLE &&
            [RC_ROLE, C_ROLE].includes(data.role.name)) ||
          (this.getUserRole() === RC_ROLE && [C_ROLE].includes(data.role.name))
        )
      case 'responsible-assistant-edit':
        return this.getUserRole() === A_ROLE
      case 'responsible-coordinator-edit':
        return [A_ROLE, RC_ROLE].includes(this.getUserRole())
      case 'exception-edit':
        return [A_ROLE, RC_ROLE].includes(this.getUserRole())

      case 'navigation-links':
        return this.getUserRole() === 'admin'

      default:
        return false
    }
  }
  @computed getUserRole() {
    return this.currentUser ? this.currentUser.role.name : null
  }
}

const authStore = new AuthStore()

export default authStore
