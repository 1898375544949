import React from 'react'
import CardRow from './CardRow'

function InfoTable({ list }) {
  const renderList = () => {
    let result = []
    for (const key in list) {
      if (Object.hasOwnProperty.call(list, key)) {
        result.push(<CardRow key={key} label={key} value={list[key]} />)
      }
    }
    return result
  }
  return <div className="info">{renderList()}</div>
}

export default InfoTable
