import Tabs from '../components/analytics/Tabs'

function Analytics() {
  return (
    <div className="page-content analytics">
      <Tabs />
    </div>
  )
}

export default Analytics
