import React from 'react'
import { formatDateDot } from '../../../util'
import { formatDistanceToNowStrict } from 'date-fns'
function HistoryTable({ history = [] }) {
  const [tab, setTab] = React.useState(1)
  const getItems = () => {
    history = history.sort(
      (a, b) =>
        new Date(b.finishDate).getTime() - new Date(a.finishDate).getTime()
    )
    switch (tab) {
      case 1:
        return history.filter(
          (v) =>
            formatDistanceToNowStrict(new Date(v.finishDate)).search('days') !==
            -1
        )
      case 2:
        return history.filter((v) =>
          ['1 month', '2 months'].includes(
            formatDistanceToNowStrict(new Date(v.finishDate))
          )
        )
      case 3:
        return history.filter((v) =>
          ['3 months', '4 months', '5 months', '6 months'].includes(
            formatDistanceToNowStrict(new Date(v.finishDate))
          )
        )
      default:
        return
    }
  }
  return (
    <React.Fragment>
      <div className="history-table-header">
        <div
          className={`history-table-header__item ${tab === 1 ? 'active' : ''}`}
          onClick={() => setTab(1)}
        >
          1 мес
        </div>
        <div
          className={`history-table-header__item ${tab === 2 ? 'active' : ''}`}
          onClick={() => setTab(2)}
        >
          2 мес
        </div>
        <div
          className={`history-table-header__item ${tab === 3 ? 'active' : ''}`}
          onClick={() => setTab(3)}
        >
          6 мес
        </div>
      </div>
      <div className="history-table-body">
        <div className="history-table__inner">
          {getItems()?.length > 0 ? (
            getItems().map((item, i) => {
              return (
                <div
                  className="history-table__item"
                  style={{
                    color: item.status === 'active' ? '#48D53B' : 'inherit',
                  }}
                  key={i}
                >
                  <div className="caption-text">{item.client}</div>
                  <div className="ml-auto">
                    <b>{formatDateDot(item.finishDate)}</b>
                  </div>
                </div>
              )
            })
          ) : (
            <div className="history-table__no-item">Отсутствует</div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default HistoryTable
