import { format, formatDistance, formatDistanceToNowStrict } from 'date-fns'
import { ru } from 'date-fns/locale'
import { useEffect } from 'react'

export const formatDate = (date) => {
  return format(new Date(date), 'yyyy-MM-dd')
}
export const formatDateDot = (date) => {
  return format(new Date(date), 'dd.MM.yyyy')
}
export const formatTime = (date) => {
  return format(new Date(date), 'HH:mm')
}
export const formatDateTime = (date) => {
  return format(new Date(date), 'yyyy-MM-dd HH:mm')
}
export const formatTimeDay = (date) => {
  return format(new Date(date), 'HH:mm EEEE', { locale: ru })
}
export const formatTimeAfter = (term) => {
  return formatDistance(new Date(new Date().getTime() + term), new Date(), {
    locale: ru,
  })
}
export const getAge = (birthDate) => {
  return formatDistanceToNowStrict(new Date(birthDate), {
    unit: 'year',
    locale: ru,
  })
}
export const getMonthYear = (date) => {
  return format(new Date(date), 'MMMM yyyy', { locale: ru })
}

export const formatPhone = (phone) => {
  // Split phone to array and remove all non number elements
  let p_arr = String(phone)
    .split('')
    .filter(
      (num) =>
        ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(num) !== -1
    )
  // Add 0 to phone if length is small
  p_arr =
    p_arr.length < 10
      ? new Array(10 - p_arr.length).fill(0).concat(p_arr)
      : p_arr
  // Format Phone
  const number_1 = p_arr.splice(p_arr.length - 2, 2).join('')
  const number_2 = p_arr.splice(p_arr.length - 2, 2).join('')
  const number_3 = p_arr.splice(p_arr.length - 3, 3).join('')
  const number_4 = p_arr.splice(p_arr.length - 3, 3).join('')
  let p = `38 (${number_4}) ${number_3} ${number_2} ${number_1}`
  return {
    formatedPhone: p,
    callPhone: [...number_4, ...number_3, ...number_2, ...number_1].join(''),
  }
}

export const useOutClick = (elRef, isActive, setActive, selector) => {
  const handleOutsideClick = (e) => {
    const elem = e.target.closest(selector)
    if (!elem || elRef.current !== elem) {
      setActive(false)
    }
  }
  useEffect(() => {
    if (isActive) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  })
}
