import React, { useState, useEffect } from 'react'
import CheckBox from '../common/CheckBox'
import CloseBtn from './common/CloseBtn'
import { MapStore, SelectionStore } from '../../store'
import CardRow from './common/CardRow'
import CardBottom from './common/CardBottom'
import { getAge, formatPhone, formatDateDot, formatTime } from '../../util'
import ManAvatar from '../../assets/profileMan.svg'
import WomanAvatar from '../../assets/profileWoman.svg'

function UserCard(props) {
  const { data: user, onChange } = props
  let { formatedPhone, callPhone } = user?.phone
    ? formatPhone(user.phone)
    : { formatedPhone: null, callPhone: null }
  const historyActions = (
    <button
      onClick={() => {
        MapStore.clearActiveMarker()
        SelectionStore.startUserSelection(user)
      }}
      className="btn btn-primary more"
    >
      Оформить визит
    </button>
  )
  const onAttachedVisits = () => {
    MapStore.showAttachedVisits = !MapStore.showAttachedVisits
  }

  return (
    <div
      className={`marker-wrapper full-card ${
        props.show ? `show ${props.position}` : ''
      }`}
    >
      <div className="marker-card full">
        {props.show && (
          <React.Fragment>
            <CloseBtn click={MapStore.clearActiveMarker} />
            <div className="marker-card__updated">
              {formatDateDot(user.updatedAt)}
              <br />
              {formatTime(user.updatedAt)}
            </div>
            <div className="marker-card__avatar">
              <img
                src={user.gender === 'M' ? ManAvatar : WomanAvatar}
                alt="avatar"
              />
            </div>

            <div className="marker-card__fullname">
              {user.firstName} {user.lastName}
            </div>
            <div className="marker-card__number">
              <a href={user.phone ? `tel:${callPhone}` : null}>
                {user.phone ? formatedPhone : '---'}
              </a>
            </div>

            <hr className="line" />
            <div className="block-custom-scroll">
              <CardRow label="Логин" value={user.login} />
              <CardRow label="E-mail" value={user.email} />
              <CardRow label="Возраст" value={getAge(user.birthDate)} />
              <CardRow
                label="Авто"
                value={user.auto && `${user.auto} (${user.autoReleaseYear})`}
              />

              <hr className="line" />

              <CardRow
                label="Адрес"
                value={`${user.city} ${user.address}`}
                isTwoLines
              />

              <CardRow
                label="Место работы"
                value={user.occupation}
                isTwoLines
              />

              <hr className="line" />

              <div className="card-row rating">
                <div className="text-label">Посещений</div>
                <div className="ml-auto">
                  <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 6.5V11H3V6.5H1Z" fill="#A6ACBE" />
                    <path d="M9 4V11H11V4H9Z" fill="#A6ACBE" />
                    <path d="M5 2V11H7V2H5Z" fill="#A6ACBE" />
                  </svg>
                  <b>{user?.visitsCount}</b>
                </div>
              </div>

              <div className="card-row">
                <CheckBox
                  value={user.isAuto}
                  placeholder="Выездной"
                  handle={(v) => onChange('isAuto', v)}
                />
              </div>
              <div className="card-row">
                <CheckBox
                  value={user.isBadShopper}
                  placeholder="Bad Shopper"
                  handle={(v) => onChange('isBadShopper', v)}
                />
              </div>

              <CardBottom
                infoListData={{
                  'Назначенные визиты': (
                    <span className="active" onClick={onAttachedVisits}>
                      {user.attachedVisits.length}
                    </span>
                  ),
                  Регистрация: user.registrationDate
                    ? formatDateDot(user.registrationDate)
                    : '----',
                  'Последний логин': user.lastLoginDate
                    ? formatDateDot(user.lastLoginDate)
                    : '----',
                  Комментарий: (
                    <CommentInput
                      value={user.comment}
                      onChange={(v) => onChange('comment', v)}
                    />
                  ),
                }}
                history={user.historyVisits}
              />
            </div>
            {historyActions}
            <div style={{ marginTop: 'auto' }}></div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default UserCard

const CommentInput = ({ value, onChange }) => {
  const [v, setv] = useState(value)
  const [searchTimeout, setSearchTimeout] = useState(null)
  useEffect(() => {
    setv(value)
  }, [value])
  const handle = (val) => {
    setv(val)
    if (searchTimeout) {
      clearSearchtimeout()
    }
    setSearchTimeout(
      setTimeout(() => {
        onChange(val)
        clearSearchtimeout()
      }, 800)
    )
  }
  const clearSearchtimeout = () => {
    clearTimeout(searchTimeout)
    setSearchTimeout(null)
  }
  return (
    <div className="user-comment-input">
      <textarea
        rows="4"
        value={v}
        onChange={(e) => handle(e.target.value)}
        placeholder="Коментарий"
      ></textarea>
    </div>
  )
}
