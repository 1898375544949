import { observable, action, makeAutoObservable } from 'mobx'
import { MapStore } from '../../'
const LOC_TYPES = ['Мои', 'Все']
class FilterStore {
  @observable showFilter = false
  @observable showShoperList = false
  @observable requestType = [
    'Заявка',
    'Не назначено',
    // 'В процессе',
    'Отклонено',
  ]
  @observable statuses = [
    'Выполнено',
    'В процессе',
    'Заявка',
    'Не назначено',
    'Отклонено',
  ]
  @observable search = ''

  @observable locations = LOC_TYPES
  @observable location = LOC_TYPES[0]
  @observable shopList = []
  @observable shopListIds = []

  constructor() {
    makeAutoObservable(this)
  }

  @action('show|hide filter')
  toggleFilter = () => {
    this.showShoperList = false
    this.showFilter = !this.showFilter
  }
  @action('show|hide list')
  toggleList = () => {
    this.showFilter = false
    this.showShoperList = !this.showShoperList
  }

  @action('close filter')
  close = () => {
    this.showShoperList = false
    this.showFilter = false
  }
  @action('set request type in filter')
  setRequestType = (type) => {
    this.requestType = type
    MapStore.onViewportUpdate()
  }
  @action('set location in filter')
  setLocation = (loc) => {
    this.location = loc
    MapStore.onViewportUpdate()
  }
  @action('set search input')
  setSearch = (input) => {
    this.search = input
    MapStore.onViewportUpdate()
  }
  @action('add to shop list')
  addShop = (shop) => {
    this.shopList.push(shop)
    this.shopListIds.push(shop.id)
  }
  @action('remove from shop list')
  removeShop = (shopId) => {
    let index = this.shopListIds.indexOf(shopId)
    this.shopList.splice(index, 1)
    this.shopListIds.splice(index, 1)
  }
}

const filterStore = new FilterStore()

export default filterStore
