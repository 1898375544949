import React, { useState, useRef } from 'react'
import { useOutClick } from '../../../util'

function Select({
  value = '',
  onChange,
  name,
  placeholder,
  options = [],
  getOptionLabel,
  getOptionSubLabel,
  read,
  type,
  isNull,
  nullLabel,
  error,
  textClass,
}) {
  const [isActive, setActive] = useState(false)
  const selectRef = useRef(null)
  useOutClick(selectRef, isActive, setActive, '.select-wrapper')
  const openSelect = () => {
    if (read) return
    setActive(!isActive)
  }

  const TYPES = {
    underline: 'underline',
    outline: 'outline',
  }

  const showVariants = () => {
    const handleOption = (variant) => {
      if (onChange) onChange(variant)
      setActive(false)
    }
    return (
      <div
        onMouseDown={(e) => {
          e.stopPropagation()
        }}
        className="select-list"
      >
        {isNull && (
          <div onClick={() => handleOption(null)} className="select-list__item">
            <div className={textClass}>{nullLabel}</div>
          </div>
        )}
        {options &&
          getOptionLabel &&
          options.map((option, i) => (
            <div
              key={i}
              onClick={() => handleOption(option)}
              className="select-list__item"
            >
              <div className={textClass}>{getOptionLabel(option)}</div>
              {getOptionSubLabel && (
                <div className={`${textClass} sub-option`}>
                  {getOptionSubLabel(option)}
                </div>
              )}
            </div>
          ))}
      </div>
    )
  }
  let arrowStyle = {
    transform: `rotate(${isActive ? '180deg' : '0'})`,
  }
  return (
    <>
      <div
        ref={selectRef}
        className={`select-wrapper ${error ? 'error' : ''} ${
          read ? 'read' : ''
        } ${isActive ? 'active' : ''} ${TYPES[type] || ''}`}
      >
        <div className="select__inner">
          <div className="select" onClick={openSelect}>
            <input
              readOnly
              value={value}
              placeholder={placeholder}
              name={name}
              type="text"
            />
            {!read && (
              <svg
                style={{ ...arrowStyle, width: '1.25rem' }}
                viewBox="0 0 10 6"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4.88621 5.16663L0.53887 0.166626L9.23355 0.166627L4.88621 5.16663Z" />
              </svg>
            )}
          </div>
          {isActive && showVariants()}
        </div>
      </div>
      <div className={`${textClass} sub-option`}>{error}</div>
    </>
  )
}

export default Select
