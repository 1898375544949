import { observable, action, makeAutoObservable } from 'mobx'
import { Analytic as API } from '../API'
import AuthStore from './AuthStore'

class AnalyticsStore {
  constructor() {
    makeAutoObservable(this)
  }
  @observable isFetch = false
  @observable chartsTabData = null

  @action('get Charts tab information')
  getChartsTabData = async (i) => {
    if (i === undefined) {
      this.chartsTabData = {}
      let globalRes = await API.getAnalytics({ type: 'chart' })
      this.chartsTabData.global = globalRes
      let regionsRes = await API.getAnalytics({ type: 'list' })
      this.chartsTabData.regionCoordinators = regionsRes
    } else {
      let regionRes = await API.getAnalytics({
        type: 'list',
        asisstanceId: this.chartsTabData.regionCoordinators[i].coordinator.id,
      })
      let t = this.chartsTabData
      t.regionCoordinators[i].coordinators = regionRes
      this.chartsTabData = { ...t }
    }
  }

  @observable tableTabData = null
  @observable tableMode = 'region'
  @observable showResponsiblesDialog = false
  @observable responsibleDialogArgs = null

  @action('get Table tab information')
  // { region, project, city }
  getTableTabData = async (child_1, child_2, child_3) => {
    let region, project, city
    let tableType = this.tableMode === 'region' ? 'regions' : 'clients'
    if (child_1 !== undefined && this.tableMode === 'region') {
      region = this.tableTabData[child_1].region.code
      tableType = 'region-clients'
    }
    if (child_1 !== undefined && this.tableMode === 'project') {
      project = this.tableTabData[child_1].client
      tableType = 'client-regions'
    }
    if (child_2 !== undefined && this.tableMode === 'region') {
      project = this.tableTabData[child_1].children[child_2].client
      tableType = 'cities'
    }
    if (child_2 !== undefined && this.tableMode === 'project') {
      region = this.tableTabData[child_1].children[child_2].region.code
      tableType = 'cities'
    }
    if (child_3 !== undefined) {
      city = this.tableTabData[child_1].children[child_2].children[child_3].city
      tableType = 'city'
    }

    let tableRes = await API.getAnalytics({
      type: 'table',
      table_mode: tableType,
      region,
      project,
      city,
    })

    if (child_1 === undefined) {
      this.tableTabData = tableRes.map((row) => ({
        ...row,
        children: null,
        title: this.tableMode === 'region' ? row.region.name : row.client,
      }))
    } else if (child_2 === undefined) {
      let d = [...this.tableTabData]
      d[child_1].children = tableRes.map((row) => ({
        ...row,
        children: null,
        title: this.tableMode === 'region' ? row.client : row.region.name,
      }))
      this.tableTabData = d
    } else if (child_3 === undefined) {
      let d = [...this.tableTabData]
      d[child_1].children[child_2].children = tableRes.map((row) => ({
        ...row,
        children: null,
        title: row.city,
      }))
      this.tableTabData = d
    } else {
      let d = [...this.tableTabData]
      d[child_1].children[child_2].children[child_3].children = tableRes.map(
        (row) => ({
          ...row,
          title: row.location,
        })
      )
      this.tableTabData = d
    }
  }
  @action('assign new responsibility')
  assignResponsibility = async (user, isRemove) => {
    let args = this.responsibleDialogArgs
    let type = args.length
    let responsibility = {}
    // Format data for permission
    if (this.tableMode === 'region') {
      responsibility.type = 'region'
      responsibility.region = this.tableTabData[args[0]].region.code
      if (type > 2) {
        responsibility.type = 'region_client'
        responsibility.client =
          this.tableTabData[args[0]].children[args[1]].client
      }
    }
    if (this.tableMode !== 'region') {
      responsibility.type = 'client'
      responsibility.client = this.tableTabData[args[0]].client
      if (type > 2) {
        responsibility.type = 'region_client'
        responsibility.client =
          this.tableTabData[args[0]].children[args[1]].region.code
      }
    }
    if (type > 3) {
      responsibility.type = 'city'
      responsibility.city =
        this.tableTabData[args[0]].children[args[1]].children[args[2]].city
      if (type > 4) {
        responsibility.type = 'location'
        responsibility.location =
          this.tableTabData[args[0]].children[args[1]].children[
            args[2]
          ].children[args[3]].locationId
      }
    }
    responsibility.user = user.id
    try {
      this.isFetch = true
      responsibility = await API.createResponsibility(responsibility)
      if (type === 5) {
        for (var i = 0; i < args[4].coordinators.length; i++) {
          let coord = args[4].coordinators[i]
          if (!coord.isInherit)
            await API.removeResponsibility(coord.permissionId)
        }
      }
      await this.getTableTabData(...args.slice(0, -2))
      this.isFetch = false
      let usersProp = args.slice(-1)[0].isRegion ? 'assistans' : 'coordinators'
      user.permissionId = responsibility.id
      switch (responsibility.type) {
        case 'region':
        case 'client':
          args[args.length - 1].coordinators =
            this.tableTabData[args[0]][usersProp]
          break
        case 'region_client':
          args[args.length - 1].coordinators =
            this.tableTabData[args[0]].children[args[1]][usersProp]
          break
        case 'city':
          args[args.length - 1].coordinators =
            this.tableTabData[args[0]].children[args[1]].children[args[2]][
              usersProp
            ]
          break
        case 'location':
          args[args.length - 1].coordinators =
            this.tableTabData[args[0]].children[args[1]].children[
              args[2]
            ].children[args[3]][usersProp]
          break
        default:
          break
      }
      this.tableTabData = [...this.tableTabData]
      this.responsibleDialogArgs = [...args]
    } catch (error) {
      this.isFetch = false
      console.log(error)
    }
  }
  @action('remove responsibility')
  removeResponsibility = async (responsibilityId, index) => {
    try {
      this.isFetch = true
      await API.removeResponsibility(responsibilityId)
      let args = this.responsibleDialogArgs
      await this.getTableTabData(...args.slice(0, -2))
      let coordinators = []
      let usersProp = args.slice(-1)[0].isRegion ? 'assistans' : 'coordinator'
      switch (args.length) {
        case 2:
          coordinators = this.tableTabData[args[0]][usersProp]
          break
        case 3:
          coordinators = this.tableTabData[args[0]].children[args[1]][usersProp]
          break
        case 4:
          coordinators =
            this.tableTabData[args[0]].children[args[1]].children[args[2]][
              usersProp
            ]
          break
        case 5:
          coordinators =
            this.tableTabData[args[0]].children[args[1]].children[args[2]]
              .children[args[3]][usersProp]
          break
        default:
          break
      }
      args[args.length - 1].coordinators = coordinators
      this.isFetch = false
      this.tableTabData = [...this.tableTabData]
      this.responsibleDialogArgs = [...args]
    } catch (error) {
      console.log(error)
    }
  }
  @action('start assign responsibilities')
  startAssignResponsibility(...args) {
    let isRegion = args.slice(-1)[0].isRegion
    let permission = isRegion
      ? 'responsible-assistant-edit'
      : 'responsible-coordinator-edit'
    if (AuthStore.getPermission(permission)) {
      this.responsibleDialogArgs = args
      this.showResponsiblesDialog = true
    }
  }
  @action('change table mode')
  changeTableMode(mode) {
    this.tableMode = mode
    this.tableTabData = null
  }

  @observable exceptionsTabData = null
  @observable exceptionsEdited = {}
  @action('get Table tab information')
  // { region, project, city }
  getExceptionTabData = async () => {
    let res = await API.getAnalytics({ type: 'exception' })
    this.exceptionsTabData = res.map((el) => ({
      ...el,
      title: el.client,
      exception: {
        ...el.exception,
        banedDays: el.exception.banedDays
          ? el.exception.banedDays
              .split(' ')
              .map((d) => new Date(d).toISOString())
          : [],
      },
    }))
  }
  @action('change data in table')
  changeExceptionTableRow = (child_1, key, value) => {
    let t = [...this.exceptionsTabData]
    t[child_1].exception[key] = value
    this.exceptionsTabData = t
    this.exceptionsEdited = {
      ...this.exceptionsEdited,
      [child_1]: true,
    }
  }
  @action('save exceptions table')
  saveExceptionTable = async () => {
    let changedElements = Object.keys(this.exceptionsEdited)
    for (let i = 0; i < changedElements.length; i++) {
      const child_1 = changedElements[i]
      let el = Object.assign({}, this.exceptionsTabData[child_1].exception)
      if (el.banedDays) {
        el.banedDays = el.banedDays
          .map((d) => new Date(d).toISOString())
          .join(' ')
      }
      if (!el.limit) el.limit = null
      if (!el.step) el.step = null
      if (!el.rotationTimeDays || el.rotationTimeDays === null)
        el.rotationTimeDays = null

      if (el.id) {
        // Update
        await API.updateException(el.id, el)
      } else {
        // create
        await API.createException({
          ...el,
          client: this.exceptionsTabData[child_1].client,
        })
      }
    }
    this.exceptionsTabData = null
    await this.getExceptionTabData()
    this.exceptionsEdited = {}
  }

  @action('export analytics')
  exportAnalytic = async () => {
    await API.exportAnalytic()
    window.open(
      process.env.REACT_APP_API_URL + '/uploads/analytic.xlsx',
      '_blank'
    )
  }
}
const analyticsStore = new AnalyticsStore()
export default analyticsStore
