import { observable, action, makeAutoObservable, computed } from 'mobx'
import FilterStore from './Filters/FilterStore'
import ShoppersStore from './ShoppersStore'

import { Shoppers as ShoppersAPI, Visits as VisitsAPI } from '../API'
class SelectionStore {
  @observable show = false
  @observable tab = 'object'
  @observable lastTab = null
  @observable selectedUsers = []
  @observable visits = []
  @observable visitFilters = {
    address: {
      address: '',
      lat: null,
      lng: null,
    },
    isLimit: true,
    isRotation: true,
    isStep: true,
    radius: { label: '1км', value: 1 },
    selected: [],
  }
  @observable selectionVisits = null

  constructor() {
    makeAutoObservable(this)
  }
  @action('show/hide selection')
  toggleShow = (type, data) => {
    let types = {
      user: 'shopper-filters',
      shop: 'visit-filters',
    }
    if (!type) {
      this.show = false
    } else {
      this.show = true
      this.tab = types[type]
      if (type === 'shop') {
        this.selectionVisits = FilterStore.shopList
      }
    }
  }
  @action('set tab')
  setTab = (tab) => {
    this.lastTab = this.tab
    this.tab = tab
  }
  @action('set tab')
  prevTab = () => {
    this.setTab(this.lastTab)
  }

  @action('show selection panel for shop')
  startShopSelection = (shop) => {
    FilterStore.toggleList()
    FilterStore.shopList = [shop]
    this.toggleShow('shop', [shop])
  }
  @action('show selection panel for user')
  startUserSelection = (user) => {
    this.visitFilters.address = {
      address: user.address,
      lat: user.lat,
      lng: user.lng,
    }
    this.selectedUsers = [user]
    this.toggleShow('user')
  }
  @action('finish selection')
  finishSelection = () => {
    ShoppersStore.clearFilter()
    this.visitFilters = {
      address: {
        address: '',
        lat: null,
        lng: null,
      },
      isLimit: false,
      isRotation: false,
      isStep: false,
      radius: { label: '1км', value: 1 },
      selected: [],
    }
    this.visits = []
    this.selectionVisits = null
    this.selectedUsers = []
    this.toggleShow()
    FilterStore.shopList = []
    FilterStore.shopListIds = []
  }

  @action('change visits filter')
  changeVisitsFilter = (key, val) => {
    if (key === 'select') {
      if (this.visitFilters.selected.includes(val)) {
        this.visitFilters.selected.splice(
          this.visitFilters.selected.indexOf(val),
          1
        )
      } else {
        this.visitFilters.selected.push(val)
      }
    } else {
      this.visitFilters[key] = val
    }
  }
  @observable isFetch = false
  @action('find shoppers')
  recommendShoppers = async () => {
    this.setTab('shoppers-list')
    this.isFetch = true
    const visitsIds = this.selectionVisits.map((v) => v.id)
    let { lat, lng } = this.selectionVisits.reduce(
      (acc, v) => {
        return { lat: acc.lat + v.lat, lng: acc.lng + v.lng }
      },
      { lat: 0, lng: 0 }
    )
    lat = lat / this.selectionVisits.length
    lng = lng / this.selectionVisits.length
    ShoppersStore.filteredShoppers = await ShoppersAPI.getFilteredShoppers({
      age: ShoppersStore.filter.ageRange?.join(' '),
      gender: ShoppersStore.filter.gender?.value,
      isLimit: ShoppersStore.filter.isLimit,
      isRotation: ShoppersStore.filter.isRotation,
      isStep: ShoppersStore.filter.isStep,
      radius: ShoppersStore.filter.radius?.value,
      lat,
      lng,
      isAuto: ShoppersStore.filter.isAuto,
      auto: ShoppersStore.filter.auto,
      isAutoOut: ShoppersStore.filter.isAutoOut,
      visitIds: visitsIds.join(' '),
    })
    this.isFetch = false
  }
  @action('Accept selected shoppers')
  acceptUsers = async () => {
    let visits = []
    for (let i = 0; i < this.selectionVisits.length; i++) {
      const v = this.selectionVisits[i]
      let visit = await VisitsAPI.getVisit(v.id)
      visits.push(visit)
    }
    this.selectionVisits = visits

    this.selectedUsers = ShoppersStore.filteredShoppers.filter((s) =>
      ShoppersStore.filterSelected.includes(s.id)
    )
    this.setTab('accept')
  }
  @action('find visits')
  recommendVisits = async () => {
    // Move this actions to selection store
    this.setTab('visits-list')
    this.isFetch = true
    this.visits = await VisitsAPI.getFilteredVisits({
      lat: this.visitFilters.address.lat,
      lng: this.visitFilters.address.lng,
      isLimit: this.visitFilters.isLimit,
      isRotation: this.visitFilters.isRotation,
      isStep: this.visitFilters.isStep,
      radius: this.visitFilters.radius?.value,
      shopperId: this.selectedUsers[0].id,
    })
    this.isFetch = false
  }
  @action('accept visits')
  acceptVisit = async () => {
    let visits = []
    for (let i = 0; i < this.visitFilters.selected.length; i++) {
      const v = this.visitFilters.selected[i]
      let visit = await VisitsAPI.getVisit(v)
      visits.push(visit)
    }
    this.selectionVisits = visits

    this.setTab('accept')
  }
  @computed get selectionMessage() {
    return `Добрий день, #shopper name # Це твоя компанія 4Service)
        \nПропонуємо вам відвідати локацію: #client name# за адресою: #client city#, #client address#. 
        \nОплата візиту / ів #client payrate# #client currency#. #date# числа. 
        \nПоспішайте підтвердити. Пропозиція обмежена. Гарного дня, з турботою 4Service.`
  }
}

const selectionStore = new SelectionStore()

export default selectionStore
