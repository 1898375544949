// import ArrowIcon from '../../assets/icons/arrow-icon.svg'
import { getMonthYear } from '../../util'
function DateTable({ plan, visitsPlanned, visitsFact }) {
  if (!plan) return null

  const prediction = plan
    .reduce((acc, el) => (el.count ? (acc + el.count) / 2 : acc), 0)
    .toFixed(0)
  return (
    <div className="layout pb">
      <div className="date-table">
        <div className="date-table-header">
          <div>Выполенение плана по дням</div>
          <div className="date-table-controll">
            {/* <button className="btn-label rotate">
              <img src={ArrowIcon} alt="arrow" />
            </button> */}
            <div className="date-table-controll__date">
              {getMonthYear(new Date())}
            </div>
            {/* <button className="btn-label">
              <img src={ArrowIcon} alt="arrow" />
            </button> */}
          </div>
        </div>

        <div className="date-table-body">
          <div className="date-table-row">
            {plan.slice(0, 19).map((day, i) => (
              <div className="date-table-cell" key={i}>
                {day.day}
              </div>
            ))}
          </div>
          <div className="date-table-row">
            {plan.slice(0, 19).map((day, i) => (
              <div className="date-table-cell" key={i}>
                {day.count}
              </div>
            ))}
          </div>
          <div className="date-table-row">
            {plan.slice(19).map((day, i) => (
              <div className="date-table-cell" key={i}>
                {day.day}
              </div>
            ))}
            <div className="date-table-cell cell-result ml-auto">план</div>
            <div className="date-table-cell cell-result">факт</div>
            <div className="date-table-cell cell-result">прогноз</div>
          </div>
          <div className="date-table-row">
            {plan.slice(19).map((day, i) => (
              <div className="date-table-cell" key={i}>
                {day.count}
              </div>
            ))}
            <div className="date-table-cell cell-result ml-auto">
              {visitsPlanned || '---'}
            </div>
            <div className="date-table-cell cell-result">
              {visitsFact || '---'}
            </div>
            <div className="date-table-cell cell-result">
              {prediction || '---'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DateTable
