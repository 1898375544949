import ShopListItem from '../common/lists/ShopListItem'
import { observer } from 'mobx-react-lite'
import { FilterStore, ProjectsFilterStore, SelectionStore } from '../../store'

const FilterList = observer(({ show }) => {
  const shopList = FilterStore.shopList
  const startSelection = () => {
    FilterStore.toggleList()
    SelectionStore.toggleShow('shop', shopList)
  }
  const goToLocation = (shop) => ProjectsFilterStore.showLocationInfo(shop)

  const removeShop = (id) => FilterStore.removeShop(id)
  return (
    <div className={`filter-card filter-list ${show ? 'active' : ''}`}>
      {shopList.length > 0 ? (
        <>
          {shopList.map((shop) => (
            <>
              <ShopListItem
                key={shop.id}
                shop={shop}
                handleInfo={() => goToLocation(shop)}
                handleDelete={() => removeShop(shop.id)}
              />
            </>
          ))}
          <div className="filter-list__bottom">
            <button
              onClick={() => startSelection()}
              className="btn btn-primary"
            >
              Подобрать шопера
            </button>
          </div>
        </>
      ) : (
        <div className="filter-card__no-items">Добавьте магазины в список</div>
      )}
    </div>
  )
})

export default FilterList
