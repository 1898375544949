const LoaderCircular = ({ show }) => {
  if (!show) return null
  return (
    <div className="loader-wrapper">
      <div className="loader"></div>
    </div>
  )
}

export default LoaderCircular
