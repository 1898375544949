import React from 'react'

import Logo from '../assets/logo.svg'
import { Icon } from './common'
import { NavLink, Link, useHistory } from 'react-router-dom'

import { NotificationsStore, AuthStore } from '../store'
import { observer } from 'mobx-react-lite'

import Notifications from './Notifications'

const Navigation = observer(() => {
  const history = useHistory()
  const allowUsers = AuthStore.getPermission('users')
  return (
    <nav className="navigation">
      <Link to="/" className="navigation__logo">
        <img src={Logo} alt="Logo" />
      </Link>
      {AuthStore.currentUser && (
        <div className="navigation-content">
          <NavLink exact to="/" className="navigation__link">
            <Icon icon="map" height="3rem" width="4rem" />
            Карта
          </NavLink>
          <NavLink to="/analytics" className="navigation__link">
            <Icon icon="analytics" height="3rem" width="4rem" />
            Аналитика
          </NavLink>
          {allowUsers && (
            <NavLink to="/users" className="navigation__link">
              Пользователи
            </NavLink>
          )}
          <div className="navigation-content__right">
            <div>
              <div className="navigation__username">
                {`${AuthStore.currentUser?.firstName} ${AuthStore.currentUser?.surname}`}
              </div>
              {/* <Link to="/edit-profile" className="navigation__edit-profile">
                Редактировать профиль
              </Link> */}
            </div>
            <div className="navigation__avatar"></div>
            <div className="navigation-btns">
              <div
                onClick={NotificationsStore.toggleNotifications}
                className="navigation__btn"
              >
                <Icon icon="notifications" size="2.5rem" />
                {NotificationsStore.notifications.length > 0 && (
                  <div className="notification__btn-label">
                    {NotificationsStore.notifications.length}
                  </div>
                )}
              </div>
              <div
                onClick={() => {
                  AuthStore.signOut()
                  history.push('/signIn')
                }}
                className="navigation__btn"
              >
                <Icon icon="logout" size="2.5rem" />
              </div>
            </div>
          </div>
        </div>
      )}
      <Notifications />
    </nav>
  )
})

export default Navigation
