import searchIcon from '../../assets/icons/search-icon.svg'
export default function Panel({ search, onSearch, onNewUser }) {
  return (
    <div className="card-header">
      <div className="search-input">
        <label className="search-input__logo" htmlFor="search">
          <img alt="Search" src={searchIcon} />
        </label>
        <input
          id="search"
          name="search"
          type="text"
          value={search}
          onChange={(e) => onSearch(e.target.value)}
          placeholder="Поиск..."
        />
      </div>
      <button className="ml-auto btn btn-primary" onClick={onNewUser}>
        Добавить
      </button>
    </div>
  )
}
