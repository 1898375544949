import { axios, securedFetchOptions } from './axios'
export const Auth = {
  login: (email, password) =>
    axios.post('/auth/local', { identifier: email, password: password }),
  updateUserPassword: (password, code) =>
    axios.post('/auth/reset-password', {
      code: code,
      password: password,
      passwordConfirmation: password,
    }),

  forgetPassword: (email) => axios.post('/auth/forgot-password', { email }),
}

export const Users = {
  getRoles: () => axios.get('/users-permissions/roles', securedFetchOptions()),
  getUsers: ({ search }) =>
    axios.get('/users', {
      params: { _q: search || undefined },
      ...securedFetchOptions(),
    }),
  createUser: (data) => axios.post('users', data, securedFetchOptions()),
  inviteUser: ({ email }) =>
    axios.post('mail/invite', { email }, securedFetchOptions()),
  updateUser: (userId, { role, username, confirmed }) =>
    axios.put(
      `/users/${userId}`,
      { role, username, confirmed },
      securedFetchOptions()
    ),

  removeUser: (userId) =>
    axios.delete(`/users/${userId}`, securedFetchOptions()),
}

export const Shoppers = {
  getShoppers: (params) => {
    let urlParams = new URLSearchParams()
    for (const key in params) {
      if (params[key]) {
        if (key === 'search') {
          urlParams.append('_q', params[key])
        } else if (key === 'area') {
          urlParams.append('lng_lte', params.area.bottomRight.lng)
          urlParams.append('lng_gte', params.area.topLeft.lng)
          urlParams.append('lat_lte', params.area.bottomRight.lat)
          urlParams.append('lat_gte', params.area.topLeft.lat)
        } else {
          urlParams.append(key, params[key])
        }
      }
    }
    urlParams.append('_limit', -1)
    return axios.get('/shoppers', {
      params: urlParams,
      ...securedFetchOptions(),
    })
  },
  getShoppersCount: (params) => {
    let urlParams = new URLSearchParams()
    for (const key in params) {
      if (params[key]) {
        if (key === 'search') {
          urlParams.append('_q', params[key])
        } else if (key === 'area') {
          urlParams.append('lng_lte', params.area.bottomRight.lng)
          urlParams.append('lng_gte', params.area.topLeft.lng)
          urlParams.append('lat_lte', params.area.bottomRight.lat)
          urlParams.append('lat_gte', params.area.topLeft.lat)
        } else {
          urlParams.append(key, params[key])
        }
      }
    }
    urlParams.append('_limit', -1)
    return axios.get('/shoppers/count', {
      params: urlParams,
      ...securedFetchOptions(),
    })
  },
  getShopper: (id) => axios.get(`/shoppers/${id}`, securedFetchOptions()),
  updateShopper: (id, data) =>
    axios.put(`/shoppers/${id}`, data, securedFetchOptions()),

  // One request for filtering and selection
  getFilteredShoppers: (params) => {
    let urlParams = new URLSearchParams()
    for (const key in params) {
      if (params[key]) urlParams.append(key, params[key])
    }
    return axios.get(`/shoppers/filter`, {
      params: urlParams,
      ...securedFetchOptions(),
    })
  },
  getRegions: () =>
    axios.get('/regions?_sort=name_ru:ASC', securedFetchOptions()),
}

export const Visits = {
  getVisits: (params) => {
    let urlParams = new URLSearchParams()
    if (params.area) {
      urlParams.append('lng_lte', params.area.bottomRight.lng)
      urlParams.append('lng_gte', params.area.topLeft.lng)
      urlParams.append('lat_lte', params.area.bottomRight.lat)
      urlParams.append('lat_gte', params.area.topLeft.lat)
    }
    if (params.statuses && params.statuses.length > 0) {
      params.statuses.forEach((s) => {
        urlParams.append('status_in', s)
      })
    }

    if (params.project) {
      if (Array.isArray(params.project)) {
        params.project.forEach((p) => {
          urlParams.append('client_in', p)
        })
      } else {
        urlParams.append('client_in', params.project)
      }
    }

    if (
      params.description &&
      (!params.project || !Array.isArray(params.project))
    )
      urlParams.append('description_in', params.description)

    if (params.search) urlParams.append('_q', params.search)

    if (params.start) urlParams.append('_start', params.start)
    if (params.sort) urlParams.append('_sort', params.sort)
    urlParams.append('_limit', params.limit || -1)

    if (params.my) urlParams.append('my', true)
    return axios.get('/visits', {
      params: urlParams,
      ...securedFetchOptions(),
    })
  },
  getFilteredVisits: (params) => {
    let urlParams = new URLSearchParams()
    for (const key in params) {
      if (params[key]) urlParams.append(key, params[key])
    }
    return axios.get(`/visits/filter`, {
      params: urlParams,
      ...securedFetchOptions(),
    })
  },
  getVisitsCount: (params) => {
    let urlParams = new URLSearchParams()
    if (params.statuses.length > 0) {
      params.statuses.forEach((s) => {
        urlParams.append('status_in', s)
      })
    } else {
      urlParams.append('status_nin', 'Выполнено')
    }
    if (params.project) {
      urlParams.append('client_in', params.project)
    }
    if (params.description) {
      urlParams.append('description_in', params.description)
    }
    if (params.search) urlParams.append('_q', params.search)

    urlParams.append('_limit', params.limit || -1)
    if (params.start) urlParams.append('_start', params.start)

    return axios.get('/visits/count', {
      params: urlParams,
      ...securedFetchOptions(),
    })
  },
  getVisit: (id) => axios.get(`/visits/${id}`, securedFetchOptions()),
  getProjects: (statuses) =>
    axios.get(
      `/visits/projects${
        statuses && statuses.length ? '?statuses=' + statuses.join(';') : ''
      }`,
      securedFetchOptions()
    ),
}

export const Status = {
  getStatuses: () => axios.get('/statuses', securedFetchOptions()),
}

export const Analytic = {
  getAnalytics: (params) =>
    axios.get('/visits/analytic', {
      params: (() => {
        let p = new URLSearchParams()
        for (let key in params) {
          if (params[key]) p.append(key, params[key])
        }
        return p
      })(),
      ...securedFetchOptions(),
    }),

  createResponsibility: (data) =>
    axios.post('/visit-permissions', data, securedFetchOptions()),
  removeResponsibility: (id) =>
    axios.delete(`/visit-permissions/${id}`, securedFetchOptions()),

  createException: (data) =>
    axios.post('/visit-exceptions', data, securedFetchOptions()),
  updateException: (id, data) =>
    axios.put(`/visit-exceptions/${id}`, data, securedFetchOptions()),

  exportAnalytic: () => axios.get(`/visits/export`, securedFetchOptions()),
}

export const Region = {
  getRegions: () => axios.get('/regions', securedFetchOptions()),
}

export const Notifications = {
  getNotifications: () => axios.get(`/notifications`, securedFetchOptions()),
}
