import Marker from '../Marker.jsx'
import MarkerOpen from './ShopOpenMarker'

function ShopMarker({ marker, isOpen, openMarker, close, openCard, isInList }) {
  if (isOpen) return <MarkerOpen shop={marker} open={openCard} close={close} />

  return (
    <Marker
      icon={marker.icon}
      type="shop"
      open={openMarker}
      label={`${marker.payRate} ${marker.currency}`}
      isInList={isInList}
    />
  )
}

export default ShopMarker
