import React, { useState, useEffect, useRef } from 'react'
import CheckBox from './CheckBox'

function MultiSelect(props) {
  const [isActive, setIsActive] = useState(false)
  const [selectValue, setSelectValue] = useState([])
  const selectRef = useRef(null)
  const handleOutsideClick = (e) => {
    const el = e.target.closest('.select-wrapper')
    if (!el || selectRef.current !== el) {
      setIsActive(false)
    }
  }
  useEffect(() => {
    if (isActive) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  })
  useEffect(() => {
    if (props.defaultValue) setSelectValue(props.defaultValue)
  }, [props.defaultValue])

  const handleSelect = (v) => {
    let tSelectValue = selectValue ? [...selectValue] : []
    if (tSelectValue.includes(v)) {
      tSelectValue.splice(tSelectValue.indexOf(v), 1)
    } else {
      tSelectValue.push(v)
    }
    setSelectValue(tSelectValue)
    props.handle(tSelectValue)
  }

  let arrowStyle = {
    transform: `rotate(${isActive ? '180deg' : '0'})`,
  }

  let value = null

  for (let k in props.list) {
    if (props.list[k] === props.value) value = k
  }
  const renderList = () => {
    let list = []
    list.push(
      <div
        className="select-list__item"
        key="default"
        onClick={() => {
          let v = selectValue.length === props.list.length ? [] : props.list
          props.handle(v)
          setSelectValue(v)
        }}
      >
        {props.default || 'Не выбрано'}
      </div>
    )
    for (let k of props.list) {
      list.push(
        <div className="select-list__item" key={k}>
          <CheckBox
            value={selectValue.includes(k)}
            handle={() => handleSelect(k)}
          >
            {k}
            {props.getOptionSublabel ? (
              <div className="select-list__item--sub">
                {props.getOptionSublabel(k)}
              </div>
            ) : null}
          </CheckBox>
        </div>
      )
    }
    return list
  }

  return (
    <div
      ref={selectRef}
      className={`select-wrapper ${isActive ? 'active' : ''}`}
      onClick={() => setIsActive(!isActive)}
    >
      <div className="select__inner">
        <div className={`select ${value !== null ? 'active' : ''}`}>
          <span>
            {(selectValue.length > 0 &&
              selectValue.length < props.list.length &&
              selectValue.join(', ')) ||
              props.placeholder}
          </span>
          <svg
            style={{ ...arrowStyle, width: '1.25rem', minWidth: '1.25rem' }}
            viewBox="0 0 10 6"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4.88621 5.16663L0.53887 0.166626L9.23355 0.166627L4.88621 5.16663Z" />
          </svg>
        </div>
        <div className="select-list" onClick={(e) => e.stopPropagation()}>
          {renderList()}
        </div>
      </div>
    </div>
  )
}

export default MultiSelect
