import React from 'react'

// import AddressInput from '../../../common/AddressInput'
import SearchInput from '../../../common/SearchInput'
import CheckBox from '../../../common/CheckBox'
// import Select from '../../../common/Select'
import { SelectNew as Select } from '../../../common'

import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

import { observer } from 'mobx-react-lite'

import { ShoppersStore } from '../../../../store/'
const ListTab = observer(({ next }) => {
  const filter = ShoppersStore.filter
  const filterOptions = ShoppersStore.filterOptions
  const regions = ShoppersStore.regions

  const changeFilter = (key, value) => {
    ShoppersStore.changeFilter(key, value)
  }
  const clearFilter = () => ShoppersStore.clearFilter()
  const submitFilter = () => ShoppersStore.submitFilter()

  const showFilterStyles = {
    opacity: 1,
    transition: 'all .3s ease-in',
    height: 'auto',
  }
  const hideFilterStyles = {
    opacity: 0,
    height: 0,
  }
  return (
    <React.Fragment>
      <div className="block-custom-scroll">
        <div className="range-label">
          <div>Возраст</div>
          <div className="range-label__value">
            {filter.ageRange[0]}-{filter.ageRange[1]}
          </div>
        </div>
        <Slider.Range
          min={0}
          max={100}
          defaultValue={filter.ageRange}
          onChange={(v) => changeFilter('ageRange', v)}
        ></Slider.Range>

        <Select
          placeholder="Пол"
          options={filterOptions.gender}
          getOptionLabel={(option) => option.label}
          onChange={(v) => changeFilter('gender', v)}
          value={filter.gender?.label}
          //   error={errors.position}
        />
        <Select
          placeholder="Опыт работы"
          options={filterOptions.experience}
          getOptionLabel={(option) => option.label}
          onChange={(v) => changeFilter('experience', v)}
          value={filter.experience?.label}
        ></Select>
        <Select
          placeholder="Количество визитов"
          options={filterOptions.visits}
          getOptionLabel={(option) => option.label}
          onChange={(v) => changeFilter('visits', v)}
          value={filter.visits?.label}
        ></Select>

        {/* 
        <AddressInput
          id="address"
          placeholder="Адрес"
          value={ShopersFilterStore.filters.address}
          handle={(v) => ShopersFilterStore.setFilter('address', v)}
        /> */}

        {/* <div
          style={
            ShopersFilterStore.filters.address.address
              ? showFitlerStyles
              : hideFitlerStyles
          }
        >
        </div> */}

        {/* <Select
          placeholder="Радиус поиска"
          options={filterOptions.radius}
          getOptionLabel={(option) => option.label}
          onChange={(v) => changeFilter('radius', v)}
          value={filter.radius?.label}
        ></Select> */}

        <Select
          placeholder="Регион"
          options={regions}
          getOptionLabel={(option) => option.name_ru}
          onChange={(v) => changeFilter('region', v)}
          value={filter.region?.name_ru}
        ></Select>

        <div className="filter__line"></div>

        <div className="filter-checkbox-row">
          <CheckBox
            placeholder="Наличие авто"
            value={filter.isAuto}
            handle={(v) => changeFilter('isAuto', v)}
          />
        </div>
        <div className="filter-checkbox-row">
          <CheckBox
            value={filter.isAutoOut}
            handle={(v) => changeFilter('isAutoOut', v)}
            placeholder="Выездной"
          />
        </div>
        <div style={filter.isAuto ? showFilterStyles : hideFilterStyles}>
          <SearchInput
            id="car"
            placeholder="Марка автомобиля"
            value={filter.auto}
            handle={(v) => changeFilter('auto', v)}
          />
        </div>

        <div className="block-custom-scroll"></div>
      </div>
      <div className="filter-list__bottom">
        <button className="btn btn-secondary" onClick={clearFilter}>
          Сбросить
        </button>

        <button
          className="btn btn-primary"
          onClick={() => {
            submitFilter().then(() => {
              next(2)
            })
          }}
        >
          Далее
        </button>
      </div>
    </React.Fragment>
  )
})

export default ListTab
