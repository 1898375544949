import React, { useState, useEffect } from 'react'
import {
  DatePicker,
  Input,
  LoaderCircular as Loader,
  SelectNew as Select,
} from '../../common'
import searchIcon from '../../../assets/icons/search-icon.svg'
import PlusIcon from '../../../assets/icons/add-small-icon.svg'
import MinusIcon from '../../../assets/icons/remove-small-icon.svg'
import { observer } from 'mobx-react-lite'
import { AnalyticsStore, AuthStore } from '../../../store'

const Exeptions = observer(() => {
  const tableData = AnalyticsStore.exceptionsTabData
  useEffect(() => {
    if (!tableData) AnalyticsStore.getExceptionTabData()
  }, [tableData])

  const onRowChange = (row_1, key, value) => {
    AnalyticsStore.changeExceptionTableRow(row_1, key, value)
  }
  const isEdited = Object.keys(AnalyticsStore.exceptionsEdited).length > 0
  const saveTable = () => {
    AnalyticsStore.saveExceptionTable()
  }

  const isEditable = AuthStore.getPermission('exception-edit')

  const headers = [
    'Проект',
    'Лимит визитов',
    'Ротация',
    'Старт/Финиш проекта',
    'Запрещенные дни',
    'Шаг',
    'Комментарий',
  ]
  const [search, setSearch] = useState('')
  const getFilteredExceptions = () => {
    return tableData.filter((row) =>
      search
        ? row.title.toLowerCase().search(search.toLowerCase()) !== -1
        : true
    )
  }
  return (
    <>
      <div className="tab-title">
        Таблица
        <div className="search-input">
          <label className="search-input__logo" htmlFor="search">
            <img alt="Search" src={searchIcon} />
          </label>
          <input
            id="search"
            name="search"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Поиск..."
          />
        </div>
        <div className="ml-auto">
          {isEdited && (
            <button className="btn btn-primary ml-auto" onClick={saveTable}>
              Сохранить
            </button>
          )}
        </div>
      </div>

      <div className="layout pb analytics-table exeptions table">
        <div className="t-header">
          {headers.map((h) => (
            <div className="t-header__item" key={h}>
              {h}
            </div>
          ))}
        </div>

        <div className="t-body">
          {tableData ? (
            getFilteredExceptions().map((row, i) => (
              <TableRow
                key={row.client}
                level={1}
                data={row}
                onChange={(k, v) => onRowChange(i, k, v)}
                readOnly={!isEditable}
                rowEvenStyle={i % 2 === 1}
              ></TableRow>
            ))
          ) : (
            <Loader show={true} />
          )}
        </div>
      </div>
    </>
  )
})

export default Exeptions

function TableRow({
  data,
  isCollapsed,
  collapse,
  onChange,
  readOnly,
  rowEvenStyle,
}) {
  const rotationOptions = {
    [null]: { name: 'Не выбрано', value: null },
    1: { name: '1 месяц', value: 1 },
    2: { name: '2 месяц', value: 2 },
    3: { name: '3 месяц', value: 3 },
    4: { name: '4 месяц', value: 4 },
    5: { name: '5 месяц', value: 5 },
    6: { name: '6 месяц', value: 6 },
  }
  return (
    <div className={`t-row ${rowEvenStyle ? 'even' : ''}`}>
      <div className="t-row__item">
        {data.hasOwnProperty('children') ? (
          <div>
            <button
              className="t-row__collapse-btn"
              onClick={() => collapse(!isCollapsed)}
            >
              <img src={isCollapsed ? MinusIcon : PlusIcon} alt="collapse" />
            </button>
            {data.title}
          </div>
        ) : (
          data.title
        )}
      </div>
      <div className="t-row__item">
        {data?.exception && (
          <Input
            value={data.exception.limit}
            placeholder="Лимит"
            type="number"
            onChange={(v) => onChange('limit', v)}
            readOnly={readOnly}
            min="1"
          />
        )}
      </div>
      <div className="t-row__item">
        {data?.exception && (
          <Select
            placeholder="Ротация"
            options={Object.values(rotationOptions)}
            getOptionLabel={(option) => option.name}
            value={
              rotationOptions[data.exception?.rotationTimeDays || null].name
            }
            onChange={(v) => onChange('rotationTimeDays', v.value)}
            readOnly={readOnly}
          />
        )}
      </div>
      <div className="t-row__item">
        {data?.exception && (
          <>
            <DatePicker
              placeholder="Старт"
              handle={(v) => onChange('startDate', v)}
              value={data?.exception.startDate}
              readOnly={readOnly}
            />
            <DatePicker
              placeholder="Конец"
              handle={(v) => onChange('dueDate', v)}
              value={data?.exception.dueDate}
              readOnly={readOnly}
            />
          </>
        )}
      </div>
      <div className="t-row__item">
        {data?.exception && (
          <DatePicker
            placeholder="Запрещенные дни"
            handle={(v) => onChange('banedDays', v)}
            value={data.exception.banedDays}
            readOnly={readOnly}
          />
        )}
      </div>

      <div className="t-row__item">
        {data?.exception && (
          <Input
            placeholder="Шаг"
            value={data?.exception?.step || ''}
            type="number"
            onChange={(v) => onChange('step', v)}
            readOnly={readOnly}
            min="1"
          />
        )}
      </div>
      <div className="t-row__item">
        {data?.exception && (
          <Input
            placeholder="Комментарий"
            value={data?.exception?.comment}
            onChange={(v) => onChange('comment', v)}
            readOnly={readOnly}
          />
        )}
      </div>
    </div>
  )
}
