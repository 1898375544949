import React from 'react'

import CheckBoxList from '../common/lists/CheckBoxList'
import { SelectionStore, ShoppersStore, MapStore } from '../../store'
import { observer } from 'mobx-react-lite'

const ShoppersListTab = observer(() => {
  const shoppers = ShoppersStore.searchFilteredShoppers || []
  const selectedShoppers = ShoppersStore.filterSelected
  const isFetch = SelectionStore.isFetch
  const isAllSelected = shoppers.length === selectedShoppers.length

  const selectShopper = (id) => ShoppersStore.changeFilter('select', id)
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
      }}
    >
      <div className="filter-menu__title">Подбор шоперов</div>
      <CheckBoxList
        isAllSelected={isAllSelected}
        topPlaceholder="Всего шоперов:"
        handleTop={() => {}}
        topResult={shoppers.length}
        list={shoppers}
        filteredList={selectedShoppers}
        handleItem={(id) => {
          selectShopper(id)
        }}
        handleItemInfo={(shopper) => {
          MapStore.setActiveMarker({ ...shopper, type: 'user' }, true)
        }}
        getName={(shopper) => shopper.firstName + ' ' + shopper.lastName}
        countKey="visits"
        isLoading={isFetch}
        topLabel={
          <svg
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 6.5V11H3V6.5H1Z" fill="#A6ACBE" />
            <path d="M9 4V11H11V4H9Z" fill="#A6ACBE" />
            <path d="M5 2V11H7V2H5Z" fill="#A6ACBE" />
          </svg>
        }
      />

      <div className="filter-list__bottom">
        <button className="btn btn-secondary" onClick={SelectionStore.prevTab}>
          Назад
        </button>
        <button
          className="btn btn-primary"
          onClick={SelectionStore.acceptUsers}
        >
          Далее
        </button>
      </div>
    </div>
  )
})
export default ShoppersListTab
