import { useEffect } from 'react'
import ChartCard from '../ChartCard'
import DateTable from '../DateTable'
import RegionCoordinator from '../RegionCoordinator'
import { useState } from 'react'
import { AnalyticsStore } from '../../../store'
import { observer } from 'mobx-react-lite'
const Graphic = observer(() => {
  useEffect(() => {
    if (!AnalyticsStore.chartsTabData) AnalyticsStore.getChartsTabData()
  }, [])
  const [activeChart, setActiveChart] = useState(null)
  const globalAnalytic = AnalyticsStore.chartsTabData?.global
  const regionCoordinators = AnalyticsStore.chartsTabData?.regionCoordinators
  const openChart = (i) => {
    AnalyticsStore.getChartsTabData(i)
    setActiveChart(i)
  }
  return (
    <div>
      {activeChart === null ? (
        <div>
          <div className="tab-title">Аналитика</div>

          <div className="graphic-charts layout">
            <ChartCard
              chart={{
                title: 'Выполенено оценок',
                elements: [
                  { key: 'План', value: globalAnalytic?.allCount || 0 },
                  {
                    key: 'Выполнено',
                    value: globalAnalytic?.completedCount || 0,
                  },
                ],
              }}
              isLoading={!globalAnalytic}
            />
            <ChartCard
              chart={{
                title: 'Назначено квоты с учетом выполнения',
                elements: [
                  {
                    key: 'Поручено',
                    value: globalAnalytic?.assignedCount || 0,
                  },
                  {
                    key: 'Не назначено',
                    value: globalAnalytic?.openCount || 0,
                  },
                ],
              }}
              chartValue={
                globalAnalytic &&
                (100 *
                  ((globalAnalytic?.assignedCount || 0) +
                    (globalAnalytic?.completedCount || 0))) /
                  (globalAnalytic?.allCount || 1).toFixed(0)
              }
              isLoading={!globalAnalytic}
            />
            <ChartCard
              chart={{
                title: 'Как сработали в % от остатка за вчера',
                elements: [
                  { key: 'Выполнено', value: globalAnalytic?.completedCount },
                  {
                    key: 'Выполнено за вчера',
                    value:
                      globalAnalytic?.calendarPlan[new Date().getDate() - 2]
                        ?.count || 0,
                  },
                ],
              }}
              chartValue={
                globalAnalytic &&
                (100 *
                  globalAnalytic?.calendarPlan[new Date().getDate() - 2]
                    ?.count || 0) /
                  (
                    globalAnalytic?.allCount - globalAnalytic.completedCount
                  ).toFixed(0)
              }
              isLoading={!globalAnalytic}
            />
          </div>

          {globalAnalytic && (
            <>
              <DateTable
                plan={globalAnalytic?.calendarPlan}
                visitsPlanned={globalAnalytic.allCount}
                visitsFact={globalAnalytic.completedCount}
              ></DateTable>

              <div className="tab-subtitle">Ассистенты</div>

              <div className="graphic-charts layout">
                {regionCoordinators && regionCoordinators.length > 0 ? (
                  regionCoordinators.map((user, i) => (
                    <ChartCard
                      openCard={() => {
                        openChart(i)
                      }}
                      key={i}
                      chart={{
                        title:
                          user.coordinator.firstName +
                          ' ' +
                          user.coordinator.surname,
                        elements: [
                          { key: 'План', value: user?.allCount || 0 },
                          {
                            key: 'Выполнено',
                            value: user?.completedCount || 0,
                          },
                        ],
                      }}
                    />
                  ))
                ) : (
                  <div className="lg-text grey-text">Отсутствуют</div>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div>
          <RegionCoordinator
            back={() => setActiveChart(null)}
            asistance={regionCoordinators[activeChart]}
          ></RegionCoordinator>
        </div>
      )}
    </div>
  )
})

export default Graphic
