import React from 'react'
import { observer } from 'mobx-react-lite'
import { FilterStore } from '../../../store'

const AddToShopListBtn = observer(({ shop }) => {
  const isOpenStatus = ['Заявка', 'Не назначено', 'Отклонено'].includes(
    shop?.status
  )
  const isInList = FilterStore.shopListIds.includes(shop.id)
  const action = () => {
    if (isInList) {
      FilterStore.removeShop(shop.id)
    } else {
      FilterStore.addShop(shop)
    }
  }
  return (
    <button
      style={{ display: isOpenStatus ? 'flex' : 'none' }}
      className="btn btn-primary btn-plus"
      onClick={() => action()}
      type="submit"
      title="Посмотри в календаре"
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {!isInList && (
          <path
            d="M5.82339 1.6715C5.82339 1.02027 6.35132 0.492339 7.00256 0.492339C7.59459 0.492339 8.08472 0.928645 8.16894 1.49726L8.18172 1.6715V12.3263C8.18172 12.9775 7.65379 13.5055 7.00256 13.5055C6.41052 13.5055 5.9204 13.0692 5.83618 12.5005L5.82339 12.3263V1.6715Z"
            fill="white"
          />
        )}
        <path
          d="M0.488426 6.99933C0.488488 6.4073 0.924845 5.91721 1.49346 5.83305L1.66771 5.82029L12.3325 5.8214C12.9837 5.82146 13.5116 6.34945 13.5116 7.00068C13.5115 7.59272 13.0751 8.0828 12.5065 8.16696L12.3323 8.17973L1.66747 8.17862C1.01623 8.17855 0.488358 7.65056 0.488426 6.99933Z"
          fill="white"
        />
      </svg>
    </button>
  )
})

export default AddToShopListBtn
