import Datepicker from 'react-modern-calendar-datepicker'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'

function DatePicker({
  value,
  handle,
  type,
  placeholder,
  readOnly,
  disabledDays,
  maximumDate,
  minimumDate,
}) {
  const getV = (v) => new Date(v.year, v.month - 1, v.day)
  let isMultiSelect = false
  let v
  if (Array.isArray(value)) {
    isMultiSelect = true
    v = value.map((v) => ({
      year: new Date(v).getFullYear(),
      month: new Date(v).getMonth() + 1,
      day: new Date(v).getDate(),
    }))
  } else if (value) {
    v = {
      year: new Date(value).getFullYear(),
      month: new Date(value).getMonth() + 1,
      day: new Date(value).getDate(),
    }
  }

  return (
    <div
      className={`datepicker-wrapper ${isMultiSelect ? 'multi' : ''} ${
        type ? type : ''
      }`}
    >
      <svg
        className="datepicker-icon"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.25 3H3.75C2.92157 3 2.25 3.67157 2.25 4.5V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V4.5C15.75 3.67157 15.0784 3 14.25 3Z"
          stroke="#A6ACBE"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 1.5V4.5"
          stroke="#A6ACBE"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 1.5V4.5"
          stroke="#A6ACBE"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.25 7.5H15.75"
          stroke="#A6ACBE"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <Datepicker
        disabledDays={disabledDays}
        value={v}
        inputPlaceholder={placeholder}
        colorPrimary="#E5264C"
        onChange={(e) => {
          if (!readOnly && handle) {
            if (Array.isArray(value)) {
              handle(e.map((v) => getV(v)))
            } else {
              handle(getV(e))
            }
          }
        }}
        minimumDate={minimumDate}
        maximumDate={maximumDate}
      />
    </div>
  )
}

export default DatePicker
