import PlusIcon from '../../../assets/icons/add-small-icon.svg'
import MinusIcon from '../../../assets/icons/remove-small-icon.svg'
import { LoaderCircular as Loader, Switch, CheckBox } from '../../common'
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { AnalyticsStore, AuthStore } from '../../../store'
import UsersDialog from '../UsersDialog'
const Table = observer(() => {
  const showExport = AuthStore.getUserRole() !== 'coordinator'
  const tableData = AnalyticsStore.tableTabData
  const fetchChild = (args) => {
    AnalyticsStore.getTableTabData(...args)
  }
  const tableMode =
    AnalyticsStore.tableMode === 'region' ? 'Области' : 'Проекты'
  useEffect(() => {
    AnalyticsStore.getTableTabData()
    // eslint-disable-next-line
  }, [tableMode])
  const changeTableMode = (v) => {
    AnalyticsStore.changeTableMode(v === 'Области' ? 'region' : 'project')
  }
  const assignResponsibility = (args) =>
    AnalyticsStore.startAssignResponsibility(...args)

  let headers = [
    'Область / Проект / Город',
    'Выполнено',
    'Не назначено',
    'Поручено на тайного покупателя',
    'Общий итог',
    'Выполнено оценок',
    'Координатор',
    'Ассистент',
  ]
  if (tableMode === 'Проекты') headers[0] = 'Проект / Область / Город'
  return (
    <>
      <div className="tab-title">
        Таблица
        <div className="ml-auto d-flex align-center">
          {showExport && (
            <button
              className="btn btn-primary"
              style={{ marginRight: '1rem' }}
              onClick={AnalyticsStore.exportAnalytic}
            >
              Експорт
            </button>
          )}
          <Switch
            value={tableMode}
            variants={['Области', 'Проекты']}
            handle={changeTableMode}
          />
        </div>
      </div>

      <div className="layout pb analytics-table table">
        <div className="t-header">
          {headers.map((h) => (
            <div className="t-header__item" key={h}>
              {h}
            </div>
          ))}
        </div>

        <div className="t-body">
          {tableData ? (
            tableData.map((row, i) => (
              <CollapsableRow
                key={i}
                rowNum={i}
                level={1}
                data={row}
                fetchChild={(v) =>
                  v ? fetchChild([i, ...v]) : fetchChild([i])
                }
                assignResponsibility={(v) =>
                  v
                    ? assignResponsibility([i, ...v])
                    : assignResponsibility([i])
                }
                regionLevel={tableMode === 'Проекты' ? 2 : 1}
              ></CollapsableRow>
            ))
          ) : (
            <Loader show={true} />
          )}
        </div>
      </div>
      <UsersDialog />
    </>
  )
})

export default Table

function CollapsableRow({
  rowNum,
  fetchChild,
  assignResponsibility,
  data,
  level,
  regionLevel,
}) {
  const [isCollapsed, setIsCollapsed] = useState(false)
  useEffect(() => {
    if (isCollapsed && !data.children) fetchChild()
    // eslint-disable-next-line
  }, [isCollapsed])

  return (
    <>
      <TableRow
        level={level}
        rowEvenStyle={rowNum % 2 === 1}
        data={data}
        isCollapsed={isCollapsed}
        collapse={setIsCollapsed}
        assignResponsibility={(v) =>
          assignResponsibility(v.length > 0 ? [v] : v ? [v] : [null])
        }
        regionLevel={regionLevel}
      ></TableRow>
      <div
        className="collapse-block"
        style={{
          display: isCollapsed ? 'block' : 'none',
        }}
      >
        {data.children ? (
          data.children.map((row, i) => (
            <CollapsableRow
              key={i + '-level-' + level}
              rowNum={i + 2}
              collapsed={true}
              level={level + 1}
              data={row}
              fetchChild={(v) => (v ? fetchChild([i, ...v]) : fetchChild([i]))}
              assignResponsibility={(v) =>
                v ? assignResponsibility([i, ...v]) : assignResponsibility([i])
              }
              regionLevel={regionLevel}
            ></CollapsableRow>
          ))
        ) : (
          <Loader show={true} />
        )}
      </div>
    </>
  )
}

function TableRow({
  level,
  data,
  isCollapsed,
  collapse,
  rowEvenStyle,
  assignResponsibility,
  regionLevel,
}) {
  const sum = data.assigned + data.open + data.completed
  return (
    <div className={`t-row ${rowEvenStyle ? 'even' : ''}`}>
      <div
        style={{
          marginLeft: (level - 1) * 2.5 + 'rem',
          width: 30 - (level - 1) * 2.5 + 'rem',
        }}
        className="t-row__item t-row__title"
      >
        {data.hasOwnProperty('children') ? (
          <div>
            <button
              className="t-row__collapse-btn"
              onClick={() => collapse(!isCollapsed)}
            >
              <img src={isCollapsed ? MinusIcon : PlusIcon} alt="collapse" />
            </button>
            {data.title}
          </div>
        ) : (
          <CheckBox placeholder={data.title} />
        )}
      </div>
      <div className="t-row__item">{data.completed}</div>
      <div className="t-row__item">{data.open}</div>
      <div className="t-row__item">{data.assigned}</div>
      <div className="t-row__item">{sum}</div>
      <div className="t-row__item">
        {sum ? Number((data.completed * 100) / sum).toFixed(2) : 0}%
      </div>
      <div className="t-row__item">
        <span
          onClick={() =>
            assignResponsibility({
              coordinators: data.coordinators,
              isRegion: false,
            })
          }
        >
          {data.coordinators && data.coordinators.length > 0 ? (
            data.coordinators.length === 1 ? (
              `${data.coordinators[0].firstName} ${data.coordinators[0].surname}`
            ) : (
              <span>
                Назначено{' '}
                <span className="count">{data.coordinators.length}</span>
              </span>
            )
          ) : (
            'Не назначено'
          )}
        </span>
      </div>
      <div className="t-row__item">
        {regionLevel === level ? (
          <span
            onClick={() =>
              assignResponsibility({
                coordinators: data.assistans,
                isRegion: true,
              })
            }
          >
            {data.assistans && data.assistans.length > 0 ? (
              data.assistans.length === 1 ? (
                `${data.assistans[0].firstName} ${data.assistans[0].surname}`
              ) : (
                <span>
                  Назначено{' '}
                  <span className="count">{data.assistans.length}</span>
                </span>
              )
            ) : (
              'Не назначено'
            )}
          </span>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
