import React, { useState, useRef } from 'react'
import searchIcon from '../../assets/icons/search-icon.svg'
import LoadingIndicator from './LoadingIndicator'
import { axios } from '../../store/axios'
import { MapStore } from '../../store'
import { useOutClick } from '../../util'

function AddressInput({ value, handle, placeholder, readOnly }) {
  const REQUEST_TIMEOUT_TIME = 1500
  const [address, setAddress] = useState(
    value
      ? value
      : {
          address: '',
          lat: null,
          lng: null,
        }
  )
  const [variants, setVariants] = useState([])
  const [isFetch, setIsFetch] = useState(false)
  const [requestTimeout, setRequestTimeout] = useState(null)
  const selectRef = useRef(null)
  useOutClick(
    selectRef,
    variants.length > 0,
    () => setVariants([]),
    '.address-input-wrapper'
  )

  const handleAddressChange = (e) => {
    setAddress({ address: e.target.value })
    if (address.address.length >= 4) {
      if (requestTimeout) clearTimeout(requestTimeout)
      setRequestTimeout(
        setTimeout(() => {
          getAddressVariants(address)
        }, REQUEST_TIMEOUT_TIME)
      )
    }
  }

  const getAddressVariants = (address) => {
    setIsFetch(true)
    setVariants([])
    return axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address.address
        )}&key=AIzaSyBb-4iyAJxuh-uVyIVXslzIoHulPuX7KGo`
      )
      .then((res) => {
        setIsFetch(false)
        if (res.status === 'OK') {
          setVariants(res.results)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const renderList = () => {
    // console.log(variants)
    return variants.map((item) => (
      <div
        className="select-list__item"
        key={item.address}
        onClick={() => {
          let newAddress = {
            address: item.formatted_address,
            lat: item.geometry.location.lat,
            lng: item.geometry.location.lng,
          }
          handle(newAddress)
          setAddress(newAddress)
          if (requestTimeout) clearTimeout(requestTimeout)
          setVariants([])
        }}
      >
        {item.formatted_address}
      </div>
    ))
  }
  return (
    <div
      ref={selectRef}
      className={`address-input-wrapper select-wrapper ${
        variants.length > 0 ? 'active' : ''
      }`}
    >
      <div className="select__inner">
        <div className="select" style={{ alignItems: 'center' }}>
          <LoadingIndicator isBottom show={isFetch} />
          <label
            className="search-input__logo"
            style={{ height: '3rem' }}
            htmlFor="address"
          >
            <img
              alt="Search"
              src={searchIcon}
              onClick={() => {
                if (value && value.lat && value.lng)
                  MapStore.goToPlace(address.lat, address.lng)
              }}
            />
          </label>
          <input
            id="address"
            name="search"
            type="text"
            value={address.address}
            onChange={(e) => handleAddressChange(e)}
            placeholder={placeholder}
            readOnly={readOnly}
          />
        </div>

        <div className="select-list" onClick={(e) => e.stopPropagation()}>
          {renderList()}
        </div>
      </div>
    </div>
  )
}

export default AddressInput
