import React from "react";
import searchIcon from '../../assets/icons/search-icon.svg';

function SearchInput({value, handle, id, placeholder}) {
    return (
        <div className="search-input">
            <label className="search-input__logo" htmlFor={id}>
                <img alt="Search" src={searchIcon} />
            </label>
            <input
                id={id}
                name="search"
                type="text"
                value={value}
                onChange={e => handle(e.target.value)}
                placeholder={placeholder}
            />
        </div>
    )
}

export default SearchInput;