import React, { useEffect } from 'react'
import Select from '../../../common/inputs/Select'
import { MultiSelect, LoadingIndicator } from '../../../common'
import 'rc-slider/assets/index.css'
import { observer } from 'mobx-react-lite'
import { VisitsStore } from '../../../../store'

const VisitsFilterTab = observer(({ next }) => {
  const filter = VisitsStore.filter
  const isFetch = VisitsStore.isFilterFetch
  useEffect(() => {
    if (!filter.projects) VisitsStore.loadFilterProjects()
    // eslint-disable-next-line
  }, [])

  const setFilter = (key, value) => VisitsStore.setFilter(key, value)
  const findVisits = () => VisitsStore.loadFilterVisits()
  const clearFilter = () => VisitsStore.clearFilter()
  return (
    <div className="filter-card__inner">
      <LoadingIndicator show={isFetch} isBackground />
      {filter.projects && (
        <MultiSelect
          placeholder="Все"
          defaultValue={filter.project}
          value={
            filter.project ? filter.project.map((p) => p.name).join(', ') : []
          }
          handle={(v) => setFilter('project', v)}
          list={Object.keys(filter.projects)}
          getOptionSublabel={(key) => filter.projects[key].count}
        ></MultiSelect>
      )}
      {filter.project.length === 1 && (
        <Select
          placeholder="Cценарий"
          value={filter.description}
          onChange={(v) => setFilter('description', v)}
          options={Object.values(
            filter.projects[filter.project[0]].descriptions
          )}
          getOptionLabel={(option) => option}
        ></Select>
      )}
      <MultiSelect
        default="Все"
        placeholder="Статус"
        defaultValue={filter.status}
        value={filter.status.join(', ')}
        handle={(v) => setFilter('status', v)}
        list={filter.statuses}
      />
      <div className="flex-1"></div>
      <div className="filter-list__bottom">
        <button
          className="btn btn-secondary"
          onClick={() => {
            clearFilter()
          }}
        >
          Сбросить
        </button>

        <button
          disabled={!filter.project.length}
          className="btn btn-primary"
          onClick={() => {
            next(2)
            findVisits()
          }}
        >
          Далее
        </button>
      </div>
    </div>
  )
})

export default VisitsFilterTab
