import { useEffect, useState } from 'react'
import useSupercluster from 'use-supercluster'
import { observer } from 'mobx-react-lite'
import {
  ShoppersStore,
  VisitsStore,
  MapStore,
  FilterStore,
} from '../../../store'
import UserMarker from './user/UserMarker'
import ShopMarker from './shop/ShopMarker'
import UserMultiMarker from './user/UserMultiMarker'
import ShopMultiMarker from './shop/ShopMultiMarker'
import CustomMarker from './CustomMarker'

const Markers = observer(({ zoom, bounds }) => {
  const { clusters: clustersUser } = useSupercluster({
    points: ShoppersStore.points,
    bounds,
    zoom: zoom,
    options: {
      radius: 175,
      maxZoom: 30,
      map: (props) => ({
        markers: [props.data],
        type: 'user',
        visitsCount: Number(props.data.visitsCount),
        ID: props.data.id + 'cluster',
      }),
      reduce: (acc, props) => {
        acc.visitsCount += props.markers[0].visitsCount
        acc.markers = [...props.markers, ...acc.markers]
        return acc
      },
    },
  })
  const { clusters: clustersShop } = useSupercluster({
    points: VisitsStore.points,
    bounds,
    zoom: zoom,
    options: {
      radius: 175,
      maxZoom: 30,
      map: (props) => ({
        markers: [props.data],
        type: 'shop',
        payRate: props.data.payRate,
        currency: props.data.currency,
        ID: props.data.id + 'cluster',
      }),
      reduce: (acc, props) => {
        acc.payRate += props.payRate
        acc.markers = [...props.markers, ...acc.markers]
        return acc
      },
    },
  })

  useEffect(() => {
    let markers = document.querySelectorAll('.marker--min')
    for (let element of markers) {
      element
        .closest('.mapboxgl-marker')
        .addEventListener('mouseover', elementIn)
      element
        .closest('.mapboxgl-marker')
        .addEventListener('mouseout', elementOut)
    }

    return () => {
      let markers = document.querySelectorAll('.mapboxgl-marker')
      for (let element of markers) {
        clearEvents(element)
      }
    }
  })
  const setOpenMarker = (id, position) => {
    MapStore.setOpenMarker(id, position)
  }
  const setOpenCard = (marker) => {
    MapStore.setActiveMarker(marker)
  }
  const closeOpenMarker = () => MapStore.clearOpenMarker()
  const openMarkerId = MapStore.openMarker
  const shopListIds = FilterStore.shopListIds
  const [renderTimeout, setRenderTimeout] = useState(null)

  useEffect(() => {
    if (openMarkerId) return
    const clearRenderTiomeout = () => {
      clearTimeout(renderTimeout)
      setRenderTimeout(null)
    }
    if (renderTimeout) clearRenderTiomeout()
    setRenderTimeout(setTimeout(() => clearRenderTiomeout(), 300))
    // eslint-disable-next-line
  }, [bounds])

  const renderMarkers = () =>
    [...clustersUser, ...clustersShop].map((marker, i) => {
      const [longitude, latitude] = marker.geometry.coordinates
      const position = { longitude, latitude }
      const { cluster, type } = marker.properties
      const markerType = cluster ? 'multi-' + type : type
      const markerKey =
        markerType + '-' + marker.properties[cluster ? 'cluster_id' : 'id']
      let props = {
        openMarker: () => setOpenMarker(markerKey, { latitude, longitude }),
        openCard: (id, icon) => {
          setOpenCard({
            type,
            icon: icon ? icon : marker.properties.icon,
            id: id ? id : marker.properties.id,
          })
        },
        isOpen: markerKey === openMarkerId,
        position: position,
        close: closeOpenMarker,
      }
      const renderMarker = () => {
        switch (markerType) {
          case 'user':
            return <UserMarker marker={marker.properties.data} {...props} />
          case 'multi-user':
            return (
              <UserMultiMarker
                isMulti
                markers={marker.properties.markers}
                point_count={marker.properties.point_count}
                visitsCount={marker.properties.visitsCount}
                {...props}
              />
            )
          case 'shop':
            return (
              <ShopMarker
                marker={marker.properties.data}
                {...props}
                isInList={
                  shopListIds.includes(marker.properties.id) ||
                  marker.properties.data.attached
                }
              />
            )
          case 'multi-shop':
            return (
              <ShopMultiMarker
                markers={marker.properties.markers}
                payRate={marker.properties.payRate}
                point_count={marker.properties.point_count}
                currency={marker.properties.currency}
                {...props}
              />
            )
          default:
            return null
        }
      }
      return (
        <CustomMarker
          isOpen={markerKey === openMarkerId}
          key={markerKey}
          latitude={latitude}
          longitude={longitude}
        >
          {renderMarker()}
        </CustomMarker>
      )
    })

  return <>{renderMarkers()}</>
})

export default Markers

const elementIn = (e) => {
  e.target.closest('.mapboxgl-marker').style.zIndex = 50
}
const elementOut = (e) => {
  e.target.closest('.mapboxgl-marker').style.zIndex = 1
}
const clearEvents = (el) => {
  el.removeEventListener('mouseover', elementIn)
  el.removeEventListener('mouseout', elementOut)
}
