import React from 'react'
import { SelectionStore } from '../../store'
import { useObserver } from 'mobx-react'
import ShopperFilterTab from './ShopperFilterTab'
import VisitFilterTab from './VisitFilterTab'
import ShoppersListTab from './ShoppersListTab'
import VisitsListTab from './VisitsListTab'
import AcceptTab from './Accept'
import CloseBtn from '../map/common/CloseBtn'

function Selection() {
  const renderActiveTab = () => {
    switch (SelectionStore.tab) {
      case 'shopper-filters':
        return <ShopperFilterTab />
      case 'visits-list':
        return <VisitsListTab />
      case 'visit-filters':
        return <VisitFilterTab />
      case 'shoppers-list':
        return <ShoppersListTab />
      case 'accept':
        return <AcceptTab />
      default:
        return null
    }
  }
  return useObserver(() => (
    <React.Fragment>
      <div
        className={`selection-wrapper filter-menu ${
          SelectionStore.show ? 'active' : ''
        }`}
      >
        <CloseBtn click={() => SelectionStore.toggleShow()} />
        {SelectionStore.show && renderActiveTab()}
      </div>
    </React.Fragment>
  ))
}

export default Selection
