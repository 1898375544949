/* eslint-disable no-unused-vars */
import React, { useRef } from 'react'
import ReactMapGl from 'react-map-gl'

import { observer } from 'mobx-react-lite'
import { MapStore } from '../../store'
import Markers from './markers/Markers'

const initialBounds = [
  29.98221924999976, 50.26283696978328, 31.036906750000412, 50.58088508738061,
]

const Map = observer(() => {
  const mapRef = useRef()

  // Границы карты
  let bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null
  const viewport = MapStore.viewport
  const isMapScroll = !MapStore.openMarker
  const handleMapClick = (e) => MapStore.handleMapClick(e)
  const handleViewportChange = (viewport) =>
    MapStore.setViewport(viewport, bounds || initialBounds)
  return (
    <div
      onClick={handleMapClick}
      className={`map-wrapper zoom-${viewport.zoom.toFixed(0)}`}
    >
      <ReactMapGl
        ref={mapRef}
        {...{ ...viewport, scrollZoom: isMapScroll }}
        mapStyle="mapbox://styles/egotkacheco/ckkxyhkvw6hih17p5ruy022jv"
        onViewportChange={handleViewportChange}
        mapboxApiAccessToken="pk.eyJ1IjoiZWdvdGthY2hlY28iLCJhIjoiY2treHh4aHpmMHVzOTJ5bzQ4NjJieWIzcCJ9.-MPCPfgSNtvC_Jw4qEQkww"
      >
        <Markers zoom={viewport.zoom} bounds={bounds} />
      </ReactMapGl>

      <ZoomControl
        zoomIn={() =>
          handleViewportChange({ ...viewport, zoom: ++viewport.zoom })
        }
        zoomOut={() =>
          handleViewportChange({ ...viewport, zoom: --viewport.zoom })
        }
      />
    </div>
  )
})

export default Map

const ZoomControl = ({ zoomIn, zoomOut }) => {
  return (
    <div className="zoom-controll">
      <div onClick={zoomOut} className="zoom-controll__item">
        <span></span>
      </div>
      <div onClick={zoomIn} className="zoom-controll__item">
        <span></span>
        <span></span>
      </div>
    </div>
  )
}
