import React from 'react'

import CheckBoxList from '../common/lists/CheckBoxList'
import { MapStore, SelectionStore } from '../../store'
import { observer } from 'mobx-react-lite'

const VisitsListTab = observer(() => {
  const visits = SelectionStore.visits || []
  const selectedVisits = SelectionStore.visitFilters.selected
  const isFetch = SelectionStore.isFetch
  const isAllSelected = visits.length === selectedVisits.length

  const selectVisit = (id) => SelectionStore.changeVisitsFilter('select', id)
  // const showInfo = (shopper) => ShoppersStore.showInfo(shopper)
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
      }}
    >
      <div className="filter-menu__title">Подбор визитов</div>
      <CheckBoxList
        isAllSelected={isAllSelected}
        topPlaceholder="Всего визитов:"
        handleTop={() => {
          if (isAllSelected) {
            SelectionStore.visitFilters.selected = []
          } else {
            SelectionStore.visitFilters.selected = visits.map((v) => v.id)
          }
        }}
        topResult={visits.length}
        list={visits}
        filteredList={selectedVisits}
        handleItem={(id) => {
          selectVisit(id)
        }}
        handleItemInfo={(visit) => {
          MapStore.setActiveMarker({ ...visit, type: 'shop' }, true)
        }}
        getName={(visit) => `${visit.client}, ${visit.city}, ${visit.address}`}
        countKey="payRate"
        isLoading={isFetch}
        topLabel="UAH"
      />

      <div className="filter-list__bottom">
        <button className="btn btn-secondary" onClick={SelectionStore.prevTab}>
          Назад
        </button>
        <button
          className="btn btn-primary"
          onClick={SelectionStore.acceptVisit}
        >
          Далее
        </button>
      </div>
    </div>
  )
})
export default VisitsListTab
