import React from 'react';

function CloseBtn(props) {
    return (
        <button className="marker__close" onClick={e => {
            props.click()
            e.stopPropagation();
         }}>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.37194 0.283046C6.74405 -0.089059 7.34735 -0.089059 7.71945 0.283046C8.05773 0.621323 8.08848 1.15067 7.81171 1.52369L7.71945 1.63056L1.63147 7.71854C1.25937 8.09064 0.656067 8.09064 0.283962 7.71854C-0.0543157 7.38026 -0.0850682 6.85091 0.191704 6.47789L0.283962 6.37103L6.37194 0.283046Z" fill="#A6ACBE" />
                <path d="M0.279149 0.279009C0.617462 -0.0592335 1.14681 -0.0899308 1.5198 0.18688L1.62666 0.279149L7.71972 6.37348C8.09179 6.74562 8.09172 7.34893 7.71958 7.72099C7.38127 8.05923 6.85192 8.08993 6.47893 7.81312L6.37207 7.72085L0.279009 1.62652C-0.0930577 1.25438 -0.0929948 0.651075 0.279149 0.279009Z" fill="#A6ACBE" />
            </svg>
        </button>
    );
}

export default CloseBtn;