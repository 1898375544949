import React from 'react'
import { Calendar } from 'react-modern-calendar-datepicker'
import CloseBtn from './CloseBtn'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'

function CalendarWrapper({ show, value, close }) {
  return (
    <div>
      {show && (
        <div className="calendar-wrapper">
          <CloseBtn click={close} />
          <Calendar
            // minimumDate={utils().getToday()}
            // maximumDate={maximumDate}
            value={value}
            colorPrimary="#E5264C"
            calendarSelectedDayClassName="calendar--selected_day"
          />
        </div>
      )}
    </div>
  )
}

export default CalendarWrapper
