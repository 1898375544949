import React from 'react'
import Filter from '../components/filter/Filter'
import Map from '../components/map/Map'
import SideCards from '../components/SideCards'
const MainPage = () => {
  return (
    <div className="page-content main">
      <Filter />
      <SideCards />
      <Map />
    </div>
  )
}

export default MainPage
