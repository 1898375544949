import React, { useState } from 'react'
import AccountIcon from '../../assets/icons/account-icon.svg'
import PasswordIcon from '../../assets/icons/password-icon.svg'
import { Input, LoaderCircular as Loader } from '../common'
import { observer } from 'mobx-react-lite'
import { AuthStore } from '../../store/'
import { Link } from 'react-router-dom'

const SignIn = observer(() => {
  const [errors, setErrors] = useState({ email: '', password: '' })
  const handleSingIn = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const email = formData.get('email')
    const password = formData.get('password')
    let errors = { email: '', password: '' }

    // Validate
    if (!email) {
      errors.email = 'Введите логин'
    }
    if (!password) {
      errors.password = 'Введите пароль'
    }
    // Sing In or show Errors
    if (email && password) {
      AuthStore.signIn(email, password).catch((err) => {
        setErrors({ email: 'Пользователь не найден' })
      })
    } else {
      setErrors(errors)
    }
  }
  const isFetch = AuthStore.isFetch
  return (
    <form onSubmit={handleSingIn} className="card">
      <Loader show={isFetch} />
      {/* <Loader show={isFetch} isBackground /> */}
      <h3 className="card__title">Войдите в систему</h3>

      <Input
        icon={AccountIcon}
        name="email"
        error={errors.email}
        placeholder="Логин"
      />
      <Input
        icon={PasswordIcon}
        name="password"
        type="password"
        error={errors.password}
        placeholder="Пароль"
      />

      <div className="card-actions">
        <button className="btn btn-primary ml-auto">Далее</button>
      </div>

      <div className="card-bottom-link">
        <Link className="link" to="/forget-password">
          Забыли пароль?
        </Link>
      </div>
    </form>
  )
})

export default SignIn
