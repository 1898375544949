import React, { useState } from 'react'
import { MultiSelect, Switch, Icon, AddressInput } from '../common'
import Search from './Search'

import { observer } from 'mobx-react-lite'
import { FilterStore, MapStore } from '../../store/'

const Filter = observer(() => {
  const showFilterMenu = FilterStore.showFilter
  const showShoperList = FilterStore.showShoperList
  const toggleFilter = () => FilterStore.toggleFilter()
  const toggleList = () => FilterStore.toggleList()
  const searchVariants = ['Карта', 'Адрес']
  const [searchVariant, setSearchVariant] = useState(searchVariants[0])
  return (
    <div className="filter">
      <div className="filter__left">
        <button
          className={`btn-icon ${showFilterMenu ? 'active' : ''}`}
          onClick={toggleFilter}
        >
          <Icon icon="settings" size="3rem" />
        </button>

        <button
          className={`btn-icon ${showShoperList ? 'active' : ''}`}
          onClick={toggleList}
        >
          <Icon icon="menu" size="3rem" />
          {FilterStore.shopList.length > 0 && (
            <div className="btn-icon__label">{FilterStore.shopList.length}</div>
          )}
        </button>
      </div>

      {searchVariant === searchVariants[0] ? (
        <Search />
      ) : (
        <AddressInput
          placeholder="Поиск адреса..."
          handle={(data) => {
            MapStore.goToPlace(data.lat, data.lng, true)
          }}
        />
      )}
      <Switch
        variants={searchVariants}
        value={searchVariant}
        handle={setSearchVariant}
      />
      <div className="label">Локации</div>
      <Switch
        variants={FilterStore.locations}
        value={FilterStore.location}
        handle={FilterStore.setLocation}
      />

      <div className="label">
        Статусы{' '}
        {FilterStore.requestType.length > 0
          ? `(${FilterStore.requestType.length})`
          : null}
      </div>

      <MultiSelect
        default="Все"
        placeholder="Все"
        defaultValue={FilterStore.requestType}
        handle={FilterStore.setRequestType}
        list={FilterStore.statuses.slice(1)}
      />
    </div>
  )
})

export default Filter
