import React, { useState } from 'react'
import CheckBoxList from '../../../common/lists/CheckBoxList'
import SearchInput from '../../../common/SearchInput'
import { observer } from 'mobx-react-lite'
import { VisitsStore, MapStore } from '../../../../store'
const ListTab = observer(({ back }) => {
  const filter = VisitsStore.filter
  const isFetch = VisitsStore.isFilterFetch
  const setFilter = (key, value) => VisitsStore.setFilter(key, value)
  // const findVisits = () => VisitsStore.loadFilterVisits()
  const [search, setSearch] = useState('')
  return (
    <>
      <SearchInput placeholder="Поиск..." value={search} handle={setSearch} />

      {filter.visits && (
        <CheckBoxList
          isAllSelected={filter.visits.length === filter.selected.length}
          topPlaceholder="Всего визитов:"
          handleTop={() => setFilter('selected', [])}
          topResult={filter.visits.length}
          list={
            search
              ? filter.visits.filter(
                  (v) =>
                    `${v.city} ${v.address}`.toLowerCase().search(search) !== -1
                )
              : filter.visits
          }
          filteredList={filter.selected}
          handleItem={(id) => setFilter('selected', id)}
          handleItemInfo={(visit) => {
            MapStore.setActiveMarker({ ...visit, type: 'shop' }, true)
          }}
          getName={(v) => v.city + ', ' + (v.address ? v.address : '---')}
          countKey="payRate"
          isLoading={isFetch}
          topLabel="UAH"
        />
      )}
      <div className="flex-1"></div>

      <div className="filter-list__bottom">
        <button className="btn btn-secondary" onClick={back}>
          Назад
        </button>
        <button
          disabled={!filter.selected.length}
          className="btn btn-primary"
          onClick={() => {
            VisitsStore.isUseFilter = true
          }}
        >
          Применить
        </button>
      </div>
    </>
  )
})

export default ListTab
