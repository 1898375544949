import { observer } from 'mobx-react-lite'
import FilterList from './filter/FilterShopList.jsx'
import FilterMenu from './filter/FilterMenu.jsx'
import UserCard from './map/UserCard'
import ShopCard from './map/ShopCard'
import Selection from './selection/Selection'
import LoadingIndicator from './common/LoadingIndicator'
import {
  FilterStore,
  SelectionStore,
  MapStore,
  ShoppersStore,
  VisitsStore,
} from '../store'

const SideCards = observer(() => {
  const showFilter = FilterStore.showFilter
  const showShoperList = FilterStore.showShoperList
  const showSelection = SelectionStore.show
  const openMarkerPosition =
    showFilter || showShoperList || showSelection ? 'right' : 'left'

  const activeMarker = MapStore.activeMarker
  const updateShopper = (data, key) => ShoppersStore.updateShopper(data, key)
  const isMapLoading = ShoppersStore.isFetch || VisitsStore.isFetch
  return (
    <>
      <div className="map-loader">
        <LoadingIndicator show={isMapLoading} isBackground />
      </div>
      <FilterMenu show={showFilter} />
      <FilterList show={showShoperList} />
      <Selection />

      <UserCard
        position={openMarkerPosition}
        data={activeMarker}
        show={activeMarker?.type === 'user'}
        onChange={updateShopper}
      />
      <ShopCard
        show={activeMarker?.type === 'shop'}
        position={openMarkerPosition}
        shop={activeMarker}
      />
    </>
  )
})

export default SideCards
