import Navigation from './components/Navigation.jsx'
import React from 'react'

import Login from './pages/Login.jsx'
import ForgetPassword from './pages/ForgetPassword'
import ResetPassword from './pages/ResetPassword'
import SignUp from './pages/SignUp.jsx'
import Main from './pages/Main.jsx'
import Analytics from './pages/Analytics'
import Users from './pages/Users'
// import EditProfile from './pages/EditProfile'

import { Switch, Route, Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { AuthStore } from './store'
const App = observer(() => {
  return (
    <div className="App">
      <Navigation></Navigation>

      <Switch>
        {!AuthStore.currentUser ? (
          <>
            <Route exact path="/signUp">
              <SignUp />
            </Route>
            <Route exact path="/signIn">
              <Login />
            </Route>
            <Route exact path="/forget-password">
              <ForgetPassword />
            </Route>
            <Route exact path="/reset-password">
              <ResetPassword />
            </Route>

            <Route exact path="/">
              <Redirect to="/signIn" />
            </Route>
          </>
        ) : (
          <>
            <Route path="/analytics">
              <Analytics />
            </Route>
            {AuthStore.getPermission('users') && (
              <Route path="/users">
                <Users />
              </Route>
            )}
            {/* <Route path="/edit-profile">
              <EditProfile></EditProfile>
            </Route> */}
            <Route exact path="/">
              <Main />
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </>
        )}
      </Switch>
    </div>
  )
})

export default App
