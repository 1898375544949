import { Input, Select, LoadingIndicator } from '../common'
import { observer } from 'mobx-react-lite'
import { UsersStore } from '../../store'
const UserDialog = observer(({ show }) => {
  const close = () => UsersStore.closeDialog()
  const handle = (data) => UsersStore.handleDialog(data)
  const handleField = (key, val) => UsersStore.updateForm(key, val)
  const errors = UsersStore.formError
  const form = UsersStore.activeUser
  const roles = UsersStore.getRoles()
  const isFetch = UsersStore.isFetch
  const isNew = UsersStore.isNew
  if (!show) return null

  return (
    <div className="dialog-wrapper">
      <div className="dialog">
        {isFetch && <LoadingIndicator show={true} isBackground={true} />}
        <h3 className="card__title">
          {isNew ? 'Создание' : 'Редактирование'} пользователя
        </h3>

        <Input
          value={form.firstName}
          onChange={(v) => handleField('firstName', v)}
          name="firstName"
          placeholder="Имя"
          error={errors.firstName}
        />
        <Input
          value={form.lastName}
          onChange={(v) => handleField('lastName', v)}
          name="lastName"
          placeholder="Фамилия"
          error={errors.lastName}
        />
        <Input
          value={form.patronymic}
          onChange={(v) => handleField('patronymic', v)}
          name="patronymic"
          placeholder="Отчество"
        />
        <Input
          value={form.email}
          onChange={(v) => handleField('email', v)}
          name="email"
          readOnly={!isNew}
          placeholder="Почта"
          error={errors.email}
        />
        <Select
          value={form.role.name}
          onChange={(v) => handleField('role', v)}
          options={roles}
          getOptionLabel={(role) => role.name}
          placeholder="Роль"
          error={errors.role}
        />

        <div className="sm-text error">{errors.global}</div>

        <div className="card-actions">
          <button className="btn btn-secondary" onClick={close}>
            Отменить
          </button>
          <button className="btn btn-primary ml-auto" onClick={handle}>
            Сохранить
          </button>
        </div>
      </div>
    </div>
  )
})

export default UserDialog
