import React, { useState } from 'react'

function LoadingIndicator({ show, isBackground }) {
  const [animationTimeout, setAnimationTimeout] = useState(null)
  const [startAnimation, setStartAnimation] = useState(false)

  if (show && !animationTimeout) {
    setAnimationTimeout(
      setTimeout(() => {
        setStartAnimation(true)
        setAnimationTimeout(
          setTimeout(() => {
            setStartAnimation(false)
            setAnimationTimeout(null)
          }, 1000)
        )
      }, 100)
    )
  }
  if (isBackground && !show) return null
  return (
    <div className={`loading-wrapper ${isBackground ? 'isBackground' : ''}`}>
      <div
        style={{
          width: startAnimation ? '100%' : 0,
          transition: startAnimation ? 'all 1s ease-in' : '',
        }}
        className="loading-indicator"
      ></div>
    </div>
  )
}

export default LoadingIndicator
