import ChartCard from './ChartCard'
import DateTable from './DateTable'
import Arrow from '../../assets/icons/arrow-icon.svg'
import React from 'react'

function RegionCoordinator({ back, asistance }) {
  return (
    <div>
      <div className="tab-title">
        <button onClick={back} className="btn-label rotate">
          <img src={Arrow} alt="arrow" />
        </button>
        Выполнено оценок
      </div>

      <div className="tab-subtitle">{`${asistance.coordinator.firstName} ${asistance.coordinator.surname}`}</div>

      <div className="graphic-charts layout">
        <ChartCard
          chart={{
            title: 'Выполенено оценок',
            elements: [
              { key: 'План', value: asistance?.allCount || 0 },
              {
                key: 'Выполнено',
                value: asistance?.completedCount || 0,
              },
            ],
          }}
        />
        <ChartCard
          chart={{
            title: 'Назначено квоты с учетом выполнения',
            elements: [
              {
                key: 'Поручено',
                value: asistance?.allCount - asistance?.openCount || 0,
              },
              {
                key: 'Не назначено',
                value: asistance?.openCount || 0,
              },
            ],
          }}
          chartValue={
            (100 *
              ((asistance?.assignedCount || 0) +
                (asistance?.completedCount || 0))) /
            (asistance?.allCount || 1).toFixed(0)
          }
        />
        <ChartCard
          chart={{
            title: 'Как сработали в % от остатка за вчера',
            elements: [
              {
                key: 'Выполнено',
                value: asistance?.completedCount,
              },
              {
                key: 'Выполнено за вчера',
                value: asistance?.yesterdayCompleted || 0,
              },
            ],
          }}
          chartValue={
            (100 * asistance?.yesterdayCompleted) /
            (asistance?.allCount - asistance.completedCount).toFixed(0)
          }
        />
      </div>

      <DateTable></DateTable>

      <div className="tab-subtitle">Менеджеры</div>

      <div className="graphic-charts layout">
        {asistance?.coordinators && asistance?.coordinators.length > 0 ? (
          asistance.coordinators.map((manager, i) => (
            <ChartCard
              min
              key={i}
              chart={{
                title: `${manager.coordinator.firstName} ${manager.coordinator.surname}`,
                elements: [
                  { key: 'План', value: manager?.allCount || 0 },
                  {
                    key: 'Выполнено',
                    value: manager?.completedCount || 0,
                  },
                ],
              }}
            />
          ))
        ) : (
          <div className="lg-text grey-text">Отсутствуют</div>
        )}
      </div>
    </div>
  )
}

export default RegionCoordinator
