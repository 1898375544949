import React, { useState } from 'react'

import searchIcon from '../../assets/icons/search-icon.svg'
import { useObserver } from 'mobx-react'

import { FilterStore } from '../../store/'

function Search() {
  const [v, setv] = useState(FilterStore.search)
  const [searchTimeout, setSearchTimeout] = useState(null)
  const handle = (e) => {
    setv(e.target.value)
    if (searchTimeout) {
      clearSearchtimeout()
    }
    setSearchTimeout(
      setTimeout(() => {
        FilterStore.setSearch(e.target.value)
        clearSearchtimeout()
      }, 800)
    )
  }
  const clearSearchtimeout = () => {
    clearTimeout(searchTimeout)
    setSearchTimeout(null)
  }
  return useObserver(() => (
    <div className="search-input">
      <label className="search-input__logo" htmlFor="search">
        <img alt="Search" src={searchIcon} />
      </label>
      <input
        id="search"
        name="search"
        type="text"
        value={v}
        onChange={handle}
        placeholder="Поиск на карте..."
      />
    </div>
  ))
}

export default Search
