import React from 'react'

import CheckBox from '../common/CheckBox'
import SearchInput from '../common/SearchInput'
import Select from '../common/inputs/Select'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

import { ShoppersStore, SelectionStore } from '../../store'
import { observer } from 'mobx-react-lite'

const VisitFilterTab = observer(() => {
  const filter = ShoppersStore.filter
  const filterOptions = ShoppersStore.filterOptions

  const changeFilter = (key, value) => {
    ShoppersStore.changeFilter(key, value)
  }
  const submitFilter = () => SelectionStore.recommendShoppers()

  const showFilterStyles = {
    opacity: 1,
    transition: 'all .3s ease-in',
    height: 'auto',
  }
  const hideFilterStyles = {
    opacity: 0,
    height: 0,
  }
  return (
    <div>
      <div className="filter-menu__title">Подбор шоперов</div>

      <div className="range-label">
        <div>Возраст</div>
        <div className="range-label__value">
          {filter.ageRange[0]}-{filter.ageRange[1]}
        </div>
      </div>
      <Slider.Range
        min={0}
        max={100}
        defaultValue={filter.ageRange}
        onChange={(v) => changeFilter('ageRange', v)}
      ></Slider.Range>

      <Select
        placeholder="Пол"
        options={filterOptions.gender}
        getOptionLabel={(option) => option.label}
        onChange={(v) => changeFilter('gender', v)}
        value={filter.gender?.label}
      ></Select>

      <div className="filter-checkbox-row" style={{ marginTop: '3rem' }}>
        <CheckBox
          value={filter.isLimit}
          handle={(v) => changeFilter('isLimit', v)}
          placeholder="Лимит визитов"
        ></CheckBox>
      </div>
      <div className="filter-checkbox-row">
        <CheckBox
          value={filter.isRotation}
          handle={(v) => changeFilter('isRotation', v)}
          placeholder="С учетом ротации"
        ></CheckBox>
      </div>
      <div className="filter-checkbox-row">
        <CheckBox
          value={filter.isStep}
          handle={(v) => changeFilter('isStep', v)}
          placeholder="С учетом шага"
        ></CheckBox>
      </div>

      <div className="range-label" style={{ marginTop: '3rem' }}>
        Радиус поиска
      </div>
      <Select
        placeholder="Радиус поиска"
        options={filterOptions.radius}
        getOptionLabel={(option) => option.label}
        onChange={(v) => changeFilter('radius', v)}
        value={filter.radius?.label}
      ></Select>

      <div className="filter-checkbox-row">
        <CheckBox
          placeholder="Наличие авто"
          value={filter.isAuto}
          handle={(v) => changeFilter('isAuto', v)}
        />
      </div>
      <div className="filter-checkbox-row">
        <CheckBox
          value={filter.isAutoOut}
          handle={(v) => changeFilter('isAutoOut', v)}
          placeholder="Выездной"
        />
      </div>
      <div style={filter.isAuto ? showFilterStyles : hideFilterStyles}>
        <SearchInput
          id="car"
          placeholder="Марка автомобиля"
          value={filter.auto}
          handle={(v) => changeFilter('auto', v)}
        />
      </div>

      <div className="block-custom-scroll"></div>

      <div className="filter-list__bottom">
        <button className="btn btn-primary" onClick={submitFilter}>
          Поиск
        </button>
      </div>
    </div>
  )
})
export default VisitFilterTab
