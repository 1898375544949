import { useState } from 'react'

const Input = ({
  name,
  value = '',
  onChange,
  type = 'text',
  placeholder,
  icon,
  error,
  readOnly,
  min,
  max,
}) => {
  const [v, setV] = useState(value)
  return (
    <div className={`input-wrapper ${error ? 'error' : ''}`}>
      <div className="input">
        {icon ? <img src={icon} alt={name} /> : ''}
        <input
          name={name}
          type={type}
          value={v}
          readOnly={readOnly}
          onChange={(e) => {
            if (type === 'number') {
              if (isNaN(Number(e.target.value))) return
            }
            setV(e.target.value)
            if (onChange) onChange(e.target.value)
          }}
          placeholder={placeholder}
          min={min}
          max={max}
        />
      </div>
      <div className="input-error">{error}</div>
    </div>
  )
}

export default Input
