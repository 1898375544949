import React from 'react'
import Market from '../../assets/icons/market-icon.svg'
import { useState } from 'react'
import { Icon, DatePicker } from '../common'
import { SelectionStore, MapStore } from '../../store'
import { observer } from 'mobx-react-lite'
import { formatPhone } from '../../util'

const Accept = observer(() => {
  const visits = SelectionStore.selectionVisits
  const [dates, setDates] = useState(new Array(visits.length).fill(new Date()))
  const changeDate = (date, i) => {
    let arr = [...dates]
    arr[i] = date
    setDates(arr)
  }
  const shoppersList = SelectionStore.selectedUsers
  const finishSelection = () => SelectionStore.finishSelection()
  const getMessege = (user) => {
    let message = `Добрий день, ${user.firstName} Це твоя компанія 4Service)\nПропонуємо вам відвідати:`

    visits.forEach((visit, i) => {
      let sD =
        new Date(dates[i]).getDate() > new Date().getDate()
          ? new Date(dates[i]).getDate() - 1
          : new Date(dates[i]).getDate()
      let eD =
        new Date(dates[i]).getDate() < 31
          ? new Date(dates[i]).getDate() + 1
          : new Date(dates[i]).getDate()
      message += `\n${visit.client} за адресою: ${visit.city}, ${
        visit.address
      }. 
      \nОплата візиту/ів ${
        visit.bonus ? visit.payRate + visit.bonus : visit.payRate
      } ${visit.currency}. ${sD}-${eD} числа. \n`
    })

    message += `\nПоспішайте підтвердити. Пропозиція обмежена. Гарного дня, з турботою 4Service.`
    return message
  }
  const copyText = (msg) => {
    return navigator.clipboard.writeText(msg)
  }
  // const goToTelegram = async (user) => {
  //   let msg = getMessege(user)
  //   await copyText(msg)
  //   window.open(
  //     `https://telegram.me/share/url?url=${' '}&text=${msg}`,
  //     '_blank'
  //   )
  //   //
  // }
  const goToViber = async (user) => {
    let msg = getMessege(user)
    await copyText(msg)
    window.open(
      `viber://chat?number=+38${formatPhone(user.phone).callPhone}&text=${msg}`,
      '_blank'
    )
  }
  const goToWhatsApp = async (user) => {
    let msg = getMessege(user)
    await copyText(msg)
    window.open(
      `https://api.WhatsApp.com/send?phone=38${
        formatPhone(user.phone).callPhone
      }&text=${msg}`,
      '_blank'
    )
  }
  const getDisabledDays = (exception) => {
    if (!exception || !exception.banedDays) return []

    return exception.banedDays.split(' ').map((d) => ({
      year: new Date(d).getFullYear(),
      month: new Date(d).getMonth() + 1,
      day: new Date(d).getDate(),
    }))
  }
  const minimumDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  }
  const end = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)
  const maximumDate = {
    year: end.getFullYear(),
    month: end.getMonth() + 1,
    day: end.getDay(),
  }
  return (
    <div className="accept-tab">
      <div className="filter-menu__title">Оформление визита</div>

      <div className="filter-list">
        {visits.map((visit, i) => (
          <>
            <div className="filter-list__item">
              <div className="filter-list__item-top">
                <img
                  src={Market}
                  className="filter-list__avatar"
                  alt={visit.client}
                />
                <div>
                  <div className="filter-list__title">{visit.client}</div>
                  <div className="filter-list__price">
                    {visit.bonus ? visit.payRate + visit.bonus : visit.payRate}{' '}
                    {visit.currency}
                  </div>
                </div>
              </div>

              <div className="d-flex align-center">
                <span className="sm-text">Дата посещения:</span>
                <DatePicker
                  type="flat"
                  value={dates[i]}
                  handle={(v) => changeDate(v, i)}
                  disabledDays={getDisabledDays(visit.exception)}
                  minimumDate={minimumDate}
                  maximumDate={maximumDate}
                />
              </div>
              <div className="sm-text">
                {visit.city}, {visit.address || '---'}
              </div>
            </div>
          </>
        ))}
      </div>

      <div className="filter__line"></div>

      {/* <div className="filter-checkbox-row" style={{ color: '#5F6475' }}>
        <div>Шоперы</div>
        <div className="rate-icon">
          <Icon icon="rate" size="1.5rem" />
        </div>
      </div> */}

      {shoppersList.map((user) => (
        <div
          className="filter-checkbox-row"
          key={user.id}
          style={{ paddingTop: 0 }}
        >
          <span>{user.firstName + ' ' + user.lastName}</span>
          <div className="user-links">
            <Icon
              icon="info"
              size="1.5rem"
              onClick={() => {
                MapStore.setActiveMarker({ ...user, type: 'user' }, true)
              }}
            />
            <Icon
              icon="whatsapp"
              size="1.5rem"
              onClick={() => goToWhatsApp(user)}
            />
            <Icon icon="viber" size="1.5rem" onClick={() => goToViber(user)} />
          </div>
          <div className="ml-auto">
            <b>{user.visitsCount}</b>
          </div>
        </div>
      ))}

      <div className="filter__line"></div>

      <div className="input-wrapper">
        <div className="input">
          <textarea
            name="message"
            id="message"
            className="input"
            value={SelectionStore.selectionMessage}
            placeholder="Текст сообщения"
            rows="9"
          ></textarea>
        </div>
      </div>
      <div className="filter-list__bottom">
        <button
          className="btn btn-secondary"
          onClick={() => {
            SelectionStore.prevTab()
          }}
        >
          Назад
        </button>
        <button className="btn btn-primary" onClick={finishSelection}>
          Завершить
        </button>
      </div>
    </div>
  )
})
export default Accept
