import Logo from '../assets/logo-white.svg'
// import { Link } from 'react-router-dom'
function Footer() {
  return (
    <footer className="footer">
      <div className="footer__logo">
        <img src={Logo} alt="Logo" />
      </div>

      {/* <Link className="footer__link" to="/">О нас</Link> */}
      {/* <Link className="footer__link" to="/">Политика конфедициальности</Link> */}

      <div className="footer__copyright">
        Copyright © 2021 4Service Holdings GmBH. All rights reserved. Use of any
        materials <br /> of the site is allowed only with reference to the site
        4Service
      </div>
    </footer>
  )
}

export default Footer
