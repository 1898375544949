import React from 'react'

function CardRow({ label, value, valueClass = '', isTwoLines = false }) {
  return (
    <div
      style={{
        justifyContent: 'space-between',
        alignItems: isTwoLines ? 'flex-start' : 'center',
        flexDirection: isTwoLines ? 'column' : 'row',
      }}
      className="card-row"
    >
      <div className="text-label">{label}</div>
      <div className="card-row__child">
        <b className={valueClass}>{value ? value : 'Отсутствует'}</b>
      </div>
    </div>
  )
}

export default CardRow
