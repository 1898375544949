
import React, {useState} from 'react';
import ListTab from './ShopersFilterTabs/ListTab';
import FiltersTab from './ShopersFilterTabs/FiltersTab';

function ShopersFilter() {
    const [tab, setTab] = useState(1);
    return (
        <div className="filter-card__inner">
            {tab === 1 ?
                <FiltersTab
                    next={() => setTab(2)}
                />
                :
                <ListTab
                    back={() => setTab(1)}
                />
                }
        </div>
    );
}

export default ShopersFilter;



