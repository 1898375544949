import { observable, action, makeAutoObservable, computed } from 'mobx'
import MapStore from '../MapStore'
import { Visits as API } from '../../API'

class ProjectsStore {
  @observable filters = {
    project: '',
    description: '',
    search: '',
    selected: [],
  }
  @observable projects = []

  constructor() {
    makeAutoObservable(this)
  }
  @action('change filter')
  setFilter = (key, value) => {
    let type = 0
    if (['search', 'project', 'description'].includes(key)) type = 3
    if (['search'].includes(key)) type = 3
    if (['selected'].includes(key)) type = 4
    switch (type) {
      case 3:
        this.filters[key] = value
        break
      case 4:
        if (this.filters.selected.includes(value)) {
          this.filters.selected.splice(this.filters.selected.indexOf(value), 1)
        } else {
          this.filters.selected.push(value)
        }
        break
      default:
        break
    }
  }
  @action('toggle all projects selected')
  setFilterAllProjects = (val) => {
    this.filters.selected = val
      ? []
      : this.searchProjects.map((s) => {
          return s.id
        })
  }
  @action('load projects to filter')
  loadProjects = async () => {
    try {
      let projects = await API.getProjects()
      this.projects = projects.result
    } catch (error) {
      console.log(error)
    }
  }
  @action('Show user on map and open user marker')
  showLocationInfo = (shop) => {
    MapStore.setActiveMarker(shop)
    MapStore.goToPlace(shop.lat, shop.lng, true)
  }
  @computed get searchProjects() {
    return this.filters.search
      ? this.visits.filter(
          (p) =>
            p.name
              .toLocaleLowerCase()
              .search(this.filters.search.toLocaleLowerCase()) !== -1
        )
      : this.projects
  }
}

const projectsStore = new ProjectsStore()

export default projectsStore
