import Marker from '../Marker.jsx'
import MarkerOpen from './UserOpenMarker'

const UserMarker = ({ marker, isOpen, openMarker, close, openCard }) => {
  if (isOpen) return <MarkerOpen user={marker} open={openCard} close={close} />

  return (
    <Marker
      icon={marker.icon}
      type="user"
      open={openMarker}
      label={marker.visitsCount}
    />
  )
}

export default UserMarker
