import React, { useState } from 'react'
import AccountIcon from '../assets/icons/account-icon.svg'
import { Input, LoaderCircular as Loader } from '../components/common'
import { observer } from 'mobx-react-lite'
import { AuthStore } from '../store'
import Footer from '../components/Footer'

const ForgetPassword = observer(() => {
  const [errors, setErrors] = useState({ email: '' })
  const [isSuccess, setIsSuccess] = useState(false)
  const handleForm = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const email = formData.get('email')
    let errors = { email: '' }
    if (!email) errors.email = 'Введите email'
    // Sing In or show Errors
    if (email) {
      AuthStore.forgetPassword(email)
        .then((res) => {
          setIsSuccess(true)
        })
        .catch((err) => {
          setErrors({ email: 'Пользователь не найден' })
        })
    } else {
      setErrors(errors)
    }
  }
  const isFetch = AuthStore.isFetch
  return (
    <>
      <div className="page-content auth-page">
        <form onSubmit={handleForm} className="card">
          <Loader show={isFetch} />
          <h3 className="card__title">Забыли пароль</h3>

          {isSuccess ? (
            <>
              <div className="md-text">Письмо успешно отправлено. </div>
            </>
          ) : (
            <>
              <Input
                icon={AccountIcon}
                name="email"
                error={errors.email}
                placeholder="Введите email"
              />
              <div className="card-actions">
                <button className="btn btn-primary ml-auto">
                  Восстановить
                </button>
              </div>
            </>
          )}
        </form>
      </div>
      <Footer></Footer>
    </>
  )
})

export default ForgetPassword
