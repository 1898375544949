import React from 'react'
// import InfoIcon from '../../assets/icons/info-icon.svg'

import CheckBox from '../common/CheckBox'
// import SearchInput from '../common/SearchInput';
import AddressInput from '../common/AddressInput'
import Select from '../common/inputs/Select'
import { SelectionStore, ShoppersStore } from '../../store'
import { observer } from 'mobx-react-lite'

const radiusVariants = ShoppersStore.filterOptions.radius

const ShopperFilterTab = observer(() => {
  const filter = SelectionStore.visitFilters
  const changeFilter = (key, value) =>
    SelectionStore.changeVisitsFilter(key, value)

  return (
    <div>
      <div className="filter-menu__title">Подбор визитов</div>
      <div className="range-label">Фактический адрес шоппера</div>
      <AddressInput
        id="address"
        placeholder="Адрес"
        value={filter.address}
        handle={(v) => changeFilter('address', v)}
      />

      <div className="filter-checkbox-row">
        <CheckBox
          value={filter.isLimit}
          handle={(v) => changeFilter('isLimit', v)}
          placeholder="Лимит визитов"
        ></CheckBox>
      </div>
      <div className="filter-checkbox-row">
        <CheckBox
          value={filter.isRotation}
          handle={(v) => changeFilter('isRotation', v)}
          placeholder="С учетом ротации"
        ></CheckBox>
      </div>
      <div className="filter-checkbox-row">
        <CheckBox
          value={filter.isStep}
          handle={(v) => changeFilter('isStep', v)}
          placeholder="С учетом шага"
        ></CheckBox>
      </div>

      <div className="range-label" style={{ marginTop: '3rem' }}>
        Радиус поиска
      </div>
      <Select
        placeholder="Радиус поиска"
        options={radiusVariants}
        getOptionLabel={(option) => option.label}
        onChange={(v) => changeFilter('radius', v)}
        value={filter.radius?.label}
      ></Select>

      <div className="block-custom-scroll"></div>

      <div className="filter-list__bottom">
        <button
          className="btn btn-primary"
          onClick={() => SelectionStore.recommendVisits()}
        >
          Далее
        </button>
      </div>
    </div>
  )
})
export default ShopperFilterTab
