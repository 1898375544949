import React, { useRef } from 'react'
import { useOutClick } from '../util'

import { NotificationsStore, MapStore } from '../store/'
import { observer } from 'mobx-react-lite'
import { formatDateTime } from '../util'

const Notifications = observer(() => {
  const goToLocation = (notification) =>
    MapStore.goToPlace(notification.visit.lat, notification.visit.lng, true)
  const notificationRef = useRef()
  useOutClick(
    notificationRef,
    NotificationsStore.showNotifications,
    NotificationsStore.toggleNotifications,
    '.notifications-wrapper'
  )
  return (
    <div
      className={`notifications-wrapper ${
        NotificationsStore.showNotifications ? 'active' : ''
      }`}
    >
      {NotificationsStore.notifications.length > 0 ? (
        NotificationsStore.notifications.map((notification, i) => (
          <div
            className="notification"
            key={i}
            onMouseDown={(e) => {
              e.stopPropagation()
            }}
          >
            <div className="notification-row">
              <img
                src={notification.icon}
                alt={notification.from}
                className="notification__icon"
              />
              <div className="notification__from">{notification.from}</div>
              {notification.visit.lat && (
                <svg
                  className="notification__geo"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => goToLocation(notification)}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.01912 1.33337C4.94643 1.32293 2.43665 3.76757 2.33634 6.82757L2.33325 7.01751C2.38432 8.51738 2.89463 9.94713 3.79344 11.1251L4.00453 11.4006C4.86245 12.4914 5.88199 13.4489 7.02677 14.2381L7.3024 14.4236L7.34261 14.4552C7.73498 14.7371 8.26476 14.7371 8.65713 14.4552L8.6906 14.4277C9.59896 13.8386 10.4366 13.1464 11.1869 12.3649C12.7123 10.7501 13.6317 8.94083 13.6664 7.10332L13.6665 7.0351C13.6769 3.95462 11.2398 1.43712 8.18923 1.33646L8.01912 1.33337ZM8.01569 2.34496C10.5347 2.35357 12.5799 4.36626 12.6559 6.87273L12.658 7.09373C12.6288 8.63428 11.8222 10.2216 10.4577 11.6661C9.76187 12.3908 8.98175 13.035 8.13563 13.583L8.07923 13.6253C8.03366 13.6649 7.96607 13.6649 7.92051 13.6253L7.8657 13.584C6.60523 12.761 5.49896 11.722 4.59749 10.5145C3.87137 9.56278 3.44109 8.42491 3.35448 7.23791L3.34175 6.9999C3.35031 4.47479 5.3568 2.42331 7.85555 2.34713L8.01569 2.34496ZM7.99987 4.9342C6.80761 4.9342 5.84109 5.9037 5.84109 7.09965C5.84109 8.29559 6.80761 9.26509 7.99987 9.26509C9.19213 9.26509 10.1587 8.29559 10.1587 7.09965C10.1587 5.9037 9.19213 4.9342 7.99987 4.9342ZM7.99987 5.94582C8.63515 5.94582 9.15015 6.4624 9.15015 7.09965C9.15015 7.73689 8.63515 8.25347 7.99987 8.25347C7.36459 8.25347 6.84959 7.73689 6.84959 7.09965C6.84959 6.4624 7.36459 5.94582 7.99987 5.94582Z"
                    fill="#A6ACBE"
                  />
                </svg>
              )}
            </div>
            <div className="notification__message">{notification.message}</div>
            <div className="notification__date">
              {formatDateTime(notification.published_at)}
            </div>
          </div>
        ))
      ) : (
        <div className="notification no-items">Нет Уведомлений</div>
      )}
    </div>
  )
})

export default Notifications
