import React from 'react';

function Switch(props) {

    return (
        <div className="switch">
            <div
                onClick={() => props.handle(props.variants[0])}
                className={`switch__item ${props.value === props.variants[0] ? 'active' : ''}`}
            >
                {props.variants[0]}
            </div>

            <div
                onClick={() => props.handle(props.variants[1])}
                className={`switch__item ${props.value === props.variants[1] ? 'active' : ''}`}
            >
                {props.variants[1]}
            </div>
        </div>
    )
}

export default Switch;