import React, { useState } from 'react'
import PasswordIcon from '../assets/icons/password-icon.svg'
import { Input, LoaderCircular as Loader } from '../components/common'
import { observer } from 'mobx-react-lite'
import { AuthStore } from '../store'
import { Link, useLocation } from 'react-router-dom'
import Footer from '../components/Footer'

const ResetPassword = observer(() => {
  const location = useLocation()
  const [errors, setErrors] = useState({ password: '' })
  const [isSuccess, setIsSuccess] = useState(false)
  const handleForm = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const password = formData.get('password')
    const repeatPassword = formData.get('repeatPassword')
    setErrors({ password: '' })
    // Validate
    if (!password) {
      setErrors({ password: 'Введите пароль' })
    } else if (password !== repeatPassword) {
      setErrors({ password: 'Пароли не совпадают' })
    } else if (password === repeatPassword) {
      let code = new URLSearchParams(location.search).get('code')
      AuthStore.resetPassword({ password, code })
        .then((res) => {
          setIsSuccess(true)
        })
        .catch((err) => {
          setErrors({ password: 'Ошибка кода восстановления пароля' })
        })
    }
  }
  const isFetch = AuthStore.isFetch
  return (
    <>
      <div className="page-content auth-page">
        <form onSubmit={handleForm} className="card">
          <Loader show={isFetch} />
          <h3 className="card__title">Восстановление пароля</h3>
          {isSuccess ? (
            <div style={{ textAlign: 'center' }}>
              <div className="md-text">Пароль успешно восстановлен</div>
              <Link className="md-text" to="/signIn">
                Войти в систему
              </Link>
            </div>
          ) : (
            <>
              <Input
                icon={PasswordIcon}
                name="password"
                type="password"
                error={errors.password}
                placeholder="Введите пароль"
              />
              <Input
                icon={PasswordIcon}
                name="repeatPassword"
                type="password"
                placeholder="Повторите пароль"
              />
              <div className="card-actions">
                <button className="btn btn-primary ml-auto">
                  Восстановить
                </button>
              </div>
            </>
          )}
        </form>
      </div>
      <Footer></Footer>
    </>
  )
})

export default ResetPassword
